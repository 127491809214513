import styled from "styled-components";

export const AgreementModalLayout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 30px 18px 18px 18px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  z-index: 101;
  .btn-container {
    display: flex;
    margin-top: 30px;
    button {
      margin-right: 10px;
    }
  }
`;

export const AgreementTextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  .title {
    width: 100%;
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .content {
    font-size: 15px;
    white-space: pre-line;
    line-height: 23px;
  }
`;

export const AgreementModalCloseBtn = styled.button`
  font-size: 20px;
  width: 25px;
  height: 25px;
  background-color: white;
  border: none;
  color: black;
  position: absolute;
  top: 28px;
  right: 18px;
`;
