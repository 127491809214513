import styled from "styled-components";
export const OpenSourceLicenseBox = styled.main`
  width: 100%;
  padding: 80px 18px 0 18px;
`;

export const OpenSourceLicenseItem = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  /* border: 1px solid black; */
  line-height: 18px;
  & > h1 {
    font-size: 20px;
    font-weight: 600;
  }
`;
