import { useNavigate, useParams } from "react-router";
import {
  PaymentContainer,
  PaymentSelectBtn,
  PaymentSelectBtnBox,
} from "../../../../pages/card/payment/style";
import Button from "../../../Common/Button/Button";
import Fixed from "../../../Common/Fixed/Fixed";
import Header from "../../../Layout/Header/Header";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ImmediatePaymentSelect = () => {
  const { charge, id, token, price, batteryamount } = useParams();
  const [isSelectPayment, setIsSelectPayment] = useState("card");
  // const selector = useSelector((state) => state.prevUrl.url);
  const navigate = useNavigate();
  const { IMP } = window;
  useEffect(() => {
    console.log("팩트 실행");
    IMP.init(process.env.REACT_APP_API_PORTONE_CODE);
  }, []);

  const notRegisterPay = (isSelectPayment) => {
    const customerUid = uuidv4();
    const pg =
      isSelectPayment === "card"
        ? "html5_inicis.MOIfecch31"
        : "kakaopay.CA70TFYHG9";
    IMP.request_pay(
      {
        pg: pg,
        pay_method: "card",
        merchant_uid: uuidv4(),
        name: "후불 카드결제 등록",
        amount: 0,
        customer_uid: customerUid,
        buyer_tel: "",
        m_redirect_url: `${window.location.host}/qrbilling/${charge}/${id}/${price}/${token}/${customerUid}/nobillingkey/${batteryamount}`,
      },
      function (res) {
        if (res.success) {
          navigate(
            `/qrbilling/${charge}/${id}/${price}/${token}/${customerUid}/nobillingkey/${batteryamount}?imp_success=true`
          );
        } else {
          alert("결제에 실패했습니다.");
        }
      }
    );
  };

  return (
    <>
      <Header
        // isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 선택"
        marginBottom="false"
      />
      <PaymentContainer>
        <PaymentSelectBtnBox>
          <PaymentSelectBtn
            backColor={"card" === isSelectPayment ? true : false}
            color={"card" === isSelectPayment ? true : false}
            onClick={() => setIsSelectPayment("card")}
          >
            {/* <PaymentSelectDot
              dotColor={"card" === isSelectPayment ? true : false}
            /> */}
            신용 / 체크 카드
          </PaymentSelectBtn>
          <PaymentSelectBtn
            backColor={"kakao" === isSelectPayment ? true : false}
            color={"kakao" === isSelectPayment ? true : false}
            onClick={() => setIsSelectPayment("kakao")}
            // style={{ marginTop: "20px" }}
          >
            {/* <PaymentSelectDot
              dotColor={"kakao" === isSelectPayment ? true : false}
            /> */}
            KakaoPay
          </PaymentSelectBtn>
        </PaymentSelectBtnBox>
      </PaymentContainer>
      <Fixed>
        <Button
          onClick={() => notRegisterPay(isSelectPayment)}
          type="button"
          text={`${
            isSelectPayment === "card" ? "신용/체크 카드" : "카카오페이"
          }로 결제진행`}
          fontSize="18px"
        />
      </Fixed>
    </>
  );
};

export default ImmediatePaymentSelect;
