import React, { useState, useRef } from "react";
import { InputLabel, InputWrapper, Inputs } from "./style";
import AuthNumberValidTime from "../AuthNumberValidTime/AuthNumberValidTime";

const AuthInput = ({
  type,
  id,
  label,
  register,
  placeholder,
  value,
  name,
  disabled,
  func,
  isTimer,
  setIsTimer,
  setTimerKey,
}) => {
  const [passwordType, setPasswordType] = useState(type);
  console.log("id", id);
  return (
    <InputWrapper>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Inputs
        disabled={disabled}
        type={passwordType}
        id={id}
        placeholder={placeholder}
        value={value}
        /* onFocus와 onBlur은 일반적은 HTML에서 제공되는 이벤트 */
        /* onFocus는 클릭될 때 일어나는 것*/

        {...register} // register 전부의 값을 받아 옴.
      />
      {/* {id === "email" && (
        <button
          style={{
            position: "absolute",
            right: "15px",
            top: "33px",
            border: "none",
            borderRadius: "8px",
            background: "greenyellow",
            padding: "5px",
          }}
          type="button"
          onClick={func}
        >
          이메일 중복 확인
        </button>
      )} */}
      {id === "verify" && isTimer && (
        <AuthNumberValidTime
          setIsTimer={setIsTimer}
          setTimerKey={setTimerKey}
        />
      )}
      {(id === "password" || id === "passwordCheck") &&
        (passwordType === "password" ? (
          <span
            onClick={() => {
              setPasswordType("text");
            }}
            style={{
              position: "absolute",
              right: "15px",
              color: "#aaaaaa",
              top: "33px",
            }}
            class="material-symbols-outlined"
          >
            visibility
          </span>
        ) : (
          <span
            onClick={() => setPasswordType("password")}
            class="material-symbols-outlined"
            style={{
              position: "absolute",
              right: "15px",
              color: "#aaaaaa",
              top: "33px",
            }}
          >
            visibility_off
          </span>
        ))}
    </InputWrapper>
  );
};

export default AuthInput;
