import { useState } from "react";
// 인증번호 api
const useCertificationNumberApi = () => {
  console.log("뭐여?");
  // 에러메세지 state
  const [errorMsg, setErrorMsg] = useState(null);
  // 로딩 state
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (data, errorText) => {
    setIsLoading(false);
    try {
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_VERIFY_VRCODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      // console.log("status", response.status);
      // console.log("데이터", responseData);
      if (response.status === 200) {
        setIsLoading(true);
        return responseData;
      }
      if (response.status === 400) {
        setIsLoading(false);
        return setErrorMsg(errorText);
      }
      if (response.status === 409) {
        // console.log("내 에러 맞나?");
        setIsLoading(false);
        return setErrorMsg(responseData.error);
      }
    } catch (error) {
      // console.log("뭐야");
      console.log(error);
    }
  };
  return { errorMsg, fetchData, isLoading };
};
export default useCertificationNumberApi;
