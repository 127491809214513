import styled from "styled-components";
import { theme } from "../../components/theme/theme";

export const AddressContainer = styled.main`
  height: ${(props) => `${props.contentHeight}px`};
  width: 100%;
  padding: 90px 18px 20px 18px;
  display: flex;
  flex-direction: column;
`;

export const AddressItem = styled.article`
  /* border: 1px solid #aaaaaa; */
  background-color: #f5f5f5;
  width: 100%;
  height: 195px;
  padding: 24px 20px;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  .box-container {
    margin: 11px 0 1px 0;
    display: flex;
    justify-content: space-between;
  }
  address {
    color: #a9a9b1;
  }
  .situation {
    margin-top: 18px;
  }
`;
export const AddressItemTitle = styled.h1`
  font-size: 22px;
  color: ${theme.colors.pointColor};
  font-weight: 700;
`;
export const AddressItemTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 25px; */
  div {
    display: flex;
    align-items: center;
    span {
      color: ${theme.colors.FontGrayColor};
    }
  }
`;
export const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  div:nth-of-type(3) {
    margin-right: 0;
  }
`;

export const InfoBoxItem = styled.div`
  background-color: #f5f5f5;
  padding: 3px;
  margin-right: 5px;
  color: #45454f;
  border-radius: 5px;
  span {
    color: ${theme.colors.pointColor};
  }
`;

export const KaKaoMapContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  background-color: white;
  z-index: 100;
  h1 {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 10px;
    font-weight: 700;
  }
  h3 {
    margin-top: 5px;
    margin-left: 10px;
  }
`;
export const KaKaoMap = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 300px;
`;

export const KaKaoMapCloseBtn = styled.button`
  border: none;
  position: absolute;
  top: 25px;
  right: 0;
  color: black;
`;
