import React, { useEffect, useState } from "react";
import {
  BatteryIcon,
  BatteryValue,
  Card,
  CardTop,
  ChargeStatusContainer,
  Introduction,
  NoData,
  RefreshBtn,
  UpdateInfo,
} from "./ChargeStatus.style";
import Container from "../../Common/Wrapper/Container";
import Header from "../../Layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import PhoneCertification from "../../QrCertification/components/PhoneCertification/PhoneCertification";
import { authInfoData } from "../../../store/Auth/auth-api";
import Footer from "../../Layout/Footer/Footer";
import axios from "axios";

const ChargeStatus = () => {
  const [chargeData, setChargeData] = useState(null);
  // 폰인증 , 인증번호 인증 체크
  const [certificationPhonNumberCheck, setCertificationPhoneNumberCheck] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  // 토근 state
  const [token, setToken] = useState();
  const userInfoData = useSelector((state) => state.authInfo.userInfo);
  // console.log("chargestatuspage userinfo", userInfoData);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("실행");
    dispatch(authInfoData());
  }, []);
  const fetchData = async (data) => {
    // console.log("hello", data);
    const token = localStorage.getItem("token");
    try {
      console.log("요청");
      const response = await axios.post(
        process.env.REACT_APP_API_CHARGE_STATUS,
        {
          phone: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `token ${token}`,
          },
        }
      );
      // console.log("chargedata", response.data);

      if (response.status === 200) {
        // console.log("response 200", response.data);
        return setChargeData(response.data);
      }
      //   } else if (!response.ok) {
      //     return console.log("not response 200.. ");
      // }
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 400) {
        setChargeData(undefined);
      }
      console.log("error", error.response.data.error);
    }
  };
  console.log("실행");
  useEffect(() => {
    console.log("실행");
    if (userInfoData) {
      // console.log(userInfoData.user.phone);
      fetchData(userInfoData.user.phone);
    } else if (certificationPhonNumberCheck) {
      fetchData(phoneNumber);
    }
  }, [certificationPhonNumberCheck, userInfoData]);

  // console.log("chargedata", chargeData);

  if (userInfoData) {
    if (!chargeData) {
      console.log("실황이 없음");
    } else {
      console.log("데이터 보여주기");
    }
  } else if (!userInfoData) {
    if (chargeData) {
      console.log("비회원 화면 보여주기");
    } else if (!chargeData) {
      console.log("비회원도 데이터 없음");
    }
  }
  let titleText;
  // console.log("phonenumber", phoneNumber);
  if (userInfoData) {
    titleText = `${userInfoData.user.name} 님의 충전 실황`;
  } else if (!certificationPhonNumberCheck) {
    titleText = "내 충전 실황 휴대폰 인증";
  } else if (certificationPhonNumberCheck) {
    titleText = `${phoneNumber.slice(phoneNumber.length - 4)}님의 충전 상황`;
  }

  let batteryColor;
  let batteryPercent =
    chargeData?.length === 4 ? 100 : parseInt(chargeData?.soc.slice(0, 2));
  // console.log(chargeData?.soc.slice(0, 2));

  if (batteryPercent < 21) {
    batteryColor = "#FF4646";
  } else if (batteryPercent < 61) {
    batteryColor = "#FFAF00";
  } else {
    batteryColor = "#80e12a;";
  }

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);
  return (
    <Container bgColor="#f5f5f5">
      <Header
        // isBack={true}
        // isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        // backColor="#5B5AC7"
        title={titleText}
      />

      {userInfoData || certificationPhonNumberCheck ? (
        <ChargeStatusContainer contentHeight={contentHeight}>
          {chargeData ? (
            <Card>
              <h1>{chargeData?.centerName} 충전소</h1>
              <CardTop>
                <UpdateInfo>
                  업데이트 시간 : {new Date().toLocaleTimeString()}
                </UpdateInfo>
                <RefreshBtn
                  onClick={() =>
                    fetchData(
                      userInfoData ? userInfoData.user.phone : phoneNumber
                    )
                  }
                >
                  <span className="material-symbols-outlined">refresh</span>
                </RefreshBtn>
              </CardTop>
              <Introduction>
                <p>현재 배터리량</p>
                <BatteryValue>
                  <div className="battery-icon-box">
                    <div className="battery-dot"></div>
                    <BatteryIcon
                      batteryValue={chargeData.soc}
                      batteryColor={batteryColor}
                    >
                      <p>{chargeData?.soc}</p>
                      <div className="battery"></div>
                    </BatteryIcon>
                  </div>
                </BatteryValue>
              </Introduction>
              <Introduction>
                <p>충전 요금</p>
                <p>{chargeData?.chargeFee}</p>
              </Introduction>
              {/* <Introduction>
                <p>충전소 이름</p>
                <p>{chargeData?.centerName}</p>
              </Introduction> */}
              <Introduction>
                <p>충전 상태</p>
                <p>{chargeData?.centerStatus}</p>
              </Introduction>

              <Introduction>
                <p>주차면 번호</p>
                <p>{chargeData?.connector}</p>
              </Introduction>

              <Introduction>
                <p>충전 속도</p>
                <p>{chargeData?.chargeSpeed}</p>
              </Introduction>
            </Card>
          ) : (
            <NoData>현재 충전중인 차량이 없습니다.</NoData>
          )}
        </ChargeStatusContainer>
      ) : (
        <PhoneCertification
          setCertificationCheck={setCertificationPhoneNumberCheck}
          setPhoneNumber={setPhoneNumber}
          setToken={setToken}
        />
      )}
      <Footer />
    </Container>
  );
};

export default ChargeStatus;
