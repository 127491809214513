import React, { useEffect, useRef } from "react";

import { ModalBackground, ModalBox, ModalBtn } from "../style/styled";

const Modal = ({ type, text, id, setIsModal, func }) => {
  const onClickCancelBtn = () => {
    document.body.style.overflow = "";
    setIsModal(null);
  };

  const ref = useRef();
  useEffect(() => {
    console.log("팩트 실행");
    // console.log("time 실행");
    const key = setTimeout(() => {
      ref.current.style.transform = "translateY(-10px)";
      ref.current.style.transition = "all 0.2s ease-in-out";
    });

    return () => {
      clearTimeout(key);
    };
  }, []);

  let content;

  if (type === "delete") {
    content = (
      <>
        <ModalBtn type="button" color={true} onClick={onClickCancelBtn}>
          취소
        </ModalBtn>
        <ModalBtn type="button" onClick={() => func(id, setIsModal)}>
          삭제
        </ModalBtn>
      </>
    );
  } else if (type === "check") {
    content = (
      <>
        <ModalBtn type="button" color={true} onClick={onClickCancelBtn}>
          아니오
        </ModalBtn>
        <ModalBtn type="button" onClick={() => func(setIsModal)}>
          네
        </ModalBtn>
      </>
    );
  } else if (type === "ready") {
    content = (
      <>
        <ModalBtn type="button" onClick={onClickCancelBtn}>
          확인
        </ModalBtn>
      </>
    );
  }
  return (
    <ModalBackground>
      <ModalBox ref={ref}>
        <div>
          <p>{text}</p>
          <div className="btn-container">{content}</div>
        </div>
      </ModalBox>
    </ModalBackground>
  );
};

export default Modal;
