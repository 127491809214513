import { useEffect, useState } from "react";
const { kakao } = window;

const useKaKaMapRequest = (mapX, mapY) => {
  const [isShowMap, setIsShowMap] = useState(false);
  const [mapData, setMapData] = useState([]);
  useEffect(() => {
    console.log("팩트 실행");
    const fetchData = async (mapData) => {
      try {
        console.log("요청");
        if (isShowMap === true && mapData.length > 0) {
          // console.log("여기가 실행되는가");
          const container = document.getElementById("map");
          const options = {
            center: new kakao.maps.LatLng(mapData[0], mapData[1]),
            level: 3,
          };
          const map = new kakao.maps.Map(container, options);
          const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(mapData[0], mapData[1]),
          });
          marker.setMap(map);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    console.log("실행");
    fetchData(mapData);
  }, [isShowMap, mapData]);
  return { isShowMap, setIsShowMap, setMapData };
};
export default useKaKaMapRequest;
