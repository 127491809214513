import {
  fetchAuthFailure,
  fetchAuthStart,
  fetchAuthSucess,
} from "./authInfo-slice";

const tokenTimer = (token) => {};
export const authInfoData = () => {
  return async (dispatch) => {
    try {
      console.log("요청");
      dispatch(fetchAuthStart());
      const token = localStorage.getItem("token");
      console.log("실행");
      const response = await fetch(process.env.REACT_APP_API_USER_INFO, {
        method: "GET",
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      // console.log("data!!!", data);
      if (response.status === 200) {
        return dispatch(fetchAuthSucess(data));
      } else if (!response.ok) {
      }
    } catch (error) {
      return dispatch(fetchAuthFailure(error.message));
    }
  };
};
