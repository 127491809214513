import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authInfoData } from "../../store/Auth/auth-api";
import Fixed from "../../components/Common/Fixed/Fixed";
import Button from "../../components/Common/Button/Button";
import BgLoading from "../../components/Common/Loading/BgLoading";
import { LoadingContainer } from "../../components/Auth/style/styled";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import styled from "styled-components";
import axios from "axios";

const UserProfileContainer = styled.main`
  background-color: #f5f5f5;
  width: 100%;
  /* max-width: 600px; */
  height: ${(props) => props.height && `${props.height}px`};
  padding: 80px 18px 0 18px;
  /* background-color: #f5f5f5; */
`;
const UserProfileItem = styled.section`
  position: relative;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* height: 55px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid #dcdcdc; */
  /* padding-bottom: 10px; */
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 15px;
  background-color: white;
  /* border-bottom: 1px solid #aaaaaa; */
`;

const UserProfileItemTitle = styled.h1`
  font-size: 14px;
  font-weight: 600;
  color: #828282;
  margin-bottom: 5px;
`;

const UserProfileItemContent = styled.span`
  font-size: 18px;
`;

const EditBtn = styled.button`
  min-width: 40px;
  height: 25px;
  /* background-color: #96f56e;
  border: none;
  border-radius: 8px; */
  border: none;
  text-decoration: underline;
  position: absolute;
  right: 10px;
  font-size: 12px;
  top: 10px;
`;

const UserProfile = () => {
  // 결제 비밀번호 생성 여부
  const [isPaymentPassword, setIsPaymentPassword] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  const checkIsPaymentPassword = async () => {
    try {
      console.log("요청");
      const token = localStorage.getItem("token");
      // console.log("실행", token);
      const response = await axios.get(
        process.env.REACT_APP_API_CHECK_IS_PAYMENT_PASSWORD,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          // credentials: "include", //Cross-Origin 요청을 할 때 요청에 자동으로 쿠키와 인증 헤더를 포함하도록 설정하는 역할
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        // console.log("체크 유무 데이터", response.data);
        if (response.data.paypass === "TRUE") {
          setIsPaymentPassword(true);
        } else {
          setIsPaymentPassword(false);
        }
      }
      // else if (!response.ok) {
      //   console.log("! response ok");
      // }
    } catch (e) {
      console.log(e);
    }
  };
  const navigate = useNavigate();

  // useSelector -> redux toolkit 에서 제공해주는 것
  //  authInfo에 있는 userInfo 상태를 가져옴.
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  // console.log(userInfo);

  // dispatch을 하기위해서 useDispatch을 불러옴
  const dispatch = useDispatch();

  // authInfoData는 auth-api.js 에서 불러옴
  // authInfoData 에서 user api를 불러와서 처리 해줌.
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
    checkIsPaymentPassword();
  }, []);

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <Container bgColor="#f5f5f5" paddingBottom="80px">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        title="회원관리"
      />

      <UserProfileContainer height={contentHeight}>
        {userInfo && userInfo ? (
          <>
            <UserProfileItem>
              <UserProfileItemTitle>이름</UserProfileItemTitle>
              <UserProfileItemContent>
                {userInfo?.user?.name}
              </UserProfileItemContent>
              <EditBtn onClick={() => navigate("/users/edit")}>수정</EditBtn>
            </UserProfileItem>
            <UserProfileItem>
              <UserProfileItemTitle>이메일 (아이디)</UserProfileItemTitle>
              <UserProfileItemContent>
                {userInfo?.user?.email}
              </UserProfileItemContent>
            </UserProfileItem>
            <UserProfileItem>
              <UserProfileItemTitle>휴대폰번호</UserProfileItemTitle>
              <UserProfileItemContent>
                {userInfo?.user?.phone.slice(0, 7)}****
              </UserProfileItemContent>
            </UserProfileItem>
            <UserProfileItem>
              <UserProfileItemTitle>비밀번호</UserProfileItemTitle>
              <UserProfileItemContent>********...</UserProfileItemContent>
              {/* <EditBtn>수정</EditBtn> */}
            </UserProfileItem>
            <UserProfileItem>
              <UserProfileItemTitle>결제 비밀번호</UserProfileItemTitle>
              <UserProfileItemContent>
                {isPaymentPassword ? "****" : "결제 비밀번호 없음"}
                {/* {false ? "****" : "결제 비밀번호 없음"} */}
              </UserProfileItemContent>
              <EditBtn
                onClick={() => {
                  if (isPaymentPassword) {
                    navigate("/card/payment/password/edit/profile");
                  } else {
                    navigate("/card/payment/password/create/profile");
                  }
                }}
              >
                {isPaymentPassword ? "수정" : "생성"}
                {/* {false ? "수정" : "생성"} */}
              </EditBtn>
            </UserProfileItem>

            {/* <Fixed>
              <Link to={"/users/edit"}>
                <Button text="수정하기" fontSize="18px" />
              </Link>
            </Fixed> */}
          </>
        ) : (
          <LoadingContainer>
            <BgLoading />
          </LoadingContainer>
        )}
      </UserProfileContainer>
    </Container>
  );
};

export default UserProfile;
