import styled from "styled-components";

export const ChargeInfoBox = styled.nav`
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  align-items: center;
  /* border-top: 1px solid #dcdcdc; */
  a,
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-size: 12px;
      border-radius: 0 !important;
      span:nth-of-type(2) {
        margin-top: 3px;
      }
    }
  }
`;
