import { CompanyInfoBox, CompanyInfoItem } from "./CompanyInfo.style";

// 회사 정보 데이터
const companyInfoContent = [
  { name: "회사명 :", value: "(주)FEC" },
  { name: "대표자 :", value: "이윤원" },
  { name: "사업자등록번호 :", value: "8468101966" },
  {
    name: "주소 :",
    value: "경상북도 경산시 강변서로 53길 23, 에이동 2층(정평동)",
  },
  { name: "전화 :", value: "053-719-4967" },
  { name: "이메일 :", value: "contactus@feccharge.co.kr" },
];

// 회사 정보 컴포넌트
const CompanyInfo = () => {
  return (
    <CompanyInfoBox>
      {companyInfoContent.map((item) => {
        return (
          <CompanyInfoItem>
            <p className="title">{item.name}</p>
            <p>{item.value}</p>
          </CompanyInfoItem>
        );
      })}
    </CompanyInfoBox>
  );
};

export default CompanyInfo;
