import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import usePatchApi from "../../hooks/api/usePatchApi";
import { carInfoData } from "../../store/CarInfo/carInfo-api";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import {
  BrandBox,
  CarContainer,
  CarNumberBox,
  Input,
  WarningText,
} from "../../components/Cars/style/style";
import Select from "../../components/Cars/Common/Selector/Select";
import FooterBox from "../../components/Common/FooterBox";
import Button from "../../components/Common/Button/Button";
import {
  batteryAmount,
  carBrandList,
  connectorList,
  hyundaCar,
  kiaCar,
  teslaCar,
} from "../../components/Cars/Common/Selector/CarTypeList";

// 등록된 자동차의 정보 수정 컴포넌트
const CarEdit = () => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isDirty, dirtyFields },
    watch,
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log("팩트 실행");

    const confirmed = window.confirm("서비스 준비 중 입니다.");
    if (confirmed) {
      navigate("/usermenu");
    }
    if (!confirmed) {
      navigate("/");
    }
  }, []);

  const { id } = useParams(); // url 에 현재 수정할려는 자동차의 id 를 가져오기
  const { carnumber, carType, connector, carbrand, BatCapacity } = watch();

  const { patchData, isError, isLoading } = usePatchApi();

  // 등록한 자동차 정보 가지고오기
  const carListData = Object.values(
    useSelector((state) => state.carInfo.carInfor) || {}
  );

  // 현재 수정할려고 선택한 등록한 자동차의 정보를 찾기
  const editCarData = carListData.find((item) => item.id === parseInt(id));

  // console.log("isdirty", isDirty);
  // console.log("dirtyfield", dirtyFields);
  // console.log("carlistdata", carListData);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("팩트 실행");
    dispatch(carInfoData());
    // if (carListData[parseInt(id)]) {
    //   setSelectedBrand(carInfoId.carType);
    //   setSelectedType(carInfoId.carbrand);
    //   setSelectedConnector(carInfoId.connector);
    //   setValue("carnumber", carInfoId.carnumber);
    //   setValue("connector", carInfoId.connector);
    //   setValue("carType", carInfoId.carType);
    //   setValue("carbrand", carInfoId.carbrand);
    // }
  }, []);
  useEffect(() => {
    console.log("팩트 실행");
    // 기존의 등록한 자동차 데이터를 불러와 값으로 설정하기
    if (editCarData) {
      setValue("carnumber", editCarData?.carnumber);
      setValue("carType", editCarData?.carType);
      setValue("carbrand", editCarData?.carbrand);
      setValue("connector", editCarData?.connector);
      setValue("BatCapacity", editCarData?.BatCapacity);
    }
  }, [editCarData]);
  // console.log(errors);
  // console.log(editCarData);

  // 자동차 정보를 변경하고 수정 완료 버튼을 클릭하면 호출되는 콜백 함수
  const updateCarInfo = async (editData) => {
    // console.log(editCarData, editData);

    // 기존의 자동차 데이터와 변경이 있었는지 확인, 리액트 훅 폼의 더티 프로퍼티를 이용하고 싶었지만 차량 번호 말고는 임의로 데이터를 바꿔 주는것이라 더티 필드가 감지를 못함
    if (
      editData.carnumber === editCarData.carnumber &&
      editData.carType === editCarData.carType &&
      editData.carbrand === editCarData.carbrand &&
      editData.connector === editCarData.connector &&
      editData.BatCapacity === editCarData.BatCapacity
    ) {
      setError("carnumber", { message: "변경사항이 없습니다." });
      return;
    }

    const requestData = {
      ...editData,
      id: parseInt(id),
    };

    patchData(
      process.env.REACT_APP_API_CAR_INFO,
      requestData,
      "/cars/management"
    );
  };

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="차량 수정하기"
        isModal={
          editCarData &&
          carnumber === editCarData?.carnumber &&
          carType === editCarData?.carType &&
          connector === editCarData?.connector &&
          carbrand === editCarData?.carbrand &&
          BatCapacity === editCarData.BatCapacity
            ? false
            : true
        }
      />

      <CarContainer contentHeight={contentHeight}>
        {editCarData && (
          <form id="form" onSubmit={handleSubmit(updateCarInfo)}>
            <CarNumberBox>
              <label htmlFor="carnumber">차량번호</label>
              <Input
                type="text"
                id="carnumber"
                {...register("carnumber", {
                  required: "차량번호를 입력해주세요.",
                  pattern: {
                    value: /^[0-9]{2,3}[가-힣][0-9]{4}$/,
                    message: "차량 번호 형식이 아닙니다.",
                  },
                })}
                // placeholder={editCarData.carnumber}
              />
              {errors && errors.carnumber && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "#412BD5",
                    lineHeight: "18px",
                  }}
                >
                  {errors.carnumber.message}
                </p>
              )}
            </CarNumberBox>
            <BrandBox>
              <div className="aa">
                <Select
                  title="차량 커넥터 선택"
                  register={register}
                  inputName="connector"
                  setValue={setValue}
                  selectList={connectorList}
                  defaultValue={editCarData.connector}
                ></Select>
                <div style={{ marginTop: "8px", marginBottom: "10px" }}>
                  <WarningText>
                    ※ FEC 충전 시스템은 DC콤보1만 지원합니다. 다른 커넥터는
                    DC콤보1으로 전환하는 어뎁터가 반드시 필요합니다.
                  </WarningText>
                </div>
                <Select
                  register={register}
                  title="차량 브랜드 선택"
                  setValue={setValue}
                  inputName="carbrand"
                  selectList={carBrandList}
                  defaultValue={editCarData.carbrand}
                />
                <Select
                  register={register}
                  title="차종 선택"
                  setValue={setValue}
                  inputName="carType"
                  selectList={
                    carbrand === "현대"
                      ? hyundaCar
                      : carbrand === "기아"
                      ? kiaCar
                      : carbrand === "테슬라"
                      ? teslaCar
                      : null
                  }
                  defaultValue={
                    carbrand === "현대"
                      ? hyundaCar[0].name
                      : carbrand === "기아"
                      ? kiaCar[0].name
                      : carbrand === "테슬라"
                      ? teslaCar[0].name
                      : null
                  }
                  mode="edit"
                  editdata={editCarData}
                />
                <Select
                  register={register}
                  title="배터리 종류"
                  setValue={setValue}
                  inputName="BatCapacity"
                  selectList={batteryAmount}
                  defaultValue={batteryAmount[0].name}
                />
              </div>
            </BrandBox>
            <FooterBox>
              <Button
                type="submit"
                text="차량 수정하기"
                fontSize="18px"
              ></Button>
            </FooterBox>
          </form>
        )}
      </CarContainer>
    </Container>
  );
};

export default CarEdit;
