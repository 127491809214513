import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { ko } from "date-fns/esm/locale";
import moment from "moment";

const DataPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  .react-datepicker__input-container {
    display: flex;
  }
  .react-datepicker__input-container input {
    border: none;
    background-color: none;
    text-align: center;
    font-size: 16px;
  }
`;

const SelectDate = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const setChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const datePickerRef = useRef();
  const formatDate = (date) => moment(date).format("YY-MM-DD");

  return (
    <DataPickerWrapper>
      <span
        class="material-symbols-outlined"
        style={{ height: "25px", width: "25px" }}
        onClick={() => {
          datePickerRef.current.setOpen(true);
        }}
      >
        calendar_month
      </span>
      <DatePicker
        ref={datePickerRef}
        selectsRange={true}
        className="datepicker"
        locale={ko}
        dateFormat="yy-MM-dd"
        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        value={`${startDate ? formatDate(startDate) : ""} ${
          startDate && endDate ? "~" : ""
        } ${endDate ? formatDate(endDate) : ""}`}
        onKeyDown={(e) => e.preventDefault()}
        onChange={(dates) => setChangeDate(dates)}
      />
    </DataPickerWrapper>
  );
};

export default SelectDate;
