import styled from "styled-components";
import { theme } from "../../theme/theme";

export const QrBtnBox = styled.div`
  width: 100%;
  height: 40px;
  /* text-align: center; */
  button:nth-child(1) {
    margin-bottom: 20px;
  }
`;

export const QrBtn = styled.button`
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  max-width: 500px;
  height: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.pointColor};
  color: white;
  border: none;
`;
