import React from "react";
import { ApplicationContainer, ApplicationDesContainer } from "./style";
import { applicationArr } from "../content";
import Container from "../../Common/Wrapper/Container";
import Header from "../../Layout/Header/Header";

const ApplicationDes = () => {
  return (
    <Container paddingBottom="80px">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전시설 설치신청"
      />
      <ApplicationContainer>
        {applicationArr.map((item) => {
          return (
            <ApplicationDesContainer>
              {item.img}
              <div>{item.number}</div>
            </ApplicationDesContainer>
          );
        })}
      </ApplicationContainer>
      {/* <FooterBox>
                    <Button bgColor="#412BD5" text="충전시설 설치 신청"/>
            </FooterBox> */}
    </Container>
  );
};

export default ApplicationDes;
