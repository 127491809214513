import React from "react";
import { DuplicateMsg } from "../style/styled";
// 중복된 값을 변경하거나 가입을 할려고 할 때 사용하는 component

const DuplicateError =(props) =>{
    return(
        <DuplicateMsg>
            {props.text}
        </DuplicateMsg>
    )
}

export default DuplicateError;

