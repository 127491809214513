import React, { useRef } from "react";
import {
  SelectBox,
  SelectConnectorBtn,
  SelectConnectorList,
  SelectInput,
  SelectInputBox,
  SelectLabel,
  SelectList,
  SelectListItem,
} from "../../style/style";
import { useState } from "react";
import { useEffect } from "react";

const Select = ({
  register,
  title,
  setValue,
  inputName,
  selectList,
  defaultValue,
  mode,
  editdata,
}) => {
  // 현재 선택한 아이템
  const [currentSelect, setCurrentSelect] = useState(defaultValue);

  // 선택 옵션 아이템들을 보여주는 드롭다운 여부 모달
  const [isSelectDropDown, setIsSelectDropDown] = useState(false);

  const selectRef = useRef();

  // 어떤 옵션을 선택 했을 때 호출되는 콜백 함수
  const onclickSelectHandler = (carName) => {
    setCurrentSelect(carName);
    setValue(inputName, carName);
  };

  // 사용자가 차량 브랜드를 변경하면 브랜드에 해당하는 차종을 보여줘야 하기 때문에 임의로 디폴트 값을 변경해줘야 한다.
  useEffect(() => {
    console.log("실행");
    // 다른 셀렉터에서는 변경해줄 필요가 없음 차량 브랜드 선택에 따라서 차종 선택이 변경되어야 하기 때문에 해당 코드 사용
    if (inputName === "carType") {
      setCurrentSelect(defaultValue);
      setValue(inputName, defaultValue);
    }
  }, [defaultValue]);

  // 첫 차량 수정 마운트, 자동차 데이터 수정 때 차종 선택에서 사용자의 데이터를 가져와야 하기 때문에 다시 업데이트
  // 차종 선택은 차량 브랜드 선택에 따라서 연결되서 변경되어야 하기 때문에 차량 수정 때는 데이터를 임의로 바꿔줘야함
  useEffect(() => {
    console.log("실행");
    if (mode === "edit") {
      setCurrentSelect(editdata.carType);
      setValue(inputName, editdata.carType);
    }
  }, []);

  // 드롭다운 UI 선택에서 드롭다운 이외의 화면 클릭 했을 때 드롭다운 풀리는 기능
  useEffect(() => {
    console.log("실행");
    // if (title === "차량 커넥터 선택") {
    //   return;
    // }
    const onclickOutSide = ({ target }) => {
      if (isSelectDropDown && !selectRef.current.contains(target)) {
        setIsSelectDropDown(false);
      }
    };
    document.addEventListener("click", onclickOutSide);
    return () => {
      document.removeEventListener("click", onclickOutSide);
    };
  }, [isSelectDropDown, title]);
  return (
    <>
      <SelectBox>
        <SelectLabel>{title}</SelectLabel>
        {title === "차량 커넥터 선택" ? (
          <SelectConnectorList>
            <SelectInput
              type="text"
              // id={inputName}
              {...register(inputName, {
                required: true,
              })}
              // onClick={() => setIsSelectCarBrandModal((prev) => !prev)}
              value={currentSelect}
              style={{ display: "none" }}
            />
            {selectList.map((item) => {
              return (
                <SelectConnectorBtn
                  type="button"
                  currentSelect={currentSelect === item.name}
                  onClick={() => onclickSelectHandler(item.name)}
                >
                  {item.name}
                </SelectConnectorBtn>
              );
            })}{" "}
          </SelectConnectorList>
        ) : (
          <>
            {/* <input ref={selectRef} /> */}
            <SelectInputBox
              onClick={() => setIsSelectDropDown((prev) => !prev)}
            >
              <SelectInput
                inputMode="none"
                // onFocus={() => document.activelement.blur()}
                type="text"
                id={inputName}
                {...register(inputName, {
                  required: true,
                })}
                value={currentSelect}
                ref={selectRef}
              />
              <div className="arrow-icon">
                {isSelectDropDown ? (
                  <span class="material-symbols-outlined">expand_less</span>
                ) : (
                  <span class="material-symbols-outlined">expand_more</span>
                )}
              </div>
            </SelectInputBox>

            {isSelectDropDown && (
              <SelectList>
                {selectList.map((item, index) => {
                  return (
                    <SelectListItem
                      key={index}
                      onClick={() => {
                        onclickSelectHandler(item.name);
                        setIsSelectDropDown((prev) => !prev);
                      }}
                    >
                      {item.name}
                    </SelectListItem>
                  );
                })}
              </SelectList>
            )}
          </>
        )}
      </SelectBox>
    </>
  );
};
export default Select;
