import styled from "styled-components";

export const AgreewithdrawBox = styled.div`
  height: ${(props) => `${props.contentHeight}px`};
  padding: 75px 18px 0 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WithdrawInfo = styled.div`
  width: 100%;
  height: ${(props) => props.contentHeight && `${props.contentHeight - 250}px`};
  display: flex;
  justify-content: center;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 30px;
  font-size: 15px;
  line-height: 20px;
  & > ul {
    overflow-y: auto;
  }
`;
export const AgreeBox = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
`;
