import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../../../Layout/Header/Header";
import {
  InputBox,
  InputItem,
  InputValueBox,
  PaymentPasswordBtnBox,
  PaymentPasswordBtn,
} from "./PaymentPassword.style";
import { PaymentContainer } from "../../../../pages/card/payment/style";
import PhoneCertification from "../../../QrCertification/components/PhoneCertification/PhoneCertification";
import { useSelector } from "react-redux";
import ErrorMsg from "../../../Common/Errors/ErrorMsg";

const PaymentPassword = () => {
  const [error, setError] = useState("");
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  // console.log("userinfo", userInfo);
  const { mode, where } = useParams();

  // console.log("where", where);

  // console.log("mode", mode);

  const [isEditPaymentPassword, setIsEditPaymentPassword] = useState(
    mode === "edit" ? true : false
  );

  // console.log("mode", mode);
  const navigate = useNavigate();
  const [isInputPasswordCover, setIsInputPasswordCover] = useState(true);
  const [clickValue, setClickValue] = useState("");
  const [list, setList] = useState(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].sort(() => Math.random() - 0.5)
  );
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("clickvalue", clickValue);

  const clickValueList = clickValue.split("");

  const paymentPasswordCreatePost = async (data) => {
    // console.log("data", data);
    try {
      const token = localStorage.getItem("token");
      console.log("요청");
      const response = await fetch(
        process.env.REACT_APP_API_PAYMENT_PASSWORD_CREATE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          // credentials: "include", //Cross-Origin 요청을 할 때 요청에 자동으로 쿠키와 인증 헤더를 포함하도록 설정하는 역할

          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      // console.log("reponsedata", responseData);
      if (response.status === 200) {
        console.log("성공!");
        if (where === "payment") {
          navigate("/card/payment/management");
        } else if (where === "profile") {
          navigate("/users/profile");
        }
        return;
      } else if (response.status === 400) {
        // console.log("무슨 에러야?", responseData, response);
        setError(responseData.error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={
          mode === "create"
            ? "결제 비밀번호 생성"
            : mode === "edit"
            ? "결제 비밀번호 수정"
            : ""
        }
        marginBottom="false"
      />

      {isEditPaymentPassword ? (
        <PhoneCertification
          setIsEditPaymentPassword={setIsEditPaymentPassword}
          mode="paymentpassword"
        />
      ) : (
        <>
          <PaymentContainer height={contentHeight}>
            <InputValueBox
              style={{
                height: "15px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isInputPasswordCover ? (
                <>
                  {Array(4)
                    .fill()
                    .map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "30px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              backgroundColor: `${
                                clickValueList[index] !== undefined
                                  ? "black"
                                  : "white"
                              }`,
                              borderRadius: "50%",
                              // marginRight: "20px",
                              border: `${
                                clickValueList[index] !== undefined
                                  ? "none"
                                  : "2px solid #828282"
                              }`,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {clickValue.split("").map((item) => {
                      return <div style={{ fontSize: "25px" }}>{item}</div>;
                    })}
                  </div>
                </>
              )}
            </InputValueBox>
            <InputBox>
              {list.slice(1).map((item) => {
                return (
                  <InputItem
                    onClick={() => {
                      if (clickValue.length > 3) {
                        return;
                      }
                      setClickValue((prev) => prev + item);
                    }}
                  >
                    {item}
                  </InputItem>
                );
              })}

              <InputItem style={{ fontSize: "20px" }}>
                {isInputPasswordCover ? (
                  <span
                    class="material-symbols-outlined"
                    onClick={() => setIsInputPasswordCover((prev) => !prev)}
                  >
                    visibility
                  </span>
                ) : (
                  <span
                    class="material-symbols-outlined"
                    onClick={() => setIsInputPasswordCover((prev) => !prev)}
                  >
                    visibility_off
                  </span>
                )}
              </InputItem>
              <InputItem
                onClick={() => {
                  if (clickValue.length > 3) {
                    return;
                  }
                  setClickValue((prev) => prev + list[0]);
                }}
              >
                {list[0]}
              </InputItem>

              <InputItem
                onClick={() => {
                  setClickValue((prev) => prev.slice(0, prev.length - 1));
                }}
              >
                <span
                  style={{ fontSize: "35px" }}
                  class="material-symbols-outlined"
                >
                  arrow_back
                </span>
              </InputItem>
            </InputBox>
            {error && <ErrorMsg text={error} />}
            <PaymentPasswordBtnBox>
              <PaymentPasswordBtn
                isActive={clickValue.length === 4 ? true : false}
                onClick={() => {
                  paymentPasswordCreatePost({ paypass: clickValue });
                }}
              >
                완료
              </PaymentPasswordBtn>
            </PaymentPasswordBtnBox>
          </PaymentContainer>
        </>
      )}
    </>
  );
};

export default PaymentPassword;
