import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useChargePriceApi from "../../api/useChargePriceApi";
import { CenteredBox } from "../../pages/card/payment/style";
import styled from "styled-components";
import { Oval } from "react-loader-spinner";
import { theme } from "../theme/theme";
import Header from "../Layout/Header/Header";
import Container from "../Common/Wrapper/Container";

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 120px 20px 0 20px;
  .aa {
    text-align: center;
    & > p {
      margin-top: 16px;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .bb {
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 8px;
    }
  }
`;

const QrBillingSuccess = () => {
  const navigate = useNavigate();
  const {
    charge,
    connector,
    token,
    price,
    billValue,
    billcase,
    batteryamount,
  } = useParams();
  useEffect(() => {
    const handle = (e) => {
      window.history.forward();
      e.preventDefault();
    };

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handle);

    return () => {
      window.removeEventListener("popstate", handle);
    };
  }, []);
  const [searchParams] = useSearchParams();
  const impSuccess = searchParams.get("imp_success");
  console.log("hello", impSuccess);
  const {
    isLoading: chargePriceLoading,
    errorMsg: chargePriceErrorMsg,
    fetchData: chargePriceFetch,
  } = useChargePriceApi();
  useEffect(() => {
    console.log("팩트 실행");
    if (impSuccess === "false") {
      navigate(
        `/qrbilling/payment/select/${charge}/${connector}/${price}/${token}/${batteryamount}`
      );
    }
    if (impSuccess === "true" && billcase === "nobillingkey") {
      chargePriceFetch({
        center: charge,
        connector,
        token,
        price,
        billcase,
        billingkey: billValue,
        soc: batteryamount,
      });
    } else if (billcase === "billingkey") {
      chargePriceFetch({
        center: charge,
        connector,
        token,
        price: Number(price),
        billcase,
        billnum: Number(billValue),
        soc: batteryamount,
      });
    }
  }, []);

  return (
    <Fragment>
      <Container bgColor="white">
        <Header
          // isBack={true}
          isHome={true}
          backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="white"
          title="충전 신청 완료"
        />

        {chargePriceErrorMsg ? (
          <CenteredBox>
            <p>오류가 발생했습니다.</p>
            <p>다시 시도해주세요.</p>
            <p>{chargePriceErrorMsg}</p>
          </CenteredBox>
        ) : chargePriceLoading ? (
          <CenteredBox>
            <Oval
              color={theme.colors.pointColor}
              secondaryColor={theme.colors.subColor}
              height={50}
              width={100}
            />
          </CenteredBox>
        ) : (
          <Box>
            <div className="aa">
              <img src="/img/Vector.jpg" />
              <p>충전 신청이 완료되었습니다.</p>
              <div className="bb">
                <p>커넥터가 차량과 연결이 잘 </p>
                <p>되었는지 재확인 부탁드립니다.</p>
              </div>
            </div>
          </Box>
        )}
      </Container>
    </Fragment>
  );
};

export default QrBillingSuccess;
