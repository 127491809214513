import { useState } from "react";

// 휴대폰 번호 인증 api
const usePhoneCertificationApi = () => {
  // 휴대폰 번호 state
  const [phoneNumber, setPhoneNUmber] = useState();

  // 이메일 번호 state
  const [email, setEmail] = useState();
  // 인증 여부 state
  const [vertify, setVertify] = useState(false);
  // 로딩 state
  const [isLoading, setIsLoading] = useState(false);
  // 에러 메세지 state
  const [errorMsg, setErrorMsg] = useState(null);

  const fetchData = async (data) => {
    try {
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_VERIFY_PHONE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      // console.log(responseData);
      if (response.status === 200) {
        setPhoneNUmber(data.phone);
        setEmail(data.email);
        setVertify(true);
        setIsLoading(true);
        // console.log(responseData);
        return responseData;
      }
      if (response.status === 400) {
        return setErrorMsg(responseData.error);
      }
      if (response.status === 403) {
        return setErrorMsg(responseData.error);
      }
    } catch (error) {
      console.log("error");
    }
  };
  return { phoneNumber, vertify, isLoading, errorMsg, fetchData, email };
};
export default usePhoneCertificationApi;
