import styled from "styled-components";
import { theme } from "../../../components/theme/theme";

export const CenteredBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  width: 100%;
  p {
    color: #969696;
    font-size: 22px;
    font-weight: 500;
    margin-top: 5px;
  }
`;
export const PaymentContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  /* height: ${(props) => `${props.contentHeight}px`}; */
  padding: 80px 18px 100px 18px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-direction: column; */
  /* align-items: center; */
  form {
    width: 100%;
  }
`;
export const PaymentViewBox = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

export const PaymentViewContentBox = styled.div`
  height: 180px;
  display: flex;
  align-items: center;
  /* padding-left: 2px; */
  width: 100%;
  display: flex;
  /* transition: all 0.5s ease-in-out; */
  /* transform: ${(props) =>
    props.currentLocation && `translateX(${props.currentLocation}px)`}; */
`;

export const PaymentCheckBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px;
  margin-bottom: 30px;
  input {
    width: 20px;
    height: 20px;
  }
  .label {
    font-size: 18px;
  }
`;

export const PaymentSelectBtnBox = styled.div`
  /* padding-top: 20px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  div:nth-of-type(1) {
    margin-bottom: 20px;
  }
`;

export const PaymentSelectBtn = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.backColor ? "white" : "white")};
  font-size: 20px;
  font-weight: 600;
  border: ${(props) =>
    props.backColor ? "2px solid #412BD5" : "2px solid 	#aaaaaa"};
  color: ${(props) => (props.color ? "#412BD5" : "#aaaaaa")};
`;

export const PaymentSelectDot = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(props) => (props.dotColor ? "#412BD5" : "white")};
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid white;
`;

export const PaymentBar = styled.div`
  width: 100%;
  border-bottom: 2px solid #c8c8c8;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const PaymentListBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 15px;
`;

export const PaymentListItem = styled.div`
  width: 100%;
  height: 80px;
  border: 1px solid black;
  border-radius: 8px;
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.clickPayment ? "#412BD5" : "#c8c8c8")};
  font-size: 18px;
  border: ${(props) =>
    props.clickPayment ? "2px solid #412BD5" : "2px solid #c8c8c8"};
`;

export const PaymentListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .billtype {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    .mainbill {
      font-size: 12px;
      margin-left: 5px;
      color: #ff8200;
    }
  }
  .billnumber {
    font-size: 18px;
  }
`;
