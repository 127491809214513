import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authSliceReducer from "./Auth/authInfo-slice";
import carInfoSliceReducer from "./CarInfo/carInfo-slice";
import selectCardInfoSliceReducer from "./SelectCardInfo";
import prevUrlReducer from "./PrevUrl";

const store = configureStore({
  reducer: {
    authInfo: authSliceReducer,
    carInfo: carInfoSliceReducer,
    selectCardInfo: selectCardInfoSliceReducer,
    prevUrl: prevUrlReducer,
  },
  // thunk 을 사용하기 위해서 설정
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
