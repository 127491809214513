import styled from "styled-components";

export const JoinSuccessContainer = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  height: ${(props) => props.height && `${props.height}px`};
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 100px 18px 0 18px;
  /* Container-color: #fff; */
`;

export const JoinSuccessTextBox = styled.div`
  padding: 20px;
  background-color: white;
  /* Container-color: #f5f5f5; */
  /* border: 1px solid #aaaaaa; */
  width: 100%;
  height: 180px;
  flex-direction: column;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const JoinSuccessText = styled.h3`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  & > span {
    color: #288cff;
  }
`;

export const PaymentText = styled.div`
  color: #828282;
  width: 100%;
  /* margin-top: 20px; */
  line-height: 20px;
  font-size: 18px;
  text-align: center;
`;

export const JoinSuccessBtnBox = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 18px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  button:nth-last-of-type(1) {
    margin-left: 15px;
  }
`;

export const JoinSuccessBtn = styled.button`
  font-size: 12px;
  width: 100%;
  height: 40px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backColor};
  color: ${(props) => props.color};
`;
