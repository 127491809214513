import { ConnectorErrorMsg, Container, UnChargeableContainer } from "./style";

// 커넥터 충전 불가능

const UnChargeable = () => {
  return (
    <UnChargeableContainer>
      <ConnectorErrorMsg>
        현재 커넥터는 이용할 수 없는 <br />
        커넥터 자리 입니다.
      </ConnectorErrorMsg>
    </UnChargeableContainer>
  );
};

export default UnChargeable;
