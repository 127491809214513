import React from "react";
import { Link } from "react-router-dom";
import AuthButton from "../../../Common/Button/AuthButton";
import { theme } from "../../../theme/theme";
import { NoAuthBox } from "./NoAuthStyle";

const NoAuth = () => {
  return (
    <NoAuthBox>
      <Link to="/users/login" style={{ marginRight: "8px" }}>
        <AuthButton
          color="#412BD5"
          bgColor="white"
          text="로그인"
          fontSize="15px"
        />
      </Link>
      <Link to="/users/account">
        <AuthButton
          bgColor={theme.colors.SubFontColor}
          color="white"
          text="회원가입"
          fontSize="15px"
        />
      </Link>
    </NoAuthBox>
  );
};

export default NoAuth;
