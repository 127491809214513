import React from "react";
import {
  ChargePrice,
  ChargePriceTable,
  ChargePriceTableBox,
  GetNumber,
  PaidPolicy,
  PriceContainer,
  PriceTitle,
  SubText,
  Text,
  TextContainer,
} from "./PriceStyle";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";

const Price = () => {
  return (
    <Container bgColor="#f5f5f5">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="FEC 충전 요금표"
      />
      <PriceContainer>
        <PriceTitle>| 충전요금</PriceTitle>
        <ChargePriceTableBox>
          <ChargePriceTable>
            <thead>
              <tr>
                <th></th>
                <th>회원</th>
                <th>비회원</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>주간(09:00~18:00)</td>
                <td>kW 당 290원</td>
                <td>kW 당 290원</td>
              </tr>
              <tr>
                <td>야간(18:00~24:00)</td>
                <td>kW 당 290원</td>
                <td>kW 당 290원</td>
              </tr>
              <tr>
                <td>심야(00:00~09:00)</td>
                <td>kW 당 290원</td>
                <td>kW 당 290원</td>
              </tr>
            </tbody>
          </ChargePriceTable>
        </ChargePriceTableBox>
      </PriceContainer>
    </Container>
  );
};

export default Price;
