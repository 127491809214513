import React, { useState } from "react";
import {
  AgreementModalCloseBtn,
  AgreementTextBox,
  AgreementModalLayout,
} from "./AgreemnetModal.style";
import { termsOfServiceContent } from "../../../../pages/termsofservice/TermsOfServiceContent";

// 이용약관 모달 창

const AgreementModal = ({ agreementCloseClick }) => {
  return (
    // <ModalWrapper onClick={onClose}>
    <AgreementModalLayout>
      <AgreementModalCloseBtn onClick={agreementCloseClick}>
        <span class="material-symbols-outlined">close</span>
      </AgreementModalCloseBtn>
      <h1 style={{ fontSize: "18px", fontWeight: "600" }}>
        서비스이용약관, 개인정보처리방침
      </h1>
      {termsOfServiceContent.map((item, idx) => {
        return (
          <>
            <h2
              style={{ fontSize: "30px", fontWeight: "600", marginTop: "20px" }}
            >
              {item.title}
            </h2>
            {item.main.map((item, idx) => {
              return (
                <AgreementTextBox id={idx} key={idx}>
                  <h3 className="title">{item.contentTitle}</h3>
                  {item.content.map((item, index) => {
                    return (
                      <span key={index} className="content">
                        {item}
                      </span>
                    );
                  })}
                </AgreementTextBox>
              );
            })}
          </>
        );
      })}
    </AgreementModalLayout>
    // </ModalWrapper>
  );
};

export default AgreementModal;
