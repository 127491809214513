import axios from "axios";
import { useEffect, useState } from "react";

const useChargeLocationRequest = () => {
  const [availableChargersData, setAvailableChargersData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("팩트 실행");
        console.log("요청");
        const response = await axios.get(
          process.env.REACT_APP_API_CHARGE_LOCATION,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // console.log("받아오는 데이터", response.data);
        if (response.status === 200 || response.status === 204) {
          // console.log("responseData", response.data);
          return setAvailableChargersData(Object.values(response.data));
        }
        // else if (!response.ok) {
        //   console.log("! response ok");
        // }
      } catch (error) {
        console.log("error", error.response.data.error);
      }
    };
    console.log("실행");
    fetchData();
  }, []);
  return { availableChargersData };
};
export default useChargeLocationRequest;
