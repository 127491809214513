import React from "react";
import { Link } from "react-router-dom";
import { NoticeItemBox } from "./style";

const NoticeItem = ({ list }) => {
  // console.log(list);
  // console.log(Date.now());
  // console.log(Date.parse(list.posttime));
  // console.log(
  //   "걸린 시간 : ",
  //   (Date.now() - Date.parse(list.posttime)) / 1000 / 60 / 60 / 24
  // );
  const time = (Date.now() - Date.parse(list.posttime)) / 1000 / 60 / 60 / 24;
  const date = new Date(list && list.posttime);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return (
    <Link to={`/community/${list.id}`}>
      <NoticeItemBox>
        <h2 className="notices">
          {time < 14 && <span className="new-text">new</span>}
          <span className="notice">{list.noticetype}</span>
          <span className="notice-title">{list.title}</span>
        </h2>
        <div className="date">
          <span>
            {year}.{month}.{day}
          </span>
        </div>
      </NoticeItemBox>
    </Link>
  );
};

export default NoticeItem;
