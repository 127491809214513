import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ChargeInfoBox } from "./ChargeInfoStyle";
import Button from "../../Common/Button/Button";
import FooterButton from "../../Common/Button/FooterButton";

// 충전소 위치와 충전 요금 버튼을 보여주는 컴포넌트
const ChargeInfo = ({ onclickMypage }) => {
  const { pathname } = useLocation();
  // console.log("pathname", pathname);
  const [currentPage, setCurrentPage] = useState(pathname);
  // useEffect(() => {
  //   setCurrentPage(param);
  // }, [param]);
  // console.log("랜더링");

  return (
    <ChargeInfoBox>
      {/* <Link to="/address">
        <Button bgColor={"white"} color="#412BD5" href="/address">
          <span class="material-symbols-outlined">location_on</span>
          <span style={{ whiteSpace: "nowrap" }}>충전소 위치</span>
        </Button>
      </Link> */}
      <Link to="/">
        <FooterButton
          bgColor={"white"}
          color={currentPage === "/" ? "#412BD5" : "#505050"}
        >
          <span className="material-symbols-outlined">home</span>
          <span style={{ whiteSpace: "nowrap" }}>홈</span>
        </FooterButton>
      </Link>
      <Link to="/qrcodescanner">
        <FooterButton
          bgColor={"white"}
          color={currentPage === "/qrcodescanner" ? "#412BD5" : "#505050"}
        >
          <span className="material-symbols-outlined">qr_code_scanner</span>
          <span style={{ whiteSpace: "nowrap" }}>QR코드 촬영</span>
        </FooterButton>
      </Link>
      <Link to="/charge/status">
        <FooterButton
          bgColor={"white"}
          color={currentPage === "/charge/status" ? "#412BD5" : "#505050"}
        >
          <span className="material-symbols-outlined">
            battery_charging_full
          </span>
          <span style={{ whiteSpace: "nowrap" }}>내 충전 실황</span>
        </FooterButton>
      </Link>
      <div onClick={onclickMypage}>
        <FooterButton
          bgColor={"white"}
          color={currentPage === "/usermenu" ? "#412BD5" : "#505050"}
        >
          <span className="material-symbols-outlined">account_circle</span>
          <span style={{ whiteSpace: "nowrap" }}>내 정보</span>
        </FooterButton>
      </div>
      <Link to="/menu">
        <FooterButton
          bgColor={"white"}
          color={currentPage === "/menu" ? "#412BD5" : "#505050"}
        >
          <span className="material-symbols-outlined">menu</span>
          <span style={{ whiteSpace: "nowrap" }}>메뉴</span>
        </FooterButton>
      </Link>
    </ChargeInfoBox>
  );
};

export default ChargeInfo;
