import { useEffect, useState } from "react";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import AgreeWithdraw from "../../components/MembeWithdraw/AgreeWithdraw/AgreeWithdraw";
import CheckPassword from "../../components/MembeWithdraw/CheckPassword/CheckPassword";
import WithdrawReason from "../../components/MembeWithdraw/WithdrawReason/WithdrawReason";
import CertificationCheck from "../../components/Auth/Account/CertificationCheck";
import WithdrawSucess from "../../components/MembeWithdraw/WithdrawSuccess/WithdrawSuccess";
import PhoneCertification from "../../components/QrCertification/components/PhoneCertification/PhoneCertification";
import { useNavigate } from "react-router";

// 회원탈퇴 페이지
const UserMemberWithdraw = () => {
  const [isAgreeWithdraw, setIsAgreeWithdraw] = useState(false);
  const [isWithdrawReason, setIsWithdrawReason] = useState(false);
  const [isWithdrawSucess, setIsWithdrawSucess] = useState(false);
  const [selectWithdrawReason, setSelectWithdrawReason] = useState("");
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  const [certificationPhonNumberCheck, setCertificationPhoneNumberCheck] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  // 토근 state
  const [tokens, setToken] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);

  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        title={"회원탈퇴"}
      />
      <div
        style={{
          height: `${contentHeight}px`,
          width: "100%",
          // padding: "70px 18px 0 18px",s
          background: "#f5f5f5",
        }}
      >
        {isAgreeWithdraw && isWithdrawReason && certificationPhonNumberCheck ? (
          <WithdrawSucess selectWithdrawReason={selectWithdrawReason} />
        ) : isAgreeWithdraw &&
          isWithdrawReason &&
          !certificationPhonNumberCheck ? (
          <PhoneCertification
            setCertificationCheck={setCertificationPhoneNumberCheck}
            setPhoneNumber={setPhoneNumber}
            setToken={setToken}
          />
        ) : isAgreeWithdraw && !isWithdrawReason ? (
          <WithdrawReason
            setIsWithdrawReason={setIsWithdrawReason}
            setSelectWithdrawReason={setSelectWithdrawReason}
            contentHeight={contentHeight}
          />
        ) : (
          <AgreeWithdraw
            setIsAgreeWithdraw={setIsAgreeWithdraw}
            contentHeight={contentHeight}
          />
        )}
      </div>
    </Container>
  );
};

export default UserMemberWithdraw;
