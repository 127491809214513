import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ButtonLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 200px;
  display: flex;
  justify-content: center;
`;
export const AgreementMsg = styled.div`
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  color: ${theme.colors.SubFontColor};
  text-decoration: underline;
`;

export const Button = styled.button`
  font-weight: 600;
  width: 100%;
  height: 51px;
  border: none;
  pointer-events: ${(props) => (props.isActiveBtn ? "auto" : "none")};
  background-color: ${(props) =>
    props.isActiveBtn ? `${theme.colors.pointColor}` : "#b4b4b4"};
  /* background: ${theme.colors.pointColor}; */
  border-radius: 8px;
  margin-top: 20px;
  font-size: 18px;
  color: #ffffff;
`;

// Auth sigin up
export const AgreementCheckBox = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 20px;
`;

// 로그인 페이지에서 다른 옵션들 박스
export const LoginOtherOptionBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  & > a {
    margin-right: 8px;
    color: #969696;
    font-weight: 600;
  }
`;

export const AgreementItem = styled.div`
  width: 100%;
  display: flex;
  .item__text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .essential {
      color: red;
      font-weight: bold;
      margin-right: 3px;
    }
    .service {
      /* color: rgb(65, 43, 213); */
      font-weight: bold;
    }
    .detail {
      text-decoration: underline;
    }
  }
`;

export const CheckBox = styled.div`
  margin-top: 2px;
  background-color: white;
  position: relative;
  width: 18px;
  height: 18px;
  border: ${(props) =>
    props.check ? "1px solid #412BD5" : "1px solid #b4b4b4"};
  border-radius: 50%;
  margin-right: 5px;
  .checkbox__left {
    position: absolute;
    width: 9px;
    height: 3px;
    transform: rotate(40deg) translate(6px, 6px);
    border-radius: 2px;
    background-color: ${(props) => (props.check ? "#412BD5" : "#b4b4b4")};
  }
  .checkbox__right {
    position: absolute;
    width: 12px;
    height: 3px;
    transform: rotate(-65deg) translate(-4px, 6px);
    border-radius: 2px;
    background-color: ${(props) => (props.check ? "#412BD5" : "#b4b4b4")};
  }
`;
