import { createSlice } from "@reduxjs/toolkit";

// 초깃값 설정
const initialAuthState = {
  userInfo: null,
  loading: false,
  error: null,
};

//createSlice 로 reducers 을 관리
const authSlice = createSlice({
  name: "authInfo",
  initialState: initialAuthState,
  reducers: {
    // userInfo data  받아오기 전
    fetchAuthStart(state) {
      state.loading = true;
      state.error = null;
    },
    // userInfo 받아 올 경우
    fetchAuthSucess(state, action) {
      state.loading = false;
      state.userInfo = action.payload;
    },
    // userInfo에서 error 날 경우
    fetchAuthFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
// 다른 컴포넌트에서 사용할 수 있도록 action을 발동시키기 위해서 export 해줌.
export const { fetchAuthFailure, fetchAuthStart, fetchAuthSucess } =
  authSlice.actions;

// authSlice 설정 값을 export default 해줌

const authSliceReducer = authSlice.reducer;
export default authSliceReducer;
