// 차량브랜드, 종류, 커넥터 종류 모으는 곳

export const carBrandList = [
  { name: "현대" },
  { name: "기아" },
  { name: "테슬라" },
];
export const hyundaCar = [
  { name: "2023 아이오닉6" },
  { name: "2023 아이오닉5" },
  { name: "2021 넥소" },
  { name: "코나" },
];
export const kiaCar = [
  { name: "2023 EV6 GT" },
  { name: "2023 EV6" },
  { name: "2023 니로 EV" },
  { name: "2023 니로 플러스" },
  { name: "2023 봉고3 EV" },
];
export const teslaCar = [
  { name: "2022 테슬라 모델 Y" },
  { name: "2022 테슬라 모델3" },
  { name: "2021 테슬라 모델X" },
  { name: "2021 테슬라 모델S " },
];

export const connectorList = [
  { name: "DC콤보1" },
  { name: "DC콤보2" },
  { name: "차데모" },
  { name: "AC3상" },
  { name: "GB/T" },
];

export const batteryAmount = [
  { name: "0.0" },
  // { name: "상의 후 결정2" },
  // { name: "상의 후 결정3" },
  // { name: "상의 후 결정4" },
];
