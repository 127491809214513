import React from "react";
import NoAuth from "../../../components/NoAuth/NoAuth";
import HomeUserInfo from "./HomeUserInfo/HomeUserInfo";
import { CompanyName, MenuList, NavBox } from "./HomeNav.style";
// 홈 화면에서 헤더의 네비게이션의 UI
const HomeNav = ({ userInfoData, bgColor }) => {
  return (
    <NavBox bgColor={bgColor}>
      {/* 메뉴 왼쪽 UI 회사이름*/}
      <CompanyName>FEC</CompanyName>

      {/* 메뉴 오른쪽 UI,  로그인 회원가입 관련 */}
      {/* 로그인이 되어 있는지에 대한 여부에 따라서 다른 컴포넌트 보여주기 */}
      {userInfoData && userInfoData.user.token ? (
        <HomeUserInfo userInfoData={userInfoData} />
      ) : (
        <NoAuth />
      )}
    </NavBox>
  );
};

export default HomeNav;
