import { Fragment, useEffect, useState } from "react";
import AuthInput from "../../../components/Auth/Common/AuthInput/AuthInput";
import { PaymentCheckBox, PaymentContainer } from "./style";
import { Button, ButtonLoading } from "../../../components/Auth/style/styled";
import { useForm } from "react-hook-form";
import Loading from "../../../components/Common/Loading/Loading";
import { useNavigate, useParams } from "react-router";
import Header from "../../../components/Layout/Header/Header";
import { useSelector } from "react-redux";
import axios from "axios";
const PaymentUpdate = () => {
  const [serverError, setServerError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const { cardName } = useSelector((state) => state.selectCardInfo);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  // console.log("cardname", cardName);
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      nickname: cardName,
    },
  });

  console.log("error", errors, isValid);
  const updatePaymentInfo = async (da) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const data = {
        id,
        nickname: "",
        ismainbill: "TRUE",
      };
      console.log("요청");
      const response = await axios.patch(
        process.env.REACT_APP_API_BILLING_UPDATE,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        navigate(-1);
      }
    } catch (error) {
      if (error.response.status === 400) {
        setServerError(error.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 정보 수정"
        marginBottom="false"
      />
      <PaymentContainer contentHeight={contentHeight}>
        <form onSubmit={handleSubmit(updatePaymentInfo)}>
          <AuthInput
            id="nickname"
            label="결제수단 별명"
            name="nickname"
            // value={}
            register={register("nickname", {
              required: "별명은 필수 입니다.",
              minLength: {
                value: 1,
                message: "1글자 이상 입력해주세요.", // 에러 메세지
              },
              maxLength: {
                value: 6,
                message: "별명은 최대 6글자입니다.",
              },
            })}
            type="text"
          />
          <p style={{ color: "red" }}>{errors?.nickname?.message}</p>

          {serverError && <p style={{ color: "red" }}>{serverError}</p>}

          <PaymentCheckBox>
            <label className="label" for="ismainbill">
              주 결제수단으로 설정
            </label>
            <input
              type="checkbox"
              id="ismainbill"
              name="ismainbill"
              {...register("ismainbill")}
            />
          </PaymentCheckBox>
          <Button
            style={{ marginTop: 0, justifyContent: "center" }}
            isActiveBtn={isValid}
          >
            {isLoading ? (
              <ButtonLoading>
                <Loading />
              </ButtonLoading>
            ) : (
              "정보 수정"
            )}
          </Button>
        </form>
      </PaymentContainer>
    </>
  );
};

export default PaymentUpdate;
