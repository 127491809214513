import { useEffect, useRef } from "react";
import { ModalBackground, ModalBox, ModalBtn } from "../style/styled";

const NoPaymentRegisterModal = ({ setIsNoPaymentRegisterModal }) => {
  const ref = useRef();
  useEffect(() => {
    console.log("팩트 실행");
    // console.log("time 실행");
    const key = setTimeout(() => {
      ref.current.style.transform = "translateY(-10px)";
      ref.current.style.transition = "all 0.2s ease-in-out";
    });

    return () => {
      clearTimeout(key);
    };
  }, []);
  return (
    <ModalBackground>
      <ModalBox ref={ref} maxWidth={250}>
        <div>
          <p className="nopayment">결제수단은 최대 5개까지</p>
          <p className="nopayment">등록 가능합니다.</p>
          <div className="nopayment-btn">
            <ModalBtn
              type="button"
              color={false}
              onClick={() => {
                document.body.style.overflow = "";
                setIsNoPaymentRegisterModal(false);
              }}
            >
              확인
            </ModalBtn>
          </div>
        </div>
      </ModalBox>
    </ModalBackground>
  );
};

export default NoPaymentRegisterModal;
