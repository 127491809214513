import { useRef, useState } from "react";
import { Popover } from "react-tiny-popover";
import { useNavigate } from "react-router";
import {
  CardContainer,
  CardHeader,
  CardMain,
  CardNumberText,
  CardOptioModal,
  CardOptionModalItem,
  IsMainBillText,
  OtherButton,
} from "./style/style";
import { useDispatch } from "react-redux";
import { updateSelectCardInfo } from "../../store/SelectCardInfo";
import axios from "axios";

const PaymentCard = ({
  cardData,
  setIsDeleteModalOpen,
  setIsUpdate,
  isEdit = true,
  isActive = false,
}) => {
  const { nickname, fourdigit, billtype, billingkey, ismainbill, id } =
    cardData;
  // console.log("hello", cardData);
  let backgroundColor;
  let fontColor = "white";
  if (billtype === "국민카드") {
    backgroundColor = "#FFB432";
    fontColor = "black";
  } else if (billtype === "신한카드") {
    backgroundColor = "#288CFF";
  } else if (billtype === "카카오페이") {
    backgroundColor = "#FFDC3C";
    fontColor = "black";
  } else if (billtype === "하나카드") {
    backgroundColor = "#429F6B";
  } else if (billtype === "삼성카드") {
    backgroundColor = "#464646";
  } else if (billtype === "현대카드") {
    backgroundColor = "#A0A0FF";
  } else if (billtype === "비씨카드") {
    backgroundColor = "#FF5A5A";
  } else {
    backgroundColor = "black";
  }
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const settingsWindowRef = useRef(null);
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  // const updatePaymentInfo = async (id, ismainbill) => {
  //   // setLoading(true);
  //   const token = localStorage.getItem("token");
  //   try {
  //     const data = {
  //       id,
  //       nickname: "test",
  //       ismainbill,
  //     };
  //     console.log("요청");
  //     const response = await axios.patch(
  //       process.env.REACT_APP_API_BILLING_UPDATE,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `token ${token}`,
  //         },
  //       }
  //     );
  //     console.log("변경 성공!");
  //     if (response.status === 200 || response.status === 204) {
  //       setIsUpdate((prev) => !prev);
  //       console.log("변경 성공!");
  //     }
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       setError(error.response.data.error);
  //     }
  //   } finally {
  //     // setLoading(false);
  //   }
  // };
  return (
    <CardContainer
      isActive={isActive}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      <CardHeader>
        {/* <p className="title">{nickname}</p> */}

        {isEdit && (
          <Popover
            ref={settingsWindowRef}
            isOpen={isPopoverOpen}
            align="end"
            onClickOutside={() => setIsPopoverOpen(false)}
            positions={["bottom"]}
            content={
              <CardOptioModal>
                <CardOptionModalItem
                  onClick={() => {
                    // if (window.confirm("이 카드를 삭제하시겠습니까?")) {
                    //   deleteCard();
                    // }
                    setIsDeleteModalOpen(id);
                  }}
                >
                  <p>결제수단 삭제</p>
                </CardOptionModalItem>
                {/* {ismainbill === "FALSE" && ( */}
                {/* <CardOptionModalItem
                  onClick={() => {
                    updatePaymentInfo(
                      id,
                      ismainbill === "TRUE" ? "FALSE" : "TRUE"
                    );
                    // navigate(`/card/payment/management/${id}`);
                    // dispatch(updateSelectCardInfo(nickname));
                  }}
                >
                  <p>
                    {ismainbill === "TRUE"
                      ? "주 결제수단 해제"
                      : "주 결제수단 선택"}
                  </p>
                </CardOptionModalItem> */}
                {/* )} */}
              </CardOptioModal>
            }
          >
            <OtherButton
              onClick={() => {
                setIsPopoverOpen(!isPopoverOpen);
              }}
              style={{
                cursor: "pointer",
                paddingBottom: 10,
                paddingTop: 10,
              }}
              fontColor={fontColor}
            >
              <div className="btn"></div>
              <div className="btn"></div>
              <div className="btn"></div>
            </OtherButton>
          </Popover>
        )}
      </CardHeader>
      <CardMain>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="title weight-title">{billtype}</p>
          {/* {ismainbill === "TRUE" && (
            <IsMainBillText className="red">주 결제수단</IsMainBillText>
          )} */}
        </div>
        <CardNumberText>
          <div className="number-item">
            {fourdigit}*<div className="dot"></div>
          </div>
          <div className="number-item">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="number-item">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="number-item">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </CardNumberText>
      </CardMain>
    </CardContainer>
  );
};

export default PaymentCard;
