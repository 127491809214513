import React from "react";
import { ContentItemBox } from "./ContentItemStyle";

const ContentItem = ({ data: { title, text } }) => {
  return (
    <ContentItemBox>
      <h2 className="content-title">{title}</h2>
      <div className="text-box">{text}</div>
    </ContentItemBox>
  );
};
export default ContentItem;
