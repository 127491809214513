import { DescriptionContainer, DescriptionItem } from "./DescriptionStyle";
import { useLocation, useParams } from "react-router";
import {
  cardRegisterUser,
  chargeMethodDetail,
  useCommonRules,
} from "./DescriptionList";
import Header from "../Layout/Header/Header";
import Container from "../Common/Wrapper/Container";
import { useEffect, useState } from "react";

// 상세설명 리스트, Url 주소에 따라서 디테일 글을 화면에 보여준다.

const Description = () => {
  const { id } = useParams();

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);
  const header =
    id === "common-rules" ? (
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="공통이용 수칙"
      />
    ) : id === "charge-method" || id === "register" ? (
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={
          <>
            충전방법 <span style={{ color: "#FFB900" }}>상세 설명</span>
          </>
        }
      />
    ) : id === "unregister" ? (
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={
          <>
            비회원/신용카드 미등록 회원
            <br />
            충전방법 <span style={{ color: "#FFB900" }}>상세 설명</span>
            <br />
          </>
        }
      />
    ) : null;
  const descriptionData =
    id === "common-rules"
      ? useCommonRules
      : id === "charge-method" || id === "register"
      ? chargeMethodDetail
      : id === "unregister"
      ? chargeMethodDetail
      : null;
  return (
    <Container bgColor="#f5f5f5">
      {header}
      <DescriptionContainer contentHeight={contentHeight}>
        {descriptionData.map((item, idx) => {
          return (
            <DescriptionItem key={idx}>
              <div className="content">{item.content}</div>
              <div className="subtitle">{item.subTitle}</div>
            </DescriptionItem>
          );
        })}
      </DescriptionContainer>
    </Container>
  );
};

export default Description;
