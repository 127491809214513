import { useState } from "react";
import { useNavigate } from "react-router";

const usePostApi = () => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const postData = async (apiUrl, data, errorMsg, token, redirect) => {
    // console.log("errorsmesssage", errorMsg);
    setIsLoading(true);
    try {
      console.log("요청");
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", //Cross-Origin 요청을 할 때 요청에 자동으로 쿠키와 인증 헤더를 포함하도록 설정하는 역할
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      console.log("status", response.status);
      if (response.status === 200 || response.status === 201) {
        if (!token) {
          return;
        }
        if (token || response) {
          const token = responseData?.user?.token;
          document.cookie = `token=${token}; path=/; secure;`;
          localStorage.setItem("token", token);
        }
        // if (redirect) {
        //   navigate("/card/payment/management", { state: null });
        // } else {
        navigate("/");
        // }
        return responseData;
      } else if (response.status === 409) {
        if (errorMsg === "") {
          setIsError(responseData?.error);
        } else {
          setIsError(errorMsg);
        }
        console.log("409 error");
        return responseData;
      } else if (response.status === 400) {
        if (errorMsg === "") {
          setIsError(responseData?.errors?.error[0]);
        } else {
          setIsError(errorMsg);
        }
        console.log("400 error");
        return responseData;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return { isError, isLoading, postData };
};

export default usePostApi;
