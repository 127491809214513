import styled from "styled-components";

// 메인 화면
export const NavBox = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* max-width: 600px; */
  position: fixed;
  z-index: 2;
  background-color: ${(props) => props.bgColor};
  padding: 18px;
  font-size: 16px;
  color: #fff;
  height: 60px;
`;
export const CompanyName = styled.h1`
  height: 100%;
  display: flex;
  margin-right: 5px;
  font-weight: 600;
  font-size: 20px;
  color: white;
`;
