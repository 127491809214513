import styled from "styled-components";
import { theme } from "../../../theme/theme";
export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const InputLabel = styled.label`
  display: block; // label은 inline이라 block으로 변경하고
  margin-bottom: 8px; // margin-bottom을 줘야함
  // input 태그 focus 여부에 따라서 label 색 변경하기
  /* color: ${(props) =>
    props.isInputFocus
      ? theme.colors.pointColor
      : theme.colors.SubFontColor}; */

  color: ${theme.colors.SubFontColor};
`;
export const Inputs = styled.input`
  width: 100%;
  height: 45px;
  font-size: 12px;
  border: 1px solid ${theme.colors.FontGrayColor};
  background-color: white;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  &:focus {
    border: 1px solid ${theme.colors.pointColor};
  }
`;
