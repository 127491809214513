import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import * as S from "./OpenSourceLicense.style";
import { opensourceListData } from "./OpenSourceLicenseContent";

const OpenSourceLicense = () => {
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={"오픈소스 라이센스"}
        marginBottom="false"
      />
      <S.OpenSourceLicenseBox>
        {opensourceListData.map((item) => {
          return (
            <S.OpenSourceLicenseItem>
              <h1>{item.name}</h1>
              <span>{item.source}</span>
              <span>{item.copyright}</span>
              <span>{item.license}</span>
            </S.OpenSourceLicenseItem>
          );
        })}
      </S.OpenSourceLicenseBox>
    </Container>
  );
};

export default OpenSourceLicense;
