import styled from "styled-components";

export const WithdrawSucessBox = styled.div`
  padding: 80px 18px 0 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* padding: 0 18px; */
`;

export const WithdrawSuccessTextBox = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 15px;
`;
export const WithdrawSucessText = styled.span`
  text-align: center;
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
`;

export const HomeButtonBox = styled.div`
  width: 100%;
  padding: 0 18px;
  position: absolute;
  bottom: 30px;
`;
export const HomeButton = styled.button`
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  width: 100%;
  height: 40px;
  background-color: #412bd5;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  color: white;
`;

export const UserDeleteLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  & > div {
    margin-top: 20px;
  }
`;
