import { useState } from "react";
import {
  BatteryBtn,
  BatteryOptionForm,
  NextBtn,
  SelectBatteryAmountBox,
  SelectBatteryAmountLayout,
  SelectBatteryAmountTitle,
  SelectBatteryOptionBox,
} from "./SelectBatteryAmount.style";

const batteryOption = [
  "60%이상",
  "50~60%",
  "40~50%",
  "30~40%",
  "20~30%",
  "20%미만",
];

const SelectBatteryAmount = ({ setBatteryAmount }) => {
  const [selectBtn, setSelectBtn] = useState("");

  return (
    <SelectBatteryAmountLayout>
      <SelectBatteryAmountTitle>
        충전 완료 시간 계산을 위해 차량의 현재 배터리양을 선택해 주십시오.
      </SelectBatteryAmountTitle>
      <SelectBatteryOptionBox>
        {batteryOption.map((item) => {
          return (
            <BatteryBtn
              onClick={() => {
                if (selectBtn !== "" && selectBtn === item) {
                  setSelectBtn("");
                } else {
                  setSelectBtn(item);
                }
              }}
              selectBtn={selectBtn === item}
            >
              <div>
                <div className="left"></div>
                <div className="right"></div>
                {item}
              </div>
            </BatteryBtn>
          );
        })}
      </SelectBatteryOptionBox>
      <NextBtn
        onClick={() => {
          setBatteryAmount(
            selectBtn === "60%이상"
              ? "60"
              : selectBtn === "50~60%"
              ? "50"
              : selectBtn === "40~50%"
              ? "40"
              : selectBtn === "30~40%"
              ? "30"
              : selectBtn === "20~30%"
              ? "20"
              : selectBtn === "20%미만"
              ? "0"
              : null
          );
        }}
        selectBtn={selectBtn !== ""}
      >
        다음
      </NextBtn>
    </SelectBatteryAmountLayout>
  );
};

export default SelectBatteryAmount;
