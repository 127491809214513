import {
  HomeButton,
  HomeButtonBox,
  UserDeleteLoading,
  WithdrawSuccessTextBox,
  WithdrawSucessBox,
  WithdrawSucessText,
} from "./WithdrawSuccess.style";
import { useEffect, useState } from "react";
import Loading from "../../Common/Loading/Loading";

const WithdrawSucess = ({ selectWithdrawReason }) => {
  const [isLoading, setIsLoading] = useState(true);
  // console.log("탈퇴사유", selectWithdrawReason);
  // 로그아웃 되면서 토큰 삭제 하는 코드
  const deleteUser = async () => {
    try {
      console.log("요청");
      const token = localStorage.getItem("token");
      const response = await fetch(process.env.REACT_APP_API_USER_DELETE, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({ detail: selectWithdrawReason }),
      });
      // console.log("response delete", response);
      // console.log("response json data", response.json());
      if (response.status === 200) {
        // console.log("회원탈퇴 성공");
        setIsLoading(false);
        localStorage.removeItem("token");
        document.cookie =
          "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
        console.log("logout");
        // window.location.href = "/";s
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    console.log("팩트 실행");
    deleteUser();
  }, []);
  return (
    <>
      <WithdrawSucessBox>
        {isLoading ? (
          <UserDeleteLoading>
            회원 탈퇴를 진행 중 입니다.
            <div>
              <Loading />
            </div>
          </UserDeleteLoading>
        ) : (
          <>
            <WithdrawSuccessTextBox>
              {" "}
              <WithdrawSucessText>
                회원 탈퇴가 완료되었습니다.
              </WithdrawSucessText>
              <WithdrawSucessText>
                지금까지 (주)FEC를 이용해주셔서 감사합니다.
              </WithdrawSucessText>
            </WithdrawSuccessTextBox>
            <HomeButtonBox>
              <HomeButton
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                확인
              </HomeButton>
            </HomeButtonBox>
          </>
        )}
      </WithdrawSucessBox>
    </>
  );
};

export default WithdrawSucess;
