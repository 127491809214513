import styled from "styled-components";
import { theme } from "../../components/theme/theme";

export const PriceContainer = styled.main`
  background-color: white;
  width: 100%;
  /* max-width: 600px; */
  padding: 90px 18px 18px 18px;
`;
export const PriceTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;
export const TextContainer = styled.section`
  margin-top: 10px;
  margin-bottom: 30px;
  .sub-text {
    line-height: 24px;
  }
`;
export const Text = styled.div`
  font-size: ${(props) => props.fontSize || "20px"};
  font-weight: 600;
  line-height: 28px;
  color: ${(props) => props.color || "black"};
  span {
    color: ${theme.colors.pointColor};
  }
`;

export const ChargePriceTableBox = styled.div`
  width: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 10px;
`;

export const ChargePriceTable = styled.table`
  th,
  td {
    color: #464646;
    font-size: 10px;
    padding: 6px;
    border: 1px solid #aaaaaa;
  }
`;
