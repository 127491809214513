import styled from "styled-components";
import { AuthBtn } from "../style/styled";
// 로그인, 로그아웃 , 회원가입 등 Auth 버튼을 관리해주는 컴포넌트

const AuthButton = ({ color, bgColor, text, onClick, fontSize }) => {
  return (
    <AuthBtn
      onClick={onClick}
      color={color}
      bgColor={bgColor}
      fontSize={fontSize}
    >
      {text}
    </AuthBtn>
  );
};
export default AuthButton;
