import styled from "styled-components";

export const Form = styled.form`
  background: white;
  width: 100%;
  /* max-width: 600px; */
  overflow-y: auto;
  height: ${(props) => `${props.contentHeight}px`};
  padding: 80px 18px 50px 18px;
  .users-container {
    align-items: center;
    width: 100%;
    /* max-width: 600px; */
    display: flex;
    flex-direction: column;
  }
  .checkBox {
    display: flex;
    label {
      font-size: 13px;
      padding-left: 4px;
      line-height: 18px;
    }
  }
`;
