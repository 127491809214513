import { useEffect, useState } from "react";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import { termsOfServiceContent } from "./TermsOfServiceContent";

import {
  TermsOfServiceContainer,
  TermsOfServiceItem,
  TermsOfServiceItemBox,
  TermsOfServiceTitle,
} from "./TermsOfServiceStyle";

// 이용약관 페이지
// console.log(termsOfServiceContent);

const TermsOfService = () => {
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);
  return (
    <>
      <Container>
        <Header
          isBack={true}
          isHome={true}
          backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="white"
          title="이용약관"
          marginBottom="false"
        />
        <TermsOfServiceContainer contentHeight={contentHeight}>
          <TermsOfServiceTitle>이용약관</TermsOfServiceTitle>
          {termsOfServiceContent.map((item) => {
            return (
              <TermsOfServiceItemBox>
                <h2
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {item.title}
                </h2>
                {item.main.map((item) => {
                  return (
                    <div style={{ width: "100%", margin: "10px 0" }}>
                      <h3 style={{ fontSize: "15px", marginBottom: "5px" }}>
                        {item.contentTitle}
                      </h3>
                      {item.content.map((item) => {
                        return <TermsOfServiceItem>{item}</TermsOfServiceItem>;
                      })}
                    </div>
                  );
                })}
              </TermsOfServiceItemBox>
            );
          })}
        </TermsOfServiceContainer>
      </Container>
    </>
  );
};

export default TermsOfService;
