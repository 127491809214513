import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PaymentCreateSuccess from "../../../components/payment/components/PaymentCreateSuccess";
import Header from "../../../components/Layout/Header/Header";
import PaymentInputNickname from "../../../components/payment/components/PaymentInputNickname";

const PaymentCreate = () => {
  // 사용자의 결제 비밀번호를 설정한 여부에 대한 데이터를 가지는 필드가 필요함
  const { customerUid } = useParams();
  const [searchParams] = useSearchParams();
  const impSuccess = searchParams.get("imp_success");
  // console.log("inpusucess", typeof impSuccess);
  const navigate = useNavigate();
  const [isNickname, setIsNickname] = useState(false);
  const [inputNickname, setInputNickname] = useState("");

  useEffect(() => {
    console.log("팩트 실행");
    if (impSuccess === "false") {
      navigate("/users/login");
    }
  }, []);

  return (
    <Fragment>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 추가등록"
        marginBottom="false"
      />
      {/* {isNickname ? ( */}
      <PaymentCreateSuccess
        // onComplete={() => {
        //   navigate("/card/payment/management");
        // }}
        customerUid={customerUid}
        impSuccess={impSuccess}
        inputNickname={inputNickname}
      />
      {/* // ) : (
      //   <PaymentInputNickname
      //     setInputNickname={setInputNickname}
      //     setIsNickname={setIsNickname}
      //   />
      // )} */}
    </Fragment>
  );
};

export default PaymentCreate;
