import styled from "styled-components";
import { theme } from "../../../components/theme/theme";

export const ApplicationContainer = styled.div`
  padding: 60px 18px 18px 18px;
  border: 1px solid red;
`;
export const Box = styled.div`
  margin-top: ${(props) => props.marginTop};
`;
export const Text = styled.p`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color || theme.colors.pointColor};
  font-weight: ${(props) => props.fontWeight};
`;
export const Footer = styled.form`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
