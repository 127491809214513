import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import usePostApi from "../../../hooks/api/usePostApi";
import AuthForm from "../Common/AuthForm/AuthForm";
import DuplicateError from "../../Common/Errors/DuplicateError";
import AgreementModal from "./AgreementModal/AgreementModal";
import { DevTool } from "@hookform/devtools";
import InputNicknameAndId from "./InputNicknameAndId";
import InputPassword from "./InputPassword";
import AccountAgreement from "./AccountAgreement";
import { useNavigate } from "react-router";
import Container from "../../Common/Wrapper/Container";

const AuthInfo = ({ inputPhoneNumberData }) => {
  const navigate = useNavigate();
  let errorText;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      phone: inputPhoneNumberData,
      email: "",
      password: "",
      passwordCheck: "",
    },
    mode: "onBlur",
  });

  // 비밀번호와 비밀번호확인이 틀렸을 경우 관리해주는 상태
  const [passwordMismatch, setPasswordMismatch] = useState("");
  const [isAgreement, setIsAgreement] = useState(false);
  const { isError, isLoading, postData } = usePostApi();

  const agreementOpenClick = () => {
    setIsAgreement(true);
  };
  const agreementCloseClick = () => {
    setIsAgreement(false);
  };
  const [isInputNicknameAndId, setIsInputNicknameAndId] = useState(false);
  const [isInputPassword, setIsInputPassword] = useState(false);
  const [isServiceCheck, setIsServieCheck] = useState(false);
  // 회원가입 시 submit 함수  부분
  const handleRegistration = async (signData) => {
    console.log("signdata", signData);
    const { password, passwordCheck, name, email, phone, addr_detail } =
      signData; // register id인 password,passwordCheck 값
    console.log("동의가 필요 합니다.");
    if (!isServiceCheck) {
      setPasswordMismatch("이용약관 동의가 필요합니다.");
      return;
    }
    if (password !== passwordCheck) {
      setPasswordMismatch("입력하신 비밀번호와 비밀번호확인이 다릅니다.");
      return;
    }

    const signupData = {
      name,
      email,
      phone,
      password,
      home_addr: "",
      termcheck: isServiceCheck ? "true" : "false",
      // addr_num: addressData.postcode,
      // home_addr: addressData.address,
      // addr_detail,
    };
    setPasswordMismatch("");
    postData(
      process.env.REACT_APP_API_USER_ACCOUNT,
      signupData,
      "이미 가입되어있는 이메일이 있습니다.",
      // setError("email", { message: "이미 가입되어있는 이메일이 있습니다." }),
      false
    );
    navigate("/users/join/success");
  };
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <>
      {/* 주소 찾기 모달 */}
      {/* {isFindAddressModal && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "60px",
            paddingTop: "40px",
            Container: "white",
          }}
        >
          <button
            style={{
              border: "none",
              borderRadius: "50%",
              Container: "#412BD5",
              color: "white",
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "0",
              left: "5px",
              cursor: "pointer",
            }}
            onClick={() => setIsFindAddressModal((prev) => !prev)}
          >
            X
          </button>
          <DaumPostcodeEmbed
            onComplete={onComplete}
            autoClose={false}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )} */}
      <Container bgColor="#f5f5f5">
        <AuthForm
          onSubmit={handleSubmit(handleRegistration)}
          contentHeight={contentHeight}
        >
          {isAgreement ? (
            <AgreementModal agreementCloseClick={agreementCloseClick} />
          ) : (
            <>
              <DevTool control={control} />
              {isInputNicknameAndId && isInputPassword ? (
                <AccountAgreement
                  isServiceCheck={isServiceCheck}
                  setIsServieCheck={setIsServieCheck}
                  agreementOpenClick={agreementOpenClick}
                  isLoading={isLoading}
                  watch={watch}
                />
              ) : isInputNicknameAndId && !isInputPassword ? (
                <InputPassword
                  watch={watch}
                  register={register}
                  setIsInputPassword={setIsInputPassword}
                  errors={errors}
                  isValid={isValid}
                />
              ) : (
                <InputNicknameAndId
                  watch={watch}
                  register={register}
                  setIsInputNicknameAndId={setIsInputNicknameAndId}
                  errors={errors}
                  isValid={isValid}
                />
              )}

              {/* 패스워드가 일치하지 않거나 중복된 이메일이 있을경우 에러문구 */}
              {!errorText && passwordMismatch && (
                <DuplicateError text={passwordMismatch} />
              )}
              {!errorText && !passwordMismatch && isError && (
                <DuplicateError text={isError} />
              )}
            </>
          )}
        </AuthForm>
      </Container>
    </>
  );
};

export default AuthInfo;
