import styled from "styled-components";
import { InnerContainer } from "../../components/Common/Wrapper/InnerContainer";

export const ContentContainer = styled.main`
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  height: ${(props) => `${props.contentHeight}px`};
  background-color: #f5f5f5;
`;

// 홈 컨텐츠 타이틀
export const ContentTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: #ffff;
  /* font-weight: 400; */
  margin-bottom: 11px;
`;

export const HomeNotice = styled.div`
  width: 100%;
  padding: 22px 18px;
  background-color: #f5f5f5;
  border-radius: 8px;
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08); */
  margin-bottom: 18px;
`;

export const HomeNoticeTitle = styled.h1`
  font-size: 22px;
  font-weight: 600;
`;

export const HomeNoticeList = styled.ul`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HomeNoticeListItem = styled.li`
  /* width: ${(props) => `${props.windowWidth - 76}px`}; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0;
  .new {
    font-size: 16px;
    font-weight: 600;
    color: tomato;
    margin-right: 5px;
  }
  .notice-type {
    font-size: 16px;
    font-weight: 600;
    color: #412bd5;
    margin-right: 5px;
  }
`;

export const HomeInnerBox = styled.section`
  padding: 0 18px;
  position: absolute;
  top: 63px;
`;
