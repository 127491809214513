import React, { useCallback } from "react";
import QrScanner from "react-qr-scanner";
import { theme } from "../theme/theme";
import Container from "../Common/Wrapper/Container";
import Header from "../Layout/Header/Header";
const QrCode = () => {
  const qrScannerHandler = useCallback((result) => {
    if (result) {
      window.location.href = result.text;
    }
  }, []);
  const qrScannerErrorHandler = useCallback((error) => {
    console.log("qr scanner error;;;");
  }, []);
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        title="QR코드 촬영"
      />
      <QrScanner
        delay={300}
        onScan={qrScannerHandler}
        style={{ with: "100%" }}
        onError={qrScannerErrorHandler}
        constraints={{ video: { facingMode: { exact: "environment" } } }}
      />
    </Container>
  );
};

export default QrCode;
