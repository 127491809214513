import React from "react";
import {Oval} from "react-loader-spinner";
import { theme } from "../../theme/theme";

const BgLoading = ()=>{
    return(
        <Oval 
        color={`${theme.colors.mainColor}`}
        height={50} 
        width={100}
        />
    )
}

export default BgLoading;