import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomerService } from "../../style/styled";

const EmailBox = styled(CustomerService)``;

const Email = () => {
  return (
    <EmailBox>
      <Link to="mailto:contactus@feccharge.co.kr">
        {/* <span class="material-symbols-outlined">mail</span> */}
        {/* contactus@feccharge.co.kr */}
        이메일 문의하기
      </Link>
    </EmailBox>
  );
};

export default Email;
