import { useEffect } from "react";
import { useState } from "react";

// 공지 데이터를 불러오는 hooks api
const useNoticeRequest = () => {
  const [notice, setNotice] = useState([]);
  useEffect(() => {
    const noticeData = async () => {
      try {
        console.log("팩트 실행");
        console.log("통 실행");
        console.log("요청");
        const response = await fetch(process.env.REACT_APP_API_NOTICE, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        if (response.status === 200) {
          setNotice(responseData);
          return responseData;
        }
      } catch (e) {
        console.log("error", e);
      }
    };
    console.log("실행");
    noticeData();
  }, []);

  const noticeArr = Object.values(notice);
  const currentNotice = noticeArr[0];
  return { currentNotice, noticeArr, notice };
};
export default useNoticeRequest;
