import { useEffect, useState } from "react";
import AuthButton from "../Common/AuthButton";
import AuthInput from "../Common/AuthInput/AuthInput";
import DuplicateError from "../../Common/Errors/DuplicateError";

const InputPassword = ({
  watch,
  register,
  setIsInputPassword,
  errors,
  isValid,
}) => {
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { password } = watch();
  console.log(errors);

  return (
    <>
      <AuthInput
        label="비밀번호 (특수문자 포함 8~24자리)"
        placeholder={"비밀번호를 입력해주세요"}
        type="password"
        id="password"
        register={register("password", {
          required: "비밀번호를 입력해주세요.",
          pattern: {
            value: /(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,24}/,
            message: "특수문자 포함 8-24자리 입력해주십시오.",
          },
        })}
      />
      <AuthInput
        label="비밀번호확인"
        placeholder={"비밀번호를 다시 입력해주세요."}
        type="password"
        id="passwordCheck"
        register={register("passwordCheck", {
          required: "비밀번호확인을 입력해주세요.",
          validate: {
            check: (item) => {
              // console.log("hi", item);
              if (password !== item) {
                return "비밀번호가 일치하지 않아요";
              } else {
                return;
              }
            },
          },
        })}
      />
      {errors && errors?.password && (
        <DuplicateError text={errors?.password?.message} />
      )}
      {!errors.password && errors?.passwordCheck && (
        <DuplicateError text={errors?.passwordCheck?.message} />
      )}
      <AuthButton
        type="button"
        title="다음"
        onClick={() => setIsInputPassword((prev) => !prev)}
        isActiveBtn={isValid}
      />
    </>
  );
};

export default InputPassword;
