import styled from "styled-components";
import { theme } from "../../../theme/theme";
export const UnChargeableContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 18px;
`;
export const ConnectorErrorMsg = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  color: ${theme.colors.mainColor};
  line-height: 28px;
`;
