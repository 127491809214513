import { Link } from "react-router-dom";

export const content = [
  {
    title: (
      <>
        <div className="title-container">
          <div>
            <span>공통이용</span> 수칙
          </div>
          <Link to="/detail/common-rules/description">
            <img src="/img/more.svg" />
          </Link>
        </div>
      </>
    ),
    text: (
      <ul>
        <li>DC 콤보1 커넥터로만 충전할 수 있습니다.</li>
        <li>
          충전구가 차량 전면부에 있으면 "전면 주차“, 후면부에 있으면 ”후면 주차"
          해 주세요
        </li>
        <li>충전구가 차량 중앙의 측면에 있는 경우 충전할 수 없습니다.</li>
      </ul>
    ),
  },
  // {
  //   title: (
  //     <>
  //       <div className="title-container">
  //         <div>
  //           비회원/ 신용카드<span> 미등록</span> 회원
  //         </div>
  //         <Link to="/detail/unregister/description">
  //           <img src="/img/more.svg" />
  //         </Link>
  //       </div>
  //     </>
  //   ),
  //   text: `1. 충전소에 도착하여 빈 주차면에 주차.
  //   2. 주차면 번호를 확인 후 키오스크로 감.
  //   3. 휴대폰 번호를 입력하고 인증.
  //   4. 결제카드 인식.
  //   5. 주차면에 돌아와서 커넥터 연결.
  //   6. 커넥터 연결 상태를 휴대폰으로 확인.
  //   7. 이후 휴대폰으로 충전시작 및 종료를 알림.`,
  // },
  {
    title: (
      <>
        <div className="title-container">
          <div>
            <span>충전방법</span> 상세설명
          </div>
          <Link to="/detail/charge-method/description">
            <img src="/img/more.svg" />
          </Link>
        </div>
      </>
    ),
    text: (
      <ol type="1">
        <li>충전소의 빈 주차면에 주차</li>
        <li>
          주차한 주차면의 QR코드를 촬영 <br />
          -> 모바일웹으로 이동 충전 진행
        </li>
        <li>휴대폰 번호를 입력하고 인증</li>
        <li>전기차의 충전 전 충전율과 충전 희망 금액을 입력</li>
        <li>결제수단의 종류를 선택하여 입력</li>
        <li>휴대폰에 충전 신청 완료 문자 수신 후 커넥터 연결</li>
        <li>이후 휴대폰에 충전 시작 문자와 충전 종료 문자가 각각 수신됨</li>
      </ol>
    ),
  },
];
