import styled from "styled-components";

export const UserMenuContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 18px 30px 18px;
  background-color: white;
`;

export const UserMenuList = styled.ul`
  width: 100%;
  margin-top: 20px;
`;

export const UserMenuItem = styled.li`
  font-size: 20px;
  padding: 7px 0;
  margin-bottom: 10px;
  /* border-bottom: 2px solid #dcdcdc; */
  display: flex;
  align-items: center;
  span:nth-of-type(2) {
    margin-left: 10px;
  }
`;
