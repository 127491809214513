import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  JoinSuccessBtn,
  JoinSuccessBtnBox,
  JoinSuccessContainer,
  JoinSuccessText,
  JoinSuccessTextBox,
  PaymentText,
} from "./JoinSuccess.style";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";

const JoinSuccess = () => {
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  // console.log("hello", contentHeight);

  useEffect(() => {
    console.log("실행");
    localStorage.removeItem("token");
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <Container bgColor="#f5f5f5">
      <Header
        // isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="회원가입 완료"
        marginBottom="false"
      />
      <JoinSuccessContainer height={contentHeight}>
        <JoinSuccessTextBox>
          <JoinSuccessText>
            회원가입에 <span>성공</span>하셨습니다.
          </JoinSuccessText>

          <PaymentText>결제수단을 등록해주세요.</PaymentText>
          <PaymentText>
            충전신청시에 간편하게 결제 하실 수 있습니다.
          </PaymentText>
        </JoinSuccessTextBox>
        <JoinSuccessBtnBox>
          <JoinSuccessBtn
            onClick={() => navigate("/")}
            backColor={"#dcdcdc"}
            color={"#505050"}
          >
            홈
          </JoinSuccessBtn>
          <JoinSuccessBtn
            onClick={() =>
              // navigate("/card/payment/management", { state: "payment" })
              navigate("/users/login")
            }
            backColor={"#412BD5"}
            color={"white"}
          >
            {/* 결제수단 등록 */}
            로그인
          </JoinSuccessBtn>
        </JoinSuccessBtnBox>
      </JoinSuccessContainer>
    </Container>
  );
};

export default JoinSuccess;
