import styled from "styled-components";
import { theme } from "../../theme/theme";

export const HomeNoticeContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.subColor};
  padding: 0 18px;
  border-bottom: 2px solid #dcdcdc;
`;
export const Banner = styled.div`
  font-weight: 600;
  margin-right: 8px;
  white-space: nowrap;
`;
export const RecentNotice = styled.div`
  color: rgba(69, 69, 79, 0.7);
  white-space: nowrap;
  overflow: hidden;
  width: ${(props) => `${props.width}px`};
  text-overflow: ellipsis;
`;
