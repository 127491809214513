// import QrButton from "./components/QrButton+";

import QrButton from "./components/QrButton";

// 카드를 등록한 사용자에대한 설명하는 글 데이터
export const cardRegisterUser = [
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number1.svg" />
        </div>
        <div className="a">
          충전소에 도착하여 <span>빈 주차면에 주차합니다.</span>
        </div>
      </>
    ),
    subTitle: "(안내인이 있을 경우 지시에 따라 주차합니다.)",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number2.svg" />
        </div>
        <div className="a">
          커넥터 시스템의 <span>QR코드를 휴대폰으로 찍습니다.</span>
        </div>
      </>
    ),
    subTitle: (
      <>
        <ul>
          <li>
            <span>-</span> FEC 모바일 웹이 로그인 상태인 경우 전화번호 인증
            화면이 나타납니다.
          </li>
          <li>
            <span>-</span> 전화번호는 자동으로 나타나고 아래 [인증번호 받기]를
            누릅니다.
          </li>
          <li>
            <span>-</span> 인증번호를 입력한 후 확인 버튼을 누릅니다.
          </li>
        </ul>
      </>
    ),
  },
  {
    content: (
      <>
        <QrButton text="QR코드 촬영하러가기" />
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number3.svg" />
        </div>
        <div className="a">
          인증 후 커넥터 박스로부터 커넥터를 꺼내어{" "}
          <span>차량과 연결합니다.</span>
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number4.svg" />
        </div>
        <div className="a">
          충전 시스템으로부터 커넥터의 연결 상태가 <span>정상으로 체결</span>{" "}
          되었음을 확인합니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number5.svg" />
        </div>
        <div className="a">
          이후 휴대폰으로 <span>충전의 시작 및 종료 메시지</span>를 확인할 수
          있습니다.
        </div>
      </>
    ),
    subTitle: "케넥터가 연결되면 충전소에서 머물지 않으셔도 됩니다.",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number6.svg" />
        </div>
        <div className="a">
          충전종료 메시지가 오면 <span>1 시간 이내에 출차</span> 를 권유합니다.
        </div>
      </>
    ),
    subTitle:
      "출차가 늦어 질수록 주차요금 등 별도의 요금이 가중되어 부가될 수 있습니다.",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number7.svg" />
        </div>
        <div className="a">
          <span>휴대폰 인증이 용이하지 않을 경우에는</span>
          <br />
          비회원처럼 키오스크를 통해 충전을 진행할 수 있습니다.
        </div>
      </>
    ),
    subTitle:
      "DC 차데모,AC 3상 등에 적합한 차량은 DC콤보1 어댑터 없이는 사용이 불가합니다.",
  },
];

// 카드를 등록하지 않은 사용자에대한 설명하는 글 데이터
export const chargeMethodDetail = [
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number1.svg" />
        </div>
        <div className="a">
          충전소에 도착하여 <span>빈 주차면에 주차합니다.</span>
        </div>
      </>
    ),
    subTitle: "(안내인이 있을 경우 지시에 따라 주차합니다.)",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number2.svg" />
        </div>
        <div className="a">
          주차 후, 주차면 커넥터함에 부착된 QR의 위치를 확인합니다. 해당
          커넥터함의 QR 코드를 촬영하여 모바일웹에서 충전을 진행합니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number3.svg" />
        </div>
        <div>
          휴대폰 번호 입력란에 휴대폰 번호를 입력하고 인증 버튼을 누르고
          인증합니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number4.svg" />
        </div>
        <div>
          안내에 따라 차량의 배터리 양을 입력하고, 충전 금액을 입력합니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number5.svg" />
        </div>
        <div className="a">결제 수단 종류를 선택하여 입력합니다.</div>
      </>
    ),
    subTitle: (
      <>
        <>
          <ul className="subtitle-list">
            <li>
              <h3>[카드 등록 회원]</h3>
              <ul>
                <li>- 등록된 카드 자동으로 나타남</li>
                <li>
                  - 카드 선택 -{`>`} 다음 버튼 -{`>`} 결제 비밀번호(4자리)
                </li>
              </ul>
            </li>
            <li>
              <h3>[비회원]</h3>
              <ul>
                <li>
                  - 신용 체크카드 : 카드번호 16자리,
                  <br />
                  <span
                    style={{
                      color: "black",
                      marginLeft: "20px",
                      fontWeight: "normal",
                    }}
                  >
                    카드유효기간, 카드비밀번호 앞 2자리,
                  </span>
                  <span
                    style={{
                      color: "black",
                      marginLeft: "20px",
                      fontWeight: "normal",
                    }}
                  >
                    생년월일 6자리 (법인은 "사업자등록번호")
                  </span>
                </li>
                <li>- 카카오페이 등 : 각 결제수단의 특성에 따라 조치</li>
              </ul>
            </li>
          </ul>
        </>
      </>
    ),
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number6.svg" />
        </div>
        <div>
          충전 신청 완료 문자를 확인한 후 커넥터를 연결합니다.
          <br />
          만일 먼저 커넥터를 연결한 경우 그대로 있으면 됩니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number7.svg" />
        </div>
        <div>
          커넥터 연결 확인 문자가 도착하면 충전 신청 절차가 완료된 것이니 자리를
          비우셔도 괜찮습니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number8.svg" />
        </div>
        <div className="a">
          충전 종료 안내 문자를 받으시면 커넥터를 해제한 후 출차하면 됩니다.
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">&#8251;</div>
        <div className="a">
          충전 시작 후 <span>도중에 충전 종료를 희망</span>할 경우에는{" "}
          <span>
            반드시 키오스크에서 충전신청시 입력한 휴대폰번호를 입력 후
          </span>{" "}
          인증을 받아 충전 취소를 하여야 가능합니다.
        </div>
      </>
    ),
    subTitle: "",
  },
];

// 공통 이용 수칙 글에대한 설명하는 글 데이터
export const useCommonRules = [
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number1.svg" />
        </div>
        <div>
          <span>DC 콤보1 커넥터로만</span> 충전할 수 있습니다.
        </div>
      </>
    ),
    subTitle:
      "DC 차데모, AC 3상 등에 적합한 차량은 DC콤보1 어댑터 없이는 사용이 불가합니다.",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number2.svg" />
        </div>
        <div>
          충전 시작 후 <span>80분 초과 시,</span> 충전은 자동 종료됩니다.
        </div>
      </>
    ),
    subTitle:
      "다만, 80분 이전이라도 충전률이 83%에 달하면 충전이 자동 종료됩니다.",
  },
  // {
  //   content: (
  //     <>
  //       <div className="title">
  //         <img src="/img/ListNumber/number3.svg" />
  //       </div>
  //       <div>
  //         충전구가 차량 중앙의 측면에 있는 경우 <span>충전할 수 없습니다.</span>
  //       </div>
  //     </>
  //   ),
  //   subTitle: "",
  // },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number3.svg" />
        </div>
        <div className="a">
          충전구가 차량 전면부에 있으면 <span>"전면 주차"</span> 충전구가
          후면부에 있으면<span> "후면 주차" </span> 해 주세요.
        </div>
      </>
    ),
    subTitle: "충전구가 차량 중앙의 측면에 있는 경우 충전할 수 없습니다.",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number4.svg" />
        </div>
        <div>
          충전 완료 후 충전기 커넥터를 원위치 시키고,
          <span> 커넥터 도어를 꼭 닫아주세요.</span>
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number5.svg" />
        </div>
        <div className="a">
          본인의 부주의로 충전기가 파손될 경우,
          <span> 법적 책임이 따르므로 주의해 주세요.</span>
        </div>
      </>
    ),
    subTitle: "",
  },
  {
    content: (
      <>
        <div className="title">
          <img src="/img/ListNumber/number6.svg" />
        </div>
        <div>
          충전소 주변에 <span>쓰레기를 버리지 마세요.</span>
        </div>
      </>
    ),
    subTitle: "",
  },
];
