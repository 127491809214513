import { useEffect, useState } from "react";
import {
  CenteredBox,
  PaymentContainer,
} from "../../../pages/card/payment/style";
import { theme } from "../../../components/theme/theme";
import { Oval } from "react-loader-spinner";
import axios from "axios";
import { json, useNavigate } from "react-router";

const PaymentCreateSuccess = ({
  impSuccess,
  customerUid,
  onComplete,
  inputNickname,
  inputPaymentPassword,
}) => {
  // 결제 비밀번호를 아직 어떻게 서버에 보낼지는 미정
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    console.log("실행");
    try {
      console.log("요청");

      const response = await fetch(process.env.REACT_APP_API_BILLING_REGISTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          nickname: "",
          billingkey: customerUid,
        }),
      });
      const data = await response.json();
      if (response.status === 200) {
        if (data?.paypass === "FALSE") {
          navigate("/card/payment/password/create/payment");
        } else if (data?.paypass === "TRUE") {
          navigate("/card/payment/management");
        }
        return response.data;
      } else if (response.status === 400) {
        setIsError(true);
        setErrorText(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const fetchData = async () => {
  //   const token = localStorage.getItem("token");
  //   console.log("실행");
  //   try {
  //     const response = await axios.post(
  //       process.env.REACT_APP_API_BILLING_REGISTER,
  //       {
  //         billingkey: customerUid,
  //         nickname: inputNickname,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `token ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       if (onComplete) {
  //         onComplete();
  //       }
  //       return response.data;
  //     }
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       setErrorText(error.response.data.error);
  //     }
  //   } finally {
  //   }
  // };

  useEffect(() => {
    console.log("팩트 실행");
    if (impSuccess === "true") {
      fetchData();
    }
  }, []);

  return (
    <PaymentContainer>
      <CenteredBox>
        {isError || impSuccess === "false" ? (
          <>
            <p>결제수단 등록이 실패하였습니다.</p>
            {errorText && <p>{errorText}</p>}
          </>
        ) : (
          <Oval
            color={theme.colors.pointColor}
            secondaryColor={theme.colors.subColor}
            height={50}
            width={100}
          />
        )}
      </CenteredBox>
    </PaymentContainer>
  );
};

export default PaymentCreateSuccess;
