import styled from "styled-components";

// 결제 비밀번호 컨테이너
export const PaymentPasswordContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height && `${props.height}px`};
  padding: 140px 18px 0 18px;
  background-color: #f5f5f5;
`;
// 입력한 값이 화면에 보여지는 박스
export const InputValueBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  padding-top: 50px;
`;

// 사용자가 입력 할 수 있는 인터페이스 박스 (숫자, 삭제 등)
export const InputBox = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 60px;
`;
// 사용자가 입력 할 수 있는 인터페이스 아이템
export const InputItem = styled.div`
  width: 100%;
  height: 80px;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); */
  /* background-color: white; */
  border-radius: 8px;
  color: #787878;
  &:active {
    background-color: #d2d2ff;
  }
`;

// 결제 비밀번호 입력 완료 버튼 박스
export const PaymentPasswordBtnBox = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
`;
// 결제 비밀번호 입력 완료 버튼
export const PaymentPasswordBtn = styled.button`
  width: 100%;
  height: 50px;
  border: none;
  background-color: ${(props) => (props.isActive ? "#412BD5" : "#b4b4b4")};
  border-radius: 8px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  pointer-events: ${(props) => (props.isActive ? "auto" : "none")};
`;
