import styled from "styled-components";

export const InputInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputInfoItem = styled.div`
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: none;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  & > h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;
