import { useNavigate } from "react-router";
import {
  BackIcon,
  HeaderBox,
  HeaderContainer,
  HeaderTitle,
  HomeIcon,
} from "./HeaderStyle";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Modal from "../../Common/Modal/Modal";

const Header = ({
  backColor,
  color,
  isBack,
  isHome,
  homesrc,
  title,
  marginBottom,
  isModal,
}) => {
  const [isCheckModal, setIsCheckModal] = useState(false);
  const [link, setLink] = useState();
  // console.log("func", link);
  const navigate = useNavigate();
  const onBackClick = () => {
    if (isModal) {
      setLink(-1);
      document.body.style.overflow = "hidden";
      setIsCheckModal((prev) => !prev);

      return;
    }
    navigate(-1);
  };

  const onHomeClick = () => {
    if (isModal) {
      setLink("/");
      document.body.style.overflow = "hidden";
      setIsCheckModal((prev) => !prev);

      return;
    }
    navigate("/");
  };

  const scrollCurrentUpdate = (e) => {
    e.preventDefault();
    const { pageYOffset } = window; // 현재 화면의 제일 위의 높이 값
    // console.log(pageYOffset);

    setScrollCurrent(pageYOffset);
  };
  const [scrollCurrent, setScrollCurrent] = useState(0);
  const layoutRef = useRef();
  useEffect(() => {
    const layoutcurrent = layoutRef.current;
    layoutcurrent.addEventListener("wheel", scrollCurrentUpdate);
    return () => {
      layoutcurrent.removeEventListener("wheel", scrollCurrentUpdate);
    };
  }, []);
  // console.log("scroll", scrollCurrent);

  return (
    <>
      {isCheckModal && (
        <Modal
          type="check"
          text={`${title}을 취소하시겠습니까?`}
          setIsModal={setIsCheckModal}
          func={(setIsModal) => {
            navigate(link);
            setIsModal((prev) => !prev);
          }}
        />
      )}
      <HeaderContainer marginBottom={marginBottom} ref={layoutRef}>
        <HeaderBox backColor={backColor} scrollPosition={scrollCurrent > 0}>
          {/* 뒤로가기 */}
          {isBack ? (
            <BackIcon onClick={onBackClick}>
              <span
                class="material-symbols-outlined"
                style={{
                  color: `${backColor === "#5B5AC7" ? "white" : "black"}`,
                }}
              >
                chevron_left
              </span>
            </BackIcon>
          ) : null}
          {/* <img onClick={onBackClick} src={backsrc} alt="backIcon" /> */}

          {/*  header 제목 props */}
          {/* <span className="menu-title">{title} </span> */}
          <HeaderTitle color={color}>{title}</HeaderTitle>

          {/* 
                무료운영 기간 동안은 홈 버튼 비활성화 
                유료운영 기간 들어가면 홈 버튼 활성화
            */}
          {isHome ? (
            <HomeIcon onClick={onHomeClick}>
              <img src={homesrc} alt="home-icon" />
            </HomeIcon>
          ) : null}
        </HeaderBox>
      </HeaderContainer>
    </>
  );
};

export default Header;
