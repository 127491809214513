import styled from "styled-components";
import { InnerContainer } from "../../components/Common/Wrapper/InnerContainer";
export const MenuContainer = styled.main`
  width: 100%;
  background-color: white;
  height: ${(props) => `${props.contentHeight}px`};
  overflow-y: auto;
  padding: 60px 18px 30px 18px;
  position: relative;
  ::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c8c8c8;
  }
`;

export const MenuListBox = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
