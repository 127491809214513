import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import usePostApi from "../../hooks/api/usePostApi";
import AuthForm from "../../components/Auth/Common/AuthForm/AuthForm";
import AuthInput from "../../components/Auth/Common/AuthInput/AuthInput";
import ErrorMsg from "../../components/Common/Errors/ErrorMsg";
import { ButtonLoading } from "../../components/Auth/style/styled";
import Loading from "../../components/Common/Loading/Loading";
import AuthButton from "../../components/Auth/Common/AuthButton";
import Header from "../../components/Layout/Header/Header";
import LoginOtherOption from "../../components/Auth/Common/LoginOtherOptions";
import AccountCheckMsg from "../../components/Auth/Common/AccountCheckMsg";
import { useLocation } from "react-router";

const Rest_api_key = process.env.REACT_APP_REST_API_KEY;
const redirect_uri = "http://localhost:3000/oauth/kakao/callback";
const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
const UserLogin = () => {
  // const { state } = useLocation();

  // console.log("state", state);
  const { isError, isLoading, postData } = usePostApi();
  const [isActiveBtn, setIsActiveBtn] = useState(false);
  // useForm hook 사용하여 form, input 관리
  // 사용법은 react useform 공식문서에서 참고
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
  });

  // console.log("isValid", isValid);
  // 로그인 submit 하는 함수
  const authLoginHandler = async (loginData) => {
    // console.log("입력");
    // console.log("logindata", loginData);
    postData(
      process.env.REACT_APP_API_USER_LOGIN,
      loginData,
      "",
      // "이메일 및 비밀번호를 올바르게 입력 하십시오.",
      true
      // state && true
    );
  };
  // console.log("errors", errors);

  // console.log("iserror", isError);

  let errorText;
  if (errors.email && errors.password) {
    // 이메일, 비밀번호를 입력하지않고 로그인 버튼을 클릭 하였을 때
    errorText = "이메일, 비밀번호를 입력해주세요.";
  } else if (errors.email && !errors.password) {
    // 이메일은 입력하지 않고 비밀번호만 입력하고 로그인 버튼을 클릭 하였을 때
    errorText = errors.email.message;
  } else if (!errors.email && errors.password) {
    // 이메일을 입력하고 비밀번호를 입력하지 않고 로그인 버튼을 클릭 하였을 때
    errorText = errors.password.message;
  }
  console.log("errortext", errorText);
  const [inputPasswordType, setInputPasswordType] = useState(true);
  return (
    <Fragment>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="로그인"
      />
      <AuthForm onSubmit={handleSubmit(authLoginHandler)}>
        <AuthInput
          label="이메일"
          type="email"
          id="email"
          register={register("email", {
            required: "이메일을 입력해주세요.",
            pattern: {
              value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
              message: "이메일 형식이 아닙니다.",
            },
          })}
          placeholder="이메일을 입력해주세요."
        />
        <AuthInput
          label="비밀번호 입력"
          type={inputPasswordType ? "password" : "text"}
          id="password"
          register={register("password", {
            required: "비밀번호를 입력해주세요.",
            pattern: {
              value: /(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,24}/,
              message: "특수문자 포함 8-24자리 입력해주십시오.",
            },
          })}
          placeholder="비밀번호를 입력해주세요."
        />

        {/* <button
          type="button"
          style={{
            width: "40px",
            height: "30px",
            border: "none",
            background: "transparent",
            borderRadius: "10px",
          }}
          onClick={() => setInputPasswordType((prev) => !prev)}
        >
          <span class="material-symbols-outlined">visibility</span>
        </button> */}
        {/* 이메일 또는 비밀번호가 일치하지 않을 경우 , 에러문구  */}
        {errors && errorText && <ErrorMsg text={errorText} />}
        {!errorText && isError && <ErrorMsg text={isError} />}
        {isLoading ? (
          <AuthButton
            type="submit"
            title={
              <ButtonLoading>
                <Loading />
              </ButtonLoading>
            }
          />
        ) : (
          <AuthButton type="submit" title="로그인" isActiveBtn={isValid} />
        )}
        <LoginOtherOption />
        {/* <AccountCheckMsg text="계정이 없으신가요?" href="/users/account" /> */}
      </AuthForm>
      {/* 카카오톡 간편로그인 */}
      {/* <Link to={KAKAO_AUTH_URL}>
                <KaKaoLoginImg />
            </Link> */}
    </Fragment>
  );
};

export default UserLogin;
