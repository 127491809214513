import styled from "styled-components";
import { theme } from "../../theme/theme";

//Button
export const AuthBtn = styled.button`
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 26px;
  color: ${(props) => props.color || "black"};
  border-radius: 8px;
  border: none;
  padding: 4px 12px;
  background-color: ${(props) => props.bgColor || "white"};
  font-size: ${(props) => props.fontSize};
  white-space: nowrap;
`;

export const FooterBtn = styled.button`
  font-weight: 600;
  /* border-radius: 8px; */
  cursor: pointer;
  width: 100%;
  height: 51px;
  background-color: ${(props) => props.bgColor || theme.colors.pointColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) => props.color || "white"};
`;
export const Btn = styled.button`
  font-weight: 600;
  /* border-radius: 8px; */
  cursor: pointer;
  width: 100%;
  height: 51px;
  /* pointer-events: ${(props) => (props.isActiveBtn ? "auto" : "none")}; */
  /* background-color: ${(props) =>
    props.isActiveBtn ? `${theme.colors.pointColor}` : "#b4b4b4"}; */
  background-color: ${theme.colors.pointColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) => props.color || "white"};
`;

// Modal

export const ModalBackground = styled.div`
  position: fixed;
  padding: 18px 18px 30px 18px;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-height: 750px) {
    height: 115%;
  }
  @media screen and (max-height: 680px) {
    height: 120%;
  }
`;
export const ModalBox = styled.div`
  /* margin: 0 18px; */
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "240px")};
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translateY(-10px);
  transition: all 1s ease; */
  p {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .nologin {
    color: black;
  }
  .nopayment {
    color: black;
    margin-bottom: 5px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    button:nth-of-type(1) {
      margin-right: 8px;
    }
  }
  .nopayment-btn {
    width: 100%;
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
`;
export const ModalBtn = styled.button`
  font-weight: 600;
  border: none;
  width: 90px;
  height: 30px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.color ? "#dcdcdc" : theme.colors.pointColor};
  font-size: 13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.color ? "#505050" : "white")};
`;

// Errors
export const DuplicateMsg = styled.p`
  padding-top: 4px;
  text-align: center;
  color: rgb(91, 90, 199);
  margin-bottom: 10px;
  font-weight: 600;
`;
export const ErrorMsgBox = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${theme.colors.pointColor};
  text-align: center;
`;
export const ErrorMsgText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
export const Text = styled.div`
  margin: 15px 0 15px 0;
  p {
    text-align: center;
    color: red;
    font-size: 16px;
  }
`;
// 아래에 고정하는 스타일 컴포넌트
export const FixedWrapper = styled.footer`
  /* padding: 0 18px; */
  width: 100%;
  /* max-width: 600px; */
  position: fixed;
  bottom: 0;
  /* left: 0; */
  display: ${(props) => props.display};
`;
