import { useEffect, useState } from "react";
import Fixed from "../../../components/Common/Fixed/Fixed";
import Button from "../../../components/Common/Button/Button";
import {
  CenteredBox,
  PaymentBar,
  PaymentContainer,
  PaymentListBox,
  PaymentListItem,
  PaymentListItemContent,
  PaymentViewBox,
  PaymentViewContentBox,
} from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Layout/Header/Header";
import { authInfoData } from "../../../store/Auth/auth-api";
import PaymentCard from "../../../components/payment/PaymentCard";
import Modal from "../../../components/Common/Modal/Modal";
import NoPaymentRegisterModal from "../../../components/Common/Modal/NoPaymentRegisterModal";
import axios from "axios";
const list = [
  {
    nickname: "충전왕",
    fourdigit: "123",
    billtype: "삼성카드",
    ismainbill: "TRUE",
    id: 1,
  },
  {
    nickname: "충전왕 ",
    fourdigit: "851",
    billtype: "카카오페이",
    ismainbill: "",
    id: 1,
  },
  {
    nickname: "충전왕",
    fourdigit: "632",
    billtype: "신한카드",
    ismainbill: "",
    id: 1,
  },
  {
    nickname: "충전왕",
    fourdigit: "622",
    billtype: "하나카드",
    ismainbill: "",
    id: 1,
  },
  {
    nickname: "충전왕",
    fourdigit: "562",
    billtype: "국민카드",
    ismainbill: "",
    id: 1,
  },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "현대카드",
  //   ismainbill: "",
  //   id: 1,
  // },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "BC카드",
  //   ismainbill: "",
  //   id: 1,
  // },
];

const Payment = () => {
  const [isNoPaymentRegisterModal, setIsNoPaymentRegisterModal] =
    useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(null);
  const [isUpdate, setIsUpdate] = useState(true);
  // const [bills, setBills] = useState([]);
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  const [clickPayment, setClickPayment] = useState([]);

  const token = localStorage.getItem("token");
  const [cardListData, setCardListData] = useState([]);
  useEffect(() => {
    try {
      console.log("팩트 실행");
      fetchData();
    } catch (error) {
      console.log("error", error);
    }
  }, [isUpdate]);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setContentHeight(window.innerHeight);
      });
  }, []);

  const fetchData = async () => {
    console.log("요청");
    const response = await fetch(process.env.REACT_APP_API_BILLING_INFO, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    });
    const data = await response.json();
    // console.log("data", data);
    if (response.status === 200) {
      setCardListData(Object.values(data));
      if (Object.values(data).length > 0) {
        setClickPayment(Object.values(data)?.[0]);
      }

      return data;
    } else if (response.status === 403) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        // navigate("/users/login", { state: "payment" });
        navigate("/users/login", { state: "payment" });
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  };

  const deleteCardFunc = async (id, setIsDeleteModalOpen) => {
    try {
      console.log("요청");
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        process.env.REACT_APP_API_BILLING_DELETE,
        {
          data: {
            id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // setIsDeleted(true);
        fetchData();
        setIsDeleteModalOpen(null);
      } else {
        alert("에러가 발생하였습니다");
      }
    } catch (error) {
      if (error.response.status === 400) {
        console.log("error", error.response.data.error);
      }
    }
  };

  return (
    <>
      {isNoPaymentRegisterModal && (
        <NoPaymentRegisterModal
          setIsNoPaymentRegisterModal={setIsNoPaymentRegisterModal}
        />
      )}
      {isDeleteModal && (
        <Modal
          type="delete"
          text="이 카드를 삭제하시겠습니까?"
          setIsModal={setIsDeleteModal}
          id={isDeleteModal}
          func={deleteCardFunc}
        />
      )}
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 관리"
        marginBottom="false"
      />

      <PaymentContainer contentHeight={contentHeight}>
        {cardListData?.length > 0 ? (
          <>
            <PaymentViewBox>
              <PaymentViewContentBox>
                {/* {cardListData.length === 1 ? ( */}
                <PaymentCard
                  cardData={clickPayment}
                  setIsDeleteModalOpen={setIsDeleteModal}
                  setIsUpdate={setIsUpdate}
                />
              </PaymentViewContentBox>
            </PaymentViewBox>

            <>
              <PaymentBar></PaymentBar>
              <PaymentListBox>
                {cardListData?.map((item) => {
                  return (
                    <>
                      <PaymentListItem
                        clickPayment={
                          item?.billtype === clickPayment?.billtype
                            ? true
                            : false
                        }
                        onClick={() => setClickPayment(item)}
                      >
                        <PaymentListItemContent
                          clickPayment={
                            item?.billtype === clickPayment?.billtype
                              ? true
                              : false
                          }
                        >
                          {/* <span className="nickname">{item.nickname}</span> */}
                          <span className="billtype">
                            {item?.billtype}
                            {/* {item?.ismainbill === "TRUE" && (
                              <span className="mainbill">(주 결제)</span>
                            )} */}
                          </span>
                          <span className="billnumber">{item.fourdigit}*</span>
                        </PaymentListItemContent>
                      </PaymentListItem>
                    </>
                  );
                })}
              </PaymentListBox>
            </>
          </>
        ) : (
          <CenteredBox>
            <p>등록된 결제수단이</p>
            <p>없습니다.</p>
            <br />
            <p>하단 버튼을 통해 결제 수단을</p>
            <p>등록해 주세요.</p>
          </CenteredBox>
        )}
      </PaymentContainer>

      <Fixed>
        <Button
          onClick={() => {
            if (cardListData.length > 4) {
              document.body.style.overflow = "hidden";
              setIsNoPaymentRegisterModal(true);
              return;
            }
            navigate("/card/payment/select");
          }}
          type="submit"
          text="결제수단 추가등록"
          fontSize="18px"
        ></Button>
      </Fixed>
    </>
  );
};

export default Payment;
