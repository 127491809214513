import { useState } from "react";
import { useNavigate } from "react-router";

const useChargePriceApi = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const fetchData = async (data) => {
    try {
      setIsLoading(true);
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_QR_REQUEST, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        // setErrorMsg(null);
        // navigate("/sucess");
        return responseData;
      }
      if (response.status === 400) {
        return setErrorMsg(responseData.error);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  return { errorMsg, fetchData, isLoading };
};
export default useChargePriceApi;
