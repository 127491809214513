import styled from "styled-components";

export const TermsOfServiceContainer = styled.main`
  width: 100%;
  padding: 80px 18px 30px 18px;
  overflow-y: auto;
  height: ${(props) => `${props.contentHeight}px`};
`;

export const TermsOfServiceItemBox = styled.section`
  width: 100%;
  div:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const TermsOfServiceItem = styled.div`
  line-height: 16px;
  margin-bottom: 5px;
`;

export const TermsOfServiceTitle = styled.h1`
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
`;
