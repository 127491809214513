import styled from "styled-components";

export const HeaderContainer = styled.header`
  /* max-width: 600px; */
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  /* left: 0; */
  margin-bottom: ${(props) => (props.marginBottom === "false" ? "" : "33px")};
  width: 100%;
  z-index: 100;
`;
export const HeaderBox = styled.div`
  width: 100%;
  /* max-width: 600px; */
  position: relative;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backColor || "white"};
  /* border-bottom: ${(props) => props.backColor || "0.5px solid  #d2d2d2"}; */
  box-shadow: ${(props) =>
    props.scrollPosition ? "0px 3px 10px rgba(0, 0, 0, 0.1)" : ""};
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  text-align: center;
  color: black;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.color || "white"};
`;
export const BackIcon = styled.div`
  position: absolute;
  left: 18px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    font-size: 30px;
  }
  img {
    width: 7px;
    height: 14px;
  }
`;

export const HomeIcon = styled.div`
  position: absolute;
  right: 18px;
  width: 21px;
  height: 18px;
  img {
    width: 100%;
    height: 100%;
  }
`;
