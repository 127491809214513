import Email from "../Email/Email";
import PhoneNumber from "../PhoneNumber/PhoneNumber";
import {
  MenuItemBox,
  MenuItemSubList,
  MenuItemTitle,
  SubItem,
} from ".//MenuItemStyle";

const MenuItem = ({ content, title, onClickLink }) => {
  return (
    <>
      <MenuItemBox>
        <MenuItemTitle>
          {/* 좌측에 위치하고 있는 메뉴 제목 */}
          {title}
        </MenuItemTitle>
        <MenuItemSubList>
          {/* 메뉴 제목 우측에 있는 리스트 */}
          {title === "고객센터" && (
            <>
              <PhoneNumber />
              <Email />
            </>
          )}
          {content.map((item, idx) => (
            <SubItem key={idx} onClick={() => onClickLink(item.url)}>
              {item.name}
            </SubItem>
          ))}
        </MenuItemSubList>
      </MenuItemBox>
    </>
  );
};

export default MenuItem;
