import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  CenteredBox,
  PaymentBar,
  PaymentContainer,
  PaymentListBox,
  PaymentListItem,
  PaymentListItemContent,
  PaymentViewBox,
  PaymentViewContentBox,
} from "../../pages/card/payment/style";
import Fixed from "../Common/Fixed/Fixed";
import Button from "../Common/Button/Button";
import Header from "../Layout/Header/Header";
import PaymentCard from "../payment/PaymentCard";
const list = [
  {
    nickname: "충전왕",
    fourdigit: "123",
    billtype: "삼성카드",
    ismainbill: "TRUE",
    id: 1,
  },
  {
    nickname: "충전왕 ",
    fourdigit: "123",
    billtype: "카카오페이",
    ismainbill: "",
    id: 2,
  },
  {
    nickname: "충전왕",
    fourdigit: "123",
    billtype: "신한카드",
    ismainbill: "",
    id: 3,
  },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "하나카드",
  //   ismainbill: "",
  //   id: 4,
  // },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "국민카드",
  //   ismainbill: "",
  //   id: 5,
  // },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "현대카드",
  //   ismainbill: "",
  //   id: 6,
  // },
  // {
  //   nickname: "충전왕",
  //   fourdigit: "123",
  //   billtype: "BC카드",
  //   ismainbill: "",
  //   id: 7,
  // },
];
// 등록된 결제수단 선택 컴포넌트
const QrBilling = () => {
  // url 구조분해
  const { charge, id, token, price, batteryamount } = useParams();
  // portone 사용을 위해서 IMP 가져오기
  const { IMP } = window;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  // 등록한 결제수단을 담을 state
  const [payments, setPayments] = useState(null);
  // 현재 사용자가 클릭한 결제수단의 데이터를 담을 state
  const [clickPayment, setClickPayment] = useState(null);

  useEffect(() => {
    console.log("팩트 실행");
    IMP.init(process.env.REACT_APP_API_PORTONE_CODE);
    checkAccount();
  }, []);

  // 등록되어 있는 결제수단이 있는지 서버에 조회
  const checkAccount = async () => {
    setIsLoading(true);
    console.log("요청");
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BILLING_CHECKACCOUNT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      // console.log("res", data);
      if (response.status === 200) {
        const alert = data.alert;
        if (data["alert"]) {
          delete data.alert;
        }
        const values = Object.values(data);

        setPayments([...values]);
        if ([...values]?.length > 0) {
          setClickPayment([...values][0]);
        }
      } else if (response.status === 400) {
        console.log("error", data?.error);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("팩트 실행");
    // 등록되어 있는 결제수단이 없으면 바로 즉시 결제수단 선택으로 이동
    if (payments && payments?.length === 0) {
      navigate(
        `/qrbilling/payment/select/${charge}/${id}/${price}/${token}/${batteryamount}`
      );
    }
  }, [payments]);

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setContentHeight(window.innerHeight);
      });
  }, []);

  return (
    <Fragment>
      <Header
        // isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제 단계"
      />
      <div style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <CenteredBox>
            <>
              <p>결제 단계 진입 중입니다. 잠시</p>
              <p>기다려 주세요.</p>
            </>
          </CenteredBox>
        ) : (
          <>
            <PaymentContainer contentHeight={contentHeight}>
              {payments && payments?.length > 0 ? (
                <>
                  <PaymentViewBox>
                    <PaymentViewContentBox>
                      <>
                        <PaymentCard cardData={clickPayment} isEdit={false} />
                      </>
                    </PaymentViewContentBox>
                  </PaymentViewBox>
                  {payments?.length > 0 && (
                    <>
                      <PaymentBar></PaymentBar>
                      <PaymentListBox>
                        {payments?.map((item) => {
                          return (
                            <PaymentListItem
                              clickPayment={
                                item.billtype === clickPayment?.billtype
                                  ? true
                                  : false
                              }
                              onClick={() => setClickPayment(item)}
                            >
                              <PaymentListItemContent>
                                <span className="billtype">
                                  {item?.billtype}
                                  {/* {item?.ismainbill === "TRUE" && (
                              <span className="mainbill">(주 결제)</span>
                            )} */}
                                </span>
                                <span className="billnumber">
                                  {item.fourdigit}*
                                </span>
                              </PaymentListItemContent>
                            </PaymentListItem>
                          );
                        })}
                      </PaymentListBox>
                    </>
                  )}
                </>
              ) : (
                <CenteredBox>
                  <p>등록된 결제수단이</p>
                  <p>없습니다</p>
                  <br />
                  <p>하단 버튼을 통해 결제 수단을</p>
                  <p>입력해 주세요.</p>
                </CenteredBox>
              )}
            </PaymentContainer>
          </>
        )}
      </div>

      <Fixed>
        <Button
          onClick={() => {
            if (payments && clickPayment) {
              navigate(
                `/card/payment/password/check/${id}/${charge}/${token}/${price}/${batteryamount}/${clickPayment?.id}`
              );
            } else {
              navigate(
                `/qrbilling/payment/select/${charge}/${id}/${price}/${token}/${batteryamount}`
              );
            }
          }}
          type="submit"
          text={
            payments?.length > 0
              ? `${clickPayment.billtype}로 결제 진행`
              : "즉시 결제 실행"
          }
          fontSize="18px"
        />
      </Fixed>
    </Fragment>
  );
};

export default QrBilling;
//  `${selectPaymentName}로 결제 진행`;
