import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";

const useTokenPost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const navigate = useNavigate();
  const tokenPostData = async (apiUrl, data, navigator) => {
    console.log("실행입니다.");
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      console.log("요청");
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      // console.log("response", response);
      if (response.status === 200 || response.status === 204) {
        navigate(navigator);
        return response.data;
      } else if (response.status === 203) {
        if (response.data.carnumber.trim() === "") {
          return;
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setIsError(error.response.data.error);
      } else if (error.response.status === 409) {
        setIsError(error.response.data.error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { isError, isLoading, tokenPostData };
};

export default useTokenPost;
