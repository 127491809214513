import React from "react";
import { useForm } from "react-hook-form";
import usePostApi from "../../../hooks/api/usePostApi";
import AuthForm from "../Common/AuthForm/AuthForm";
import AuthInput from "../Common/AuthInput/AuthInput";
import AuthButton from "../Common/AuthButton";
import Loading from "../../Common/Loading/Loading";
import ErrorText from "../../Common/Errors/ErrorText";
import ErrorMsg from "../../Common/Errors/ErrorMsg";

const CertificationCheck = ({
  inputPhoneNumberData,
  setPhoneNumberAuthentication,
  timerValue,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      phone: inputPhoneNumberData,
    },
    mode: "onChange",
  });
  const { isError, isLoading, postData } = usePostApi();
  // console.log("iserror", isError);

  const PhoneVerifyCheckSubmit = async (verifyData) => {
    try {
      verifyData.phone = inputPhoneNumberData;
      let res = await postData(
        process.env.REACT_APP_API_VERIFY_VRCODE,
        verifyData,
        "",
        false
      );
      // console.log("res", res);
      if (res?.error) {
        return;
      }
      setPhoneNumberAuthentication(true);
    } catch (e) {
      console.log(e);
    }
  };

  // console.log("엥", timerValue);

  // console.log(errors?.verify?.message);

  const hello = errors?.verify?.message;
  return (
    <AuthForm onSubmit={handleSubmit(PhoneVerifyCheckSubmit)}>
      <AuthInput
        label="휴대폰번호"
        type="number"
        id="phone"
        value={inputPhoneNumberData}
        register={register("phone", {
          required: true,
          disabled: true,
        })}
      />
      <AuthInput
        label="인증번호"
        type="number"
        id="verify"
        register={register("verify", {
          required: "인증번호를 입력해주세요.",
          maxLength: {
            value: 6,
            message: "인증번호는 6글자 입니다.",
          },
          minLength: {
            value: 6,
            message: "인증번호는 6글자 입니다.",
          },
        })}
      />
      {/* {errors && errors?.verify && (
        <ErrorText errorText={errors?.verify?.message} />
      )} */}
      {/* 
      {typeof timerValue === "string" ? (
        <h1
          style={{
            width: "100%",
            height: "30px",
            color: "tomato",
            fontSize: "15px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "5px",
          }}
        >
          {timerValue}
        </h1>
      ) : (
        <h1
          style={{
            width: "100%",
            height: "30px",
            color: "tomato",
            fontSize: "15px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginBottom: "5px",
          }}
        >
          {String(Math.floor(timerValue / 60)).padStart(2, "0")}:
          {String(timerValue % 60).padStart(2, "0")}
        </h1>
      )} */}
      {errors && errors?.verify && <ErrorMsg text={errors?.verify?.message} />}
      {!errors.verify && isError && <ErrorMsg text={isError} />}
      {isLoading ? (
        <Loading />
      ) : (
        <AuthButton
          type="submit"
          title="인증번호 확인"
          isActiveBtn={isValid && typeof timerValue !== "string"}
        />
      )}
    </AuthForm>
  );
};

export default CertificationCheck;
