import { useState } from "react";
// 커넥터 충전 가능여부 체크 api
const useConnectorCheck = () => {
  // 충전 가능 여부 state
  const [isUnchargeable, setIsUnchargeable] = useState(false);
  // 에러 메세지 state
  const [isErrorMsg, setIsErrorMsg] = useState(null);
  // api 에러 메세지 state
  // const [isApiErrorMsg, setIsApiErrorMsg] = useState(null);

  const fetchData = async (center, connector) => {
    try {
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_QR_CHECK, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          center: center,
          connector: connector,
        }),
      });
      const responseData = await response.json();
      // console.log("responsedata", responseData);

      if (response.status === 200) {
        setIsUnchargeable(true);
        return responseData;
      }
      if (response.status === 400) {
        // setIsUnchargeable(false);
        return setIsErrorMsg(responseData.error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return { isUnchargeable, fetchData, isErrorMsg };
};
export default useConnectorCheck;
