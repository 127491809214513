import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router";

const usePatchApi = () => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const patchData = async (apiUrl, data, navigator) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      // apiUrl api 주소가 들어감
      console.log("요청");
      // const response = await fetch(apiUrl, {
      //   method: "PATCH",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `token ${token}`,
      //   },
      //   credentials: "include",
      //   body: JSON.stringify(data),
      // });
      const response = await axios.patch(apiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        credentials: "include",
      });
      // console.log("받는 데이터", response.data);
      if (response.status === 200 || response.status === 204) {
        // 200은 정상적으로 처리가 될 경우
        navigate(navigator);
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 400) {
        // 빈공간일 경우 400 error
        // 400은 빈공란으로 보낼 경우
        setIsError(error.response.data.error);
      } else if (error.response.status === 409) {
        //409는 중복 된 값을 받을 경우
        setIsError(error.response.data.error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return { isError, isLoading, patchData };
};

export default usePatchApi;
