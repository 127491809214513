import styled from "styled-components";
import { theme } from "../../../components/theme/theme";

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
 
 `
export const CardBoxContaier = styled.div`
    display: flex;
    height:213px;
    flex-wrap:nowrap;
    position:relative;
`
export const CardBox = styled.div`
    width:322px;
    height:184px;
    margin-right:33px;
    border-radius:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(theme.colors.subColor)};
`
export const CardinforText = styled.div`
    font-weight: bold;
    font-size:19px;
    span{
        display: block;
        color: rgba(0,0,0,0.5);
    }
    span:nth-child(2){
        text-align: center;
        margin-top:9px;
        font-size:25px;
    }
`
export const CardInfoInputBtn = styled.button`
    width:259px;
    height:61px;
    border-radius: 20px;
    color:white;
    font-size:17px;
    border:none;
    font-weight: 500;
    background-color: ${(theme.colors.pointColor)};
    margin-top: 98px;
`   
export const CheckBoxContainer = styled.div`
    display: flex;
    margin-top:22px;
`
export const CardCheckBox = styled.div`
    width:14px;
    height:14px;
    margin-right:11px;
    border-radius: 50%;
    background-color: gray;
`