import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AuthInfo from "../../components/Auth/Account/AuthInfo";
import CertificationCheck from "../../components/Auth/Account/CertificationCheck";
import PhoneNumberCertification from "../../components/Auth/Account/PhoneNumberCertification";
import Header from "../../components/Layout/Header/Header";
// 회원가입 전체 관리
// 휴대폰인증, 회원정보 입력 , 이용약관 동의 등등
const UserAccount = () => {
  // 휴대폰 번호 인증 여부
  const [phoneNumberAuthentication, setPhoneNumberAuthentication] =
    useState(false);
  // 입력한 휴대폰 번호 데이터
  const [inputPhoneNumberData, setInputPhoneNumberData] = useState("");

  const navigate = useNavigate();

  const [timerValue, setTimerValue] = useState(60);
  const [time, setTimer] = useState("");

  const timerLetsGitit = () => {
    let timer = setInterval(() => {
      // console.log(timerValue);
      setTimerValue((prev) => (prev -= 1));
    }, 1000);
    setTimer(timer);
  };

  if (timerValue === 0) {
    clearInterval(time);
    setTimerValue("인증번호 시간 만료");
  }
  return (
    <Fragment>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="회원가입"
        marginBottom="false"
      />
      {/* // 회원정보 입력 */}
      {phoneNumberAuthentication ? (
        <AuthInfo inputPhoneNumberData={inputPhoneNumberData} />
      ) : (
        <>
          {inputPhoneNumberData ? (
            // 인증번호 입력
            <CertificationCheck
              inputPhoneNumberData={inputPhoneNumberData}
              setPhoneNumberAuthentication={setPhoneNumberAuthentication}
              timerValue={timerValue}
            />
          ) : (
            // 인증번호 받을 휴대폰번호
            <PhoneNumberCertification
              setInputPhoneNumberData={setInputPhoneNumberData}
              timerLetsGitit={timerLetsGitit}
              setTimerValue={setTimerValue}
            />
          )}
        </>
      )}
    </Fragment>
  );
};

export default UserAccount;
