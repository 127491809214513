import React from "react";
import AuthForm from "../../../components/Auth/Common/AuthForm/AuthForm";
import AuthInput from "../../../components/Auth/Common/AuthInput/AuthInput";
import Button from "../../../components/Common/Button/Button";
import { Box, ApplicationContainer, Footer, Text } from "./style";
import Container from "../../../components/Common/Wrapper/Container";
import Header from "../../../components/Layout/Header/Header";

const Application = () => {
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전시설 설치 신청"
      />
      <ApplicationContainer>
        <Box>
          <Text>아래 전화로 먼저 상의해주세요.</Text>
          <Box marginTop="8px">
            <Text color="#45454F">
              담당자: 이윤원 대표, 송덕원 연구소장, 이상수 선임연구원
            </Text>
          </Box>
        </Box>
        <Box marginTop="30px">
          <Text fontWeight="600" fontSize="20px">
            충전기 설치 신청서
          </Text>
        </Box>
        <AuthForm>
          <div className="label">
            <AuthInput id="openform" type="text" />
            <AuthInput id="installcharge" type="text" />
            <AuthInput id="name" type="text" />
            <AuthInput id="phone" type="text" />
            <AuthInput id="number" type="number" />
            <AuthInput id="email" type="email" />
            <AuthInput id="installhope" type="text" />
            <AuthInput id="address" type="text" />
            <AuthInput
              id="quiry"
              type="text"
              placeholder="1000자내로 내용작성 바랍니다."
            />
          </div>
          <Footer>
            <Button
              type="submit"
              color=""
              text="충전시설 설치신청"
              fontSize="18px"
            />
          </Footer>
        </AuthForm>
      </ApplicationContainer>
    </Container>
  );
};

export default Application;
