import * as S from "./HomeStyle";
import { content } from "../../components/Home/Content/content";
import Container from "../../components/Common/Wrapper/Container";
import Footer from "../../components/Layout/Footer/Footer";
import ContentItem from "../../components/Home/Content/ContentItem";
import HomeHeader from "../../components/Layout/Header/Home/HomeHeader";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useNoticeRequest from "../../hooks/api/useNoticeRequest";
import { theme } from "../../components/theme/theme";

const Home = () => {
  const { currentNotice, notice } = useNoticeRequest();
  const [scrollPosition, setScrollPosition] = useState(0); //scroll 기본값 0으로 설정
  const selector = useSelector((state) => state.prevUrl);
  console.log(selector);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(selector.url);
  console.log(location.pathname);
  // console.log("current", currentNotice);
  // console.log("notice", notice);
  const noticeArr = Object.values(notice).reverse();
  // console.log(noticeArr);

  const [contentHeight, setContentHeight] = useState(window.innerHeight - 55);
  useEffect(() => {
    // console.log(selector.url);
    if (!selector.url) {
      return;
    }
    const handle = (e) => {
      window.history.forward();
      e.preventDefault();
    };

    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handle);

    return () => {
      window.removeEventListener("popstate", handle);
    };
  }, []);
  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight - 55);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight - 55)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);

  // useEffect(() => {
  //   console.log("실행");
  //   const refElement = ref.current;
  //   refElement.addEventListener(
  //     "scroll",
  //     () => {
  //       setScrollPosition(ref.current.scrollTop);
  //     },
  //     { passive: true }
  //   );
  //   return () => {
  //     refElement.removeEventListener("scroll", () => {
  //       setScrollPosition(ref.current.scrollTop);
  //     });
  //   };
  // }, []);

  const ref = useRef();

  // console.log("window.pageyoffset", window.scrollY);
  // const handleScroll = () => {
  //   console.log("hello");
  //   setScrollPosition(window.pageYOffset);
  // };
  // const backgroundColor =
  //   scrollPosition > 50 ? "rgba(91, 90, 199, 0.9)" : "rgb(91, 90, 199)";

  return (
    <>
      <Container bgColor="#f5f5f5">
        {/* 홈 헤더 */}
        <HomeHeader scrollPosition={scrollPosition} />

        <S.ContentContainer contentHeight={contentHeight} ref={ref}>
          <div
            style={{
              width: "100%",
              // maxWidth: "600px",
              height: "270px",
              background: `${theme.colors.mainColor}`,
              borderRadius: "0 0 13px 13px",
            }}
          ></div>
          <S.HomeInnerBox>
            <S.ContentTitle>(주)FEC의 충전시스템</S.ContentTitle>
            {/* <HomeNotice>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <HomeNoticeTitle>공지</HomeNoticeTitle>
                <Link to="/community/notice">
                  <img src="/img/more.svg" />
                  {/* 더 보기 */}
            {/* </Link>
              </div>

              <HomeNoticeList>
                {noticeArr.slice(0, 3).map((item) => (
                  <HomeNoticeListItem>
                    <Link to={`/community/${item.id}`}>
                      {(Date.now() - Date.parse(item.posttime)) /
                        1000 /
                        60 /
                        60 /
                        24 <
                        14 && <span className="new">new</span>}
                      <span className="notice-type">[{item.noticetype}]</span>
                      <span className="notice-title">{item.title}</span>
                    </Link>
                  </HomeNoticeListItem>
                ))}
                ...
              </HomeNoticeList>
            </HomeNotice> */}
            {content.map((item, idx) => {
              return <ContentItem key={idx} data={item} />;
            })}
          </S.HomeInnerBox>

          {/* 이용안내 3가지  */}
        </S.ContentContainer>

        {/* 홈 화면 푸터, 푸터 안에는 충전소 위치, 충전 요금 버튼 그리고 가장 아래에 공지 박스*/}
        <Footer />
      </Container>
    </>
  );
};

export default Home;
