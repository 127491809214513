import styled from "styled-components";
import { theme } from "../../../components/theme/theme";

// 고객센터 전화, 이메일 컴포넌트 기본 스타일
export const CustomerService = styled.li`
  width: 100%;
  margin-bottom: 10px;
  & > a {
    font-size: 16px;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    & > span {
      margin-right: 8px;
      font-size: 16px;
    }
  }
`;
