import useKaKaMapRequest from "../../hooks/api/useKaKaoMapRequest";
import useChargeLocationRequest from "../../hooks/api/useChargeLocationRequest";
// import {} from "../Home/style";
import {
  KaKaoMap,
  AddressContainer,
  AddressItem,
  AddressItemTitle,
  AddressItemTitleBox,
  InfoBox,
  InfoBoxItem,
  KaKaoMapContainer,
  KaKaoMapCloseBtn,
} from "./AddressStyle";
import Situation from "../../components/ChargeAddress/Components/Situation/Situation";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import { useEffect, useState } from "react";
const Address = () => {
  // 카카오 map api 를 호출하는 커스텀 훅
  const { isShowMap, setIsShowMap, setMapData } = useKaKaMapRequest();
  const [clickCenterInfo, setClickCenterInfo] = useState({
    centerName: "",
    centerAddress: "",
  });

  // 화면에 맵을 보여주는 여부를 제어하는 함수
  const onMapClickHandler = (mapX, mapY, centerName, address) => {
    setClickCenterInfo({ centerName: centerName, centerAddress: address });
    setMapData([mapX, mapY]);
    setIsShowMap((prev) => !prev);
  };

  // 충전소 위치 정보를 요청하고 받아오는 커스텀 훅
  const { availableChargersData } = useChargeLocationRequest();

  // console.log("avali data", availableChargersData);

  // console.log("avali data", availableChargersData);s
  // console.log("clickcentername", clickCenterInfo.centerName);

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);

  return (
    <Container bgColor="#f5f5f5">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전소 위치"
      />
      <AddressContainer contentHeight={contentHeight}>
        {availableChargersData.map((item) => (
          <AddressItem>
            <AddressItemTitleBox>
              <AddressItemTitle>{item?.centerName}</AddressItemTitle>
              <div
                onClick={() =>
                  onMapClickHandler(
                    item?.mapX,
                    item?.mapY,
                    item?.centerName,
                    item?.address
                  )
                }
              >
                <img src="/img/Address/addressIcon.svg" alt="company address" />
                <span>지도</span>
              </div>
            </AddressItemTitleBox>
            <InfoBox>
              <InfoBoxItem>
                <span>{item.performance.slice(4, 8)}</span>
              </InfoBoxItem>
              <InfoBoxItem>충전기 {item.chargerTotal}기</InfoBoxItem>
              <InfoBoxItem>주차면 {item.parkingTotal}기</InfoBoxItem>
            </InfoBox>
            <address>{item.address}</address>
            {/* 충전정보 */}
            <Situation availableChargersData={item} />
          </AddressItem>
        ))}
      </AddressContainer>
      {isShowMap && (
        <KaKaoMapContainer>
          <h1>{clickCenterInfo.centerName}</h1>
          <h3>{clickCenterInfo.centerAddress}</h3>
          <KaKaoMapCloseBtn onClick={() => setIsShowMap((prev) => !prev)}>
            <span className="material-symbols-outlined">close</span>
          </KaKaoMapCloseBtn>

          <KaKaoMap id="map" />
        </KaKaoMapContainer>
      )}
    </Container>
  );
};

export default Address;
