import styled from "styled-components";

export const InfoContainer = styled.div`
  margin-top: ${(props) => props.marginTop};
  .description {
    p {
      font-weight: bold;
      line-height: 23px;
    }
  }
`;
export const CompanyVideo = styled.video`
  object-fit: fill;
  width: 100%;
  height: 181.6px;
`;
export const InfoTitle = styled.h1`
  margin-bottom: 13px;
  font-size: 20px;
  font-weight: 600;
  color: #412bd5;
`;
