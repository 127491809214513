import React, { useEffect, useState } from "react";
import AuthForm from "../../../Auth/Common/AuthForm/AuthForm";
import AuthInput from "../../../Auth/Common/AuthInput/AuthInput";
import AuthButton from "../../../Auth/Common/AuthButton";
import { useParams, useNavigate } from "react-router";
import {
  ChargeOptionBox,
  ChargeOptionContainer,
  ChargeOptionInput,
  ChargeOptionItem,
  ChargeOptionText,
} from "./ChargeOption.style";
import ErrorMsg from "../../../Common/Errors/ErrorMsg";

const chargeOptionItems = [500, 1000, 5000, 10000];

const ChargeOption = ({ token, batteryAmount }) => {
  const { charge, id } = useParams();
  const navigate = useNavigate();
  const [price, setPrice] = useState(0);
  const [showPrice, setShowPrice] = useState("");
  const [error, setError] = useState("");
  // 충전 금액 입력 후 submit

  const onClickChargePriceSubmit = async (price) => {
    navigate(`/qrbilling/${charge}/${id}/${price}/${token}/${batteryAmount}`);
  };

  // 각각의 충전 옵션을 클릭하면 호출되는 콜백함수
  const onclickChargeOptionItem = (clickValue) => {
    if (clickValue === "초기화") {
      setError("");
      setPrice(0);
    } else if (clickValue === "완충(83%)") {
      setPrice(50000);
      setShowPrice("완충(83%) 25000");
    } else {
      if (price + clickValue > 25000) {
        setError("충전 요금은 최대 25,000원 입니다.");
      } else {
        setPrice(price + clickValue);
        setShowPrice(price + clickValue);
        setError("");
      }
    }
  };

  return (
    <ChargeOptionContainer
      style={{ width: "100%", padding: "0 20px", paddingTop: "80px" }}
    >
      {/* <AuthInput
        style={{ display: "none" }}
        label="충전 요금: 300원/kWh, 무료 서비스: 완충(80%)만 가능"
        type="text"
        id="price"
        register={verifyRgs("price", {
          required: true,
          validate: {
            check: (item) => {
              return item > 50000 && "50000원 넘음";
            },
          },
        })}
        // value={`${watch("price")} 원`}
      /> */}
      <ChargeOptionText>충전 요금: 290원/kW</ChargeOptionText>
      <ChargeOptionInput>
        {price === 0 ? "충전 금액을 클릭하여 입력해주세요." : `${showPrice}원`}
      </ChargeOptionInput>

      {error.length > 0 && <ErrorMsg text={error} />}
      <ChargeOptionBox>
        <ChargeOptionItem
          onClick={() => {
            onclickChargeOptionItem("완충(83%)");
          }}
        >
          완충(83%)
        </ChargeOptionItem>
        {chargeOptionItems.map((item) => {
          return (
            <ChargeOptionItem
              onClick={() => {
                onclickChargeOptionItem(item);
              }}
            >
              {item}원
            </ChargeOptionItem>
          );
        })}
        <ChargeOptionItem
          onClick={() => {
            onclickChargeOptionItem("초기화");
          }}
        >
          초기화
        </ChargeOptionItem>
      </ChargeOptionBox>
      <AuthButton
        type="button"
        title="신청하기 "
        isActiveBtn={price > 0}
        onClick={() => onClickChargePriceSubmit(price)}
      />
    </ChargeOptionContainer>
  );
};

export default ChargeOption;
