import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { carInfoData } from "../../../store/CarInfo/carInfo-api";
import Fixed from "../../../components/Common/Fixed/Fixed";
import Button from "../../../components/Common/Button/Button";
import CarList from "../../../components/Cars/Common/CarList";
import Container from "../../../components/Common/Wrapper/Container";
import Header from "../../../components/Layout/Header/Header";
import { CarContainer, NoCarList } from "../../../components/Cars/style/style";

const CarManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carListData = Object.values(
    useSelector((state) => state.carInfo.carInfor) || {}
  );
  // const carInfor = useSelector((state) => state.carInfo);
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");

    const confirmed = window.confirm("서비스 준비 중 입니다.");
    if (confirmed) {
      navigate("/usermenu");
    }
    if (!confirmed) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(carInfoData());
  }, []);

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <Container paddingBottom="100px">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="내 차량관리"
      />
      <CarContainer contentHeight={contentHeight}>
        {carListData.length === 0 ? (
          <NoCarList>등록된 차량이 없습니다.</NoCarList>
        ) : (
          <CarList carListData={carListData} />
        )}
      </CarContainer>
      <Fixed>
        <Link to={"/cars/register"}>
          <Button text="차량 등록하러 가기" bgColor="#412BD5" fontSize="16px" />
        </Link>
      </Fixed>
    </Container>
  );
};
export default CarManagement;
