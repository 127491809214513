import { Link } from "react-router-dom";
import PhoneNumber from "./components/PhoneNumber/PhoneNumber";

// 네비게이션 요소 배열
export const NavArr = [
  {
    title: "충전하기",
    content: [
      {
        name: "충전 방법",
        url: `/detail/register/description`,
      },
      // {
      //   name: (
      //     <>
      //       <div className="qr">
      //         <div className="qr-icon-img">
      //           <img
      //             src={`${process.env.PUBLIC_URL}/img/QR.svg`}
      //             alt="qrIcon"
      //           />
      //         </div>

      //         <div className="qr-text">
      //           <span>충전주차면의QR찍기</span>
      //           <span>QR: 커넥터박스 전면에 위치</span>
      //         </div>
      //       </div>
      //     </>
      //   ),
      //   url: "",
      // },

      // {
      //   name: "비회원/신용카드 미등록 회원",
      //   url: "/detail/unregister/description",
      // },
      {
        name: "충전 요금",
        url: "/price",
      },
      // {
      //   name: "내 충전 실황",
      //   url: "/charge/status",
      // },
    ],
  },
  {
    title: "충전소 찾기",
    content: [
      {
        name: "FEC 충전소 위치",
        url: "/address",
      },
    ],
  },
  // {
  //   title: "FEC 소개 및 충전시설 신청",
  //   content: [
  //     {
  //       name: "FEC 소개",
  //       url: "/fec/information",
  //     },
  //     // {
  //     //   name: (
  //     //     <>
  //     //       <br />
  //     //     </>
  //     //   ),
  //     //   url: "",
  //     // },
  //     {
  //       name: "충전 시설 설치 신청",
  //       url: "/fec/application/description",
  //     },
  //   ],
  // },
  {
    title: "공지사항",
    content: [
      {
        name: "공지사항/이벤트",
        url: "/community/notice",
      },
    ],
  },
  {
    title: "고객센터",
    content: [
      {
        name: "이용약관",
        url: "/service",
      },
      // {
      //   name: "오픈소스",
      //   url: "/opensourcelicense",
      // },
    ],
  },
];
