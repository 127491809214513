import { Link } from "react-router-dom";
import { LoginOtherOptionBox } from "../style/styled";
const optionData = [
  {
    name: "아이디 찾기",
    url: "/users/find/id",
  },
  {
    name: "비밀번호 찾기",
    url: "/users/find/password",
  },
  {
    name: "회원가입",
    url: "/users/account",
  },
];

// 로그인 페이지 다른 옵션들
const LoginOtherOption = () => {
  return (
    <LoginOtherOptionBox>
      {optionData.map((item) => {
        return <Link to={item.url}>{item.name}</Link>;
      })}
    </LoginOtherOptionBox>
  );
};

export default LoginOtherOption;
