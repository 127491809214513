import styled from "styled-components";

// NoticeItem 컴포넌트의 스타일 컴포넌트
export const NoticeItemBox = styled.article`
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px 0 10px 7px;
  .notices {
    margin-bottom: 2px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .new-text {
      margin-right: 5px;
      color: tomato;
      font-weight: 700;
    }
    .notice {
      color: #412bd5;
      font-weight: 600;
      margin-right: 5px;
    }
  }
  .date {
    color: #a9a9b1;
  }
`;
