import { useState } from "react";
import {
  ItemDot,
  ReasonBox,
  ReasonItem,
  WithdrawReasonBox,
  WithdrawReasonTitle,
} from "./WithdrawReason.style";

const reasonList = [
  "서비스를 사용하지 않아요",
  "서비스를 이용하는데 불편해요",
  "비회원으로 사용하고 싶어요",
  "이용금액이 비싸요",
  "기타",
];
const WithdrawReason = ({
  setIsWithdrawReason,
  setSelectWithdrawReason,
  contentHeight,
}) => {
  const [clickReason, setClickReason] = useState("");
  return (
    <WithdrawReasonBox>
      <WithdrawReasonTitle>탈퇴 이유가 어떻게 되시나요?</WithdrawReasonTitle>
      <ReasonBox>
        {reasonList.map((item) => {
          return (
            <ReasonItem
              onClick={() => setClickReason(item)}
              clickItem={clickReason === item}
            >
              <ItemDot clickItem={clickReason === item}>
                <div className="realdot"></div>
              </ItemDot>
              {item}
            </ReasonItem>
          );
        })}
      </ReasonBox>
      <div
        style={{
          position: "absolute",
          width: "100%",
          padding: "0 18px",
          bottom: "20px",
          left: "0",
        }}
      >
        <div
          onClick={() => {
            setIsWithdrawReason(true);
            setSelectWithdrawReason(clickReason);
            // console.log("hello");
          }}
          style={{
            width: "100%",
            height: "40px",
            background: `${clickReason ? "#412BD5" : "	#a0a0a0"}`,
            color: "white",
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            marginTop: "10px",
            pointerEvents: `${clickReason ? "auto" : "none"}`,
          }}
        >
          다음
        </div>
      </div>
    </WithdrawReasonBox>
  );
};

export default WithdrawReason;
