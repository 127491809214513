import { ThemeProvider } from "styled-components";
import { useMediaQuery } from "react-responsive";
// import { Router, RouterProvider, useLocation } from "react-router-dom";
import GlobalStyles from "./GlobalStyle";
import { Fragment, useEffect } from "react";
import { theme } from "./components/theme/theme";
import { Rou } from "./router/router";

function App() {
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  return (
    <Fragment>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        {/* <RouterProvider router={router} /> */}
        <Rou />
      </ThemeProvider>
    </Fragment>
  );
}
// /qrrequest(이건 바꿔도 됨)/KS01/1
export default App;
