import React from "react";
import { Button } from "../style/styled";

const AuthButton = ({ title, onClick, isActiveBtn }) => {
  return (
    <Button
      type={title || "button"}
      onClick={onClick}
      isActiveBtn={isActiveBtn}
    >
      {title}
    </Button>
  );
};

export default AuthButton;
