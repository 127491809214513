import styled from "styled-components";

export const AuthNumberValidTimeText = styled.h1`
  /* width: 100%; */
  /* height: 30px; */
  position: absolute;
  top: 37px;
  right: 14px;
  color: tomato;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 5px; */
`;
