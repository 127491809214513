import styled from "styled-components";

export const ChargeOptionContainer = styled.main`
  width: 100%;
  padding: 0 20px;
  padding-top: 80px;
`;

export const ChargeOptionText = styled.h3`
  color: #828282;
`;

export const ChargeOptionInput = styled.div`
  width: 100%;
  color: #969696;
  height: 40px;
  border: 1px solid #828282;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
`;

export const ChargeOptionBox = styled.article`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;

export const ChargeOptionItem = styled.div`
  width: 100%;
  height: 80px;
  background-color: #ffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    background-color: #d2d2ff;
  }
`;
