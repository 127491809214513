import styled from "styled-components";

export const SituationContainer = styled.div`
  margin-top: 18px;
  div {
    line-height: 20px;
  }
  p {
    font-weight: 800;
    margin-bottom: 10px;
  }
  span {
    color: #412bd5;
  }
`;
