import React from "react";
import styled from "styled-components";
import Container from "../Common/Wrapper/Container";
import { theme } from "../theme/theme";
const PaymentDesListBox = styled.div`
  width: 100%;
  font-weight: 500;
  margin-bottom: 36px;
  font-size: 18px;
  span {
    color: ${theme.colors.pointColor};
  }
  .content-container {
    display: flex;
    .title {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
  .text {
    line-height: 26px;
  }
  .subtitle {
    margin-left: 31px;
    line-height: 22px;
    color: ${theme.colors.SubFontColor};
    font-size: 15px;
  }
`;
const PaymentDesList = ({ paymentDesArr }) => {
  return (
    <PaymentDesListBox>
      {paymentDesArr.map((item, idx) => {
        return (
          <Container key={idx}>
            <div className="content-container">{item.content}</div>
          </Container>
        );
      })}
    </PaymentDesListBox>
  );
};

export default PaymentDesList;
