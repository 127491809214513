import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authInfoData } from "../../../../store/Auth/auth-api";
import HomeNav from "./content/HomeNav";
import { HomeHeaderContainer } from "./HomeHeaderStyle";

// 홈 헤더
const HomeHeader = ({ scrollPosition }) => {
  // userdata 불러옴
  const userInfoData = useSelector((state) => state.authInfo.userInfo);
  // console.log("hi", userInfoData);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, []);

  const backgroundColor =
    scrollPosition > 50 ? "rgba(91, 90, 199, 0.9)" : "rgb(91, 90, 199)";

  return (
    <HomeHeaderContainer>
      <HomeNav userInfoData={userInfoData} bgColor={backgroundColor} />
      {/* <div
        style={{
          width: "100%",
          height: "100px",
          background: "aqua",
          opacity: "0.1",
        }}
      ></div> */}
    </HomeHeaderContainer>
  );
};

export default HomeHeader;
