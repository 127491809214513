import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ViewContainer = styled.div`
  padding: 80px 18px 18px 18px;
`;
export const NoticeTitle = styled.div`
  .new-text {
    font-size: 16px;
    margin-right: 5px;
    color: tomato;
    font-weight: 700;
  }
  .notice {
    font-size: 16px;
    color: #412bd5;
    font-weight: 600;
    margin-right: 5px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 18px;
  }
  .date {
    margin-top: 2px;
    span {
      color: ${theme.colors.FontGrayColor};
    }
  }
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 10px;
`;

export const NoticeText = styled.div`
  padding-top: 20px;
  line-height: 22px;
`;
