import { ShadowBox, Text } from "./style/style";

export const applicationArr = [
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application01.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          <span>01</span> 신청/문의 접수
        </Text>
        <div>
          <p className="sub-title">담당자: 이상수 선임연구원</p>
          <p className="sub-title">전화번호: 053-719-4966~7</p>
        </div>
      </>
    ),
  },
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application02.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          <span>02</span> 담당자 상담
        </Text>
        <div>
          <p className="sub-title">
            담당자가 배정되어 유선 상담이 진행 됩니다.
          </p>
        </div>
      </>
    ),
  },
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application03.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          <span>03</span> 서류 작성
        </Text>
        <div>
          <p className="sub-title">
            충전기 설치 신청에 필요한 서류를 작성합니다.
          </p>
        </div>
      </>
    ),
  },
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application04.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          {" "}
          <span>04</span> 현장 실사
        </Text>
        <div>
          <p className="sub-title">현장방문하여 설치가능 여부를 확인합니다.</p>
        </div>
      </>
    ),
  },
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application05.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          <span>05</span>설치 시작
        </Text>
        <div>
          <p className="sub-title">전기공사 및 설치를 진행합니다.</p>
        </div>
      </>
    ),
  },
  {
    img: (
      <ShadowBox>
        <img src="/img/Application/Application06.jpg" />
      </ShadowBox>
    ),
    number: (
      <>
        <Text>
          <span>06</span> 설치 완료
        </Text>
        <div>
          {" "}
          <p className="sub-title">이제 매월 꾸준한 수익 창출이 실현됩니다.</p>
        </div>
      </>
    ),
  },
];
