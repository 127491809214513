import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import SelectDate from "./components/SelectDate";
import HistoryModal from "./components/HistoryModal";
import Header from "../Layout/Header/Header";
import axios from "axios";

const Container = styled.div`
  height: ${(props) => `${props.height}px`};
  width: 100%;
  padding: 65px 18px 0 18px;
`;
const CardContainer = styled.div`
  width: 100%;
  position: relative;
  .card {
    font-weight: bold;
  }
  .card-numer {
  }
  .react-datepicker__input-container {
    display: flex;
  }
  .react-datepicker__input-container input {
    border: none;
    background-color: none;
    font-size: 16px;
  }
`;
const Table = styled.div`
  margin-top: 16px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #f5f5f5;
  div {
    min-width: 70px;
    display: flex;
    justify-content: center;
  }
  .title {
    font-weight: 800;
  }
`;

const HistoryDataBox = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
// 충전소 이용내역 컴포넌트
const History = () => {
  const [record, setRecord] = useState({});
  // console.log("record", record);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 3))
  );
  const [endDate, setEndDate] = useState(new Date());

  // yy-mm-dd 형식으로 변경해주는 함수
  const fulldateFormmat = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1 해주고 두 자릿수로 포맷팅
    const day = currentDate.getDate().toString().padStart(2, "0"); // 두 자릿수로 포맷팅
    return `${year}-${month}-${day}`;
  };

  const shortDateFormat = (currentTime) => {
    const currentDate = new Date(currentTime);
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const addComma = (price) => {
    let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnString;
  };

  const getChargeHistoryData = async (startDate, endDate) => {
    try {
      console.log(startDate, endDate);
      console.log("요청");
      const response = await axios.post(
        process.env.REACT_APP_API_USER_RECORD,
        {
          startDate: fulldateFormmat(startDate),
          endDate: fulldateFormmat(endDate),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      if (response.status === 200 || response.status === 204) {
        setRecord(response.data);
        return response.data;
      } else if (response.status === 403) {
        const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
        if (confirmed) {
          navigate("/users/login");
        }
        if (!confirmed) {
          navigate("/");
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    console.log("팩트 실행");
    if (startDate && endDate) {
      getChargeHistoryData(startDate, endDate);
    }
  }, [startDate, endDate]);

  const [contentHeight, setContentHeight] = useState(window.innerHeight - 55);
  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight - 55);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight - 55)
      );
  }, []);
  const recordValue = Object.values(record).sort(
    (a, b) => new Date(b.startTime) - new Date(a.startTime)
  );
  console.log(recordValue);

  const [modalContent, setModalContent] = useState(null);

  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전소 이용내역 확인"
      />
      <Container height={contentHeight}>
        <CardContainer>
          <div style={{ display: "flex" }}>
            <SelectDate
              endDate={endDate}
              setEndDate={setEndDate}
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </div>
        </CardContainer>
        <Table>
          <div className="title">일시</div>
          <div className="title">충전소</div>
          <div className="title">충전요금</div>
        </Table>
        <HistoryDataBox>
          {" "}
          {recordValue.map((item) => {
            return (
              <Table
                onClick={() => {
                  setModalContent(item);
                }}
              >
                <div>{shortDateFormat(item.startTime)}</div>
                <div>{item.centerName}</div>
                <div style={{ fontWeight: 600 }}>
                  {addComma(item.chargeFee)} 원
                </div>
              </Table>
            );
          })}
        </HistoryDataBox>
      </Container>
      {modalContent && (
        <HistoryModal
          modalContent={modalContent}
          onClose={() => setModalContent(null)}
        />
      )}
    </>
  );
};

export default History;
