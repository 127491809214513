import axios from "axios";
import { useEffect, useState } from "react";

export const useNoticeResponse = () => {
  const [isData, setIsData] = useState({});
  const [isError, setIsError] = useState(null);
  useEffect(() => {
    console.log("실행");
    const fetchData = async () => {
      try {
        console.log("요청");
        const response = await axios.get(process.env.REACT_APP_API_NOTICE, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("response", response);
        if (response.status === 200 || response.status === 204) {
          return setIsData(response.data);
        }
      } catch (error) {
        if (error.response.status === 400) {
          return setIsError(error.response.data.error);
        }
      }
    };
    console.log("실행");
    fetchData();
  }, []);
  return { isData, isError };
};
