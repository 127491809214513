import { createSlice } from "@reduxjs/toolkit";

const prevUrlSlice = createSlice({
  name: "prevUrl",
  initialState: {
    url: "",
  },
  reducers: {
    update: (state, action) => {
      return {
        url: action.payload,
      };
    },
  },
});
export const { update } = prevUrlSlice.actions;

export default prevUrlSlice.reducer;
