import { useEffect, useState } from "react";
import { AuthNumberValidTimeText } from "./AuthNumberValidTime.style";

const AuthNumberValidTime = ({ setTimerKey, setIsTimer }) => {
  const [timerValue, setTimerValue] = useState(180);
  //   const timerLetsGitit = () => {
  //     let timer = setInterval(() => {
  //       console.log(timerValue);
  //       console.log("hello!");
  //       setTimerValue((prev) => prev - 1);
  //     }, 1000);
  //     setTimer(timer);
  //   };

  useEffect(() => {
    console.log("실행");
    const timer = setInterval(() => {
      // console.log("hello!");
      console.log("실행");
      setTimerValue((prev) => prev - 1);
    }, 1000);
    setTimerKey(timer);
    if (timerValue === 0) {
      clearInterval(timer);
      setIsTimer(false);
    }

    return () => clearInterval(timer);
  }, [timerValue]);
  // if (timerValue === 0) {
  //   clearInterval(timerKey);
  //   setTimerValue("인증번호를 다시 받아주세요. 재발급");
  // }
  return (
    <AuthNumberValidTimeText>
      {String(Math.floor(timerValue / 60)).padStart(2, "0")}:
      {String(timerValue % 60).padStart(2, "0")}
    </AuthNumberValidTimeText>
  );
};

export default AuthNumberValidTime;
