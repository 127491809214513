import React, { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { NoticeText, NoticeTitle, ViewContainer } from "./style";
import { useNoticeResponse } from "../../../api/useNoticeResponse";
import Container from "../../Common/Wrapper/Container";
import Header from "../../Layout/Header/Header";

const NoticeView = () => {
  const { id } = useParams();
  const { isData, isError } = useNoticeResponse();

  // console.log(isData);
  const time =
    (Date.now() - Date.parse(isData[id]?.posttime)) / 1000 / 60 / 60 / 24;
  const noticeArr = Object.values(isData);
  const noticeTitleRef = useRef(null);
  const date = new Date(isData[id]?.posttime);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  // 수정하는 코드
  // let y = noticeArr[noticeArr.length - 1].posttime.slice(0, 4);
  // let m = noticeArr[noticeArr.length - 1].posttime.slice(5, 7);
  // let d = noticeArr[noticeArr.length - 1].posttime.slice(8, 10);
  // console.log("날짜", noticeArr[noticeArr.length - 1].posttime);
  // console.log("날짜입니다.", y, m, d);

  useEffect(() => {
    console.log("팩트 실행");
    if (noticeTitleRef.current) {
      noticeTitleRef.current.innerHTML = noticeArr
        .map((item) => item.text)
        .join(" ");
    }
  }, [noticeArr]);

  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="공지사항/ 이벤트"
        marginBottom="false"
      />
      <ViewContainer>
        <NoticeTitle>
          <div>
            {time < 14 && <span className="new-text">new</span>}
            <span className="notice">[{isData[id]?.noticetype}]</span>
            <span className="title">{isData[id]?.title}</span>
            <span> </span>
          </div>
          <div className="date">
            <span>
              {year}.{month}.{day}
            </span>
          </div>
        </NoticeTitle>
        <NoticeText id="notice-title">
          {isData &&
            isData[id] &&
            isData[id].text.split("/n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== isData[id].text.split("/n").length - 1 && <br />}
              </React.Fragment>
            ))}
        </NoticeText>
      </ViewContainer>
    </Container>
  );
};
export default NoticeView;
