import { useState } from "react";
import {
  AgreewithdrawBox,
  AgreeBox,
  WithdrawInfo,
} from "./AgreeWithdraw.style";
const withdrawText = [
  {
    text: "안녕하세요, 고객님.",
  },
  {
    text: "저희 전기차 충전소 서비스를 이용해주셔서 감사합니다. 회원 탈퇴 시 아래의 사항을 참고해주시기 바랍니다.",
  },
  {
    text: "1. 회원 정보 삭제: 회원 탈퇴 시 결제 수단을 포함한 모든 개인 정보가 즉시 삭제됩니다. 단, 이용 내역은 다음과 같은 이유로 일정 기간 보관됩니다.",
  },
  {
    text: "2. 이용 내역 보관: 서비스 품질 향상 및 추후 대응을 위해 회원님의 이용 내역은 기존 회원의 이용 내역 관리 방침과 동일하게 이용 시작일 기준 1년간 보관됩니다. 보관된 이용 내역은 개인정보 보호법에 따라 안전하게 관리되며, 서비스 대응 외의 목적으로는 사용되지 않습니다.",
  },
  {
    text: "3. 회원 탈퇴 후에도 비회원으로서 서비스 이용이 가능하며, 재가입은 1일 후 가능합니다. 단, 해당 모바일웹의 기능을 남용한다고 여겨지면 필요에 따라 이용이 제한될 수 있습니다.",
  },
];
const AgreeWithdraw = ({ setIsAgreeWithdraw, contentHeight }) => {
  const [isAgree, setIsAgree] = useState(false);
  return (
    <AgreewithdrawBox contentHeight={contentHeight}>
      <WithdrawInfo contentHeight={contentHeight}>
        <ul>
          {withdrawText.map((item) => {
            return <li style={{ margin: "10px 0" }}>{item.text}</li>;
          })}
        </ul>
      </WithdrawInfo>
      <AgreeBox>
        <input
          type="checkbox"
          style={{
            minWidth: "20px",
            height: "20px",
            border: "1px solid black",
            marginRight: "10px",
          }}
          onChange={(e) => {
            setIsAgree(e.target.checked);
          }}
        />
        <span style={{ lineHeight: "16px" }}>
          위의 내용을 충분히 읽어보았고 해당 내용에 모두 동의합니다.
        </span>
      </AgreeBox>
      <div
        style={{
          position: "absolute",
          width: "100%",
          padding: "0 18px",
          bottom: "20px",
          left: "0",
        }}
      >
        <div
          onClick={() => {
            setIsAgreeWithdraw(true);
            // console.log("hello");
          }}
          style={{
            width: "100%",
            height: "40px",
            background: `${isAgree ? "#412BD5" : "	#a0a0a0"}`,
            color: "white",
            fontSize: "18px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            pointerEvents: `${isAgree ? "auto" : "none"}`,
          }}
        >
          다음
        </div>
      </div>
    </AgreewithdrawBox>
  );
};

export default AgreeWithdraw;
