import styled from "styled-components";

// 로그인 되어 있을때 사용자의 이름을 보여주는 UI
export const HomeUserInfoBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  & > img {
    color: black;
    margin-right: 6px;
  }
  .user-name {
    font-weight: 600;
    font-size: 16px;
    /* color: #ffff; */
  }
`;
