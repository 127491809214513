import React from "react";
import { SituationContainer } from "./SituationStyle";

const Situation = ({ availableChargersData }) => {
  return (
    <SituationContainer>
      <p>현재 충전 및 주차상황</p>
      <div>
        <span>충전 가능</span> 충전기 (
        <span>{availableChargersData.chargerNow}대</span> /
        {availableChargersData.chargerTotal}대)
      </div>
      <div>
        <span>주차 가능</span> 주차면 (
        <span>{availableChargersData?.parkingNow}대</span> /
        {availableChargersData.parkingTotal}대)
      </div>
    </SituationContainer>
  );
};

export default Situation;
