import { FooterBtn } from "../style/styled";

const FooterButton = ({
  text,
  type,
  onClick,
  bgColor,
  color,
  children,
  fontSize,
}) => {
  return (
    <FooterBtn
      type={type || "button"}
      bgColor={bgColor}
      fontSize={fontSize}
      color={color}
      onClick={onClick}
    >
      {children} {text}
    </FooterBtn>
  );
};

export default FooterButton;
