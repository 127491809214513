import React, { useEffect, useRef, useState } from "react";
import { NavArr } from "./content";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MenuContainer, MenuListBox } from "./Menu.style";
import MenuItem from "./components/MenuItem/MenuItem";
import { authInfoData } from "../../store/Auth/auth-api";
import Header from "../../components/Layout/Header/Header";
import Container from "../../components/Common/Wrapper/Container";
import Footer from "../../components/Layout/Footer/Footer";
import CompanyInfo from "./components/CompanyInfo/CompanyInfo";
const Menu = () => {
  const navigate = useNavigate();
  const onClickLink = (url) => {
    navigate(url);
  };
  const userInfoData = useSelector((state) => state.authInfo.userInfo);
  const dispatch = useDispatch();
  const ref = useRef();
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, []);

  const [contentHeight, setContentHeight] = useState(window.innerHeight - 55);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      console.log("높이 변경");
      setContentHeight(window.innerHeight - 55);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight - 55)
      );
  }, []);
  return (
    <>
      <Container bgColor="#f5f5f5">
        <Header
          // isBack={true}
          // isHome={true}
          // backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="black"
          title="메뉴"
          marginBottom="false"
        />
        <MenuContainer ref={ref} contentHeight={contentHeight}>
          {/* 네비게이션 요소들을 담고 있는 박스 UI */}
          <MenuListBox>
            {NavArr.map((item, idx) => (
              <MenuItem
                key={idx}
                title={item.title}
                content={item.content}
                userInfo={userInfoData}
                onClickLink={onClickLink}
              />
            ))}
          </MenuListBox>
          <CompanyInfo />
        </MenuContainer>
        <Footer />
      </Container>
    </>
  );
};

export default Menu;
