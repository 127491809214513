import { useEffect, useState } from "react";
import Container from "../../../components/Common/Wrapper/Container";
import Header from "../../../components/Layout/Header/Header";
import ShowUserId from "../../../components/Auth/Find/Id/ShowUserId";
import PhoneCertification from "../../../components/QrCertification/components/PhoneCertification/PhoneCertification";
// 아이디 찾기 페이지
const UserFindId = () => {
  // 이메일 데이터
  const [emailData, setEmailData] = useState();
  // console.log("email", emailData);
  // 토근 state
  const [token, setToken] = useState();

  // 폰인증 , 인증번호 인증 체크
  const [certificationPhonNumberCheck, setCertificationPhoneNumberCheck] =
    useState(false);

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);

  return (
    <Container bgColor="#f5f5f5">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        title="아이디 찾기"
      />
      {certificationPhonNumberCheck ? (
        <ShowUserId emailData={emailData} contentHeight={contentHeight} />
      ) : (
        <PhoneCertification
          setPhoneNumber={setEmailData}
          setCertificationCheck={setCertificationPhoneNumberCheck}
          setToken={setToken}
          mode={"findemail"}
        />
      )}
    </Container>
  );
};

export default UserFindId;
