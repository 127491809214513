import styled from "styled-components";
import { theme } from "../../theme/theme";

// 컨텐츠 아이템 박스, 컨텐츠 아이템을 보여주는 스타일 컴포넌트
export const ContentItemBox = styled.article`
  border: 0.5px solid #f5f5f5;
  /* border: 0.2px solid #f5f5f5; */
  width: 100%;
  padding: 24px 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  .content-title {
    font-size: 22px;
    font-weight: 600;
    span {
      color: #5b5ac7;
    }
  }
  .text-box {
    width: 100%;
    font-size: 16px;
    color: ${theme.colors.SubFontColor};
    padding-top: 18px;
    line-height: 24px;
    ul {
      padding-left: 20px;
      li {
        list-style: disc;
      }
    }
    ol {
      padding-left: 20px;
      li {
        list-style: decimal;
      }
    }
  }
  & > div {
    white-space: pre-line;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
  }
`;
