import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Header from "../../../Layout/Header/Header";
import {
  InputBox,
  InputItem,
  InputValueBox,
  PaymentPasswordBtnBox,
  PaymentPasswordBtn,
} from "./PaymentPassword.style";
import { PaymentContainer } from "../../../../pages/card/payment/style";
import PhoneCertification from "../../../QrCertification/components/PhoneCertification/PhoneCertification";
import { useSelector } from "react-redux";
import ErrorMsg from "../../../Common/Errors/ErrorMsg";

const PaymentPasswordCheck = () => {
  const [error, setError] = useState("");
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  // console.log("userinfo", userInfo);
  const { charge, id, token, price, batteryamount, selectpaymentid } =
    useParams();

  // console.log("charge", charge);
  // console.log("id", id);

  // console.log("price", price);
  // console.log("batteryamount", bastteryamount);
  // console.log("selectpaymentid", selectpaymentid);

  const navigate = useNavigate();
  const [isInputPasswordCover, setIsInputPasswordCover] = useState(true);
  const [clickValue, setClickValue] = useState("");
  const [list, setList] = useState(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].sort(() => Math.random() - 0.5)
  );
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("clickvalue", clickValue);

  const clickValueList = clickValue.split("");

  console.log(token);
  const paymentPasswordCheck = async (data) => {
    console.log("여기 데이터", data);
    try {
      console.log("요청");
      // const token = localStorage.getItem("token");
      const response = await fetch(
        process.env.REACT_APP_API_CHECK_PAYMENT_PASSWORD,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `token ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      console.log("responseData", responseData);
      if (response.status === 200) {
        navigate(
          `/qrbilling/${charge}/${id}/${price}/${token}/${selectpaymentid}/billingkey/${batteryamount}`
        );
        return;
      } else if (response.status === 400) {
        setError(responseData.error);
        setClickValue("");
        return;
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={"결제 비밀번호 확인"}
        marginBottom="false"
      />
      <>
        <PaymentContainer height={contentHeight}>
          <InputValueBox
            style={{
              height: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isInputPasswordCover ? (
              <>
                {Array(4)
                  .fill()
                  .map((item, index) => {
                    return (
                      <div
                        style={{
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: `${
                              clickValueList[index] !== undefined
                                ? "black"
                                : "white"
                            }`,
                            borderRadius: "50%",
                            // marginRight: "20px",
                            border: `${
                              clickValueList[index] !== undefined
                                ? "none"
                                : "2px solid #828282"
                            }`,
                          }}
                        ></div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                <div
                  style={{
                    width: "100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {clickValue.split("").map((item) => {
                    return <div style={{ fontSize: "25px" }}>{item}</div>;
                  })}
                </div>
              </>
            )}
          </InputValueBox>
          <InputBox>
            {list.slice(1).map((item) => {
              return (
                <InputItem
                  onClick={() => {
                    if (clickValue.length > 3) {
                      return;
                    }
                    setClickValue((prev) => prev + item);
                  }}
                >
                  {item}
                </InputItem>
              );
            })}

            <InputItem style={{ fontSize: "20px" }}>
              {isInputPasswordCover ? (
                <span
                  class="material-symbols-outlined"
                  onClick={() => setIsInputPasswordCover((prev) => !prev)}
                >
                  visibility
                </span>
              ) : (
                <span
                  class="material-symbols-outlined"
                  onClick={() => setIsInputPasswordCover((prev) => !prev)}
                >
                  visibility_off
                </span>
              )}
            </InputItem>
            <InputItem
              onClick={() => {
                if (clickValue.length > 3) {
                  return;
                }
                setClickValue((prev) => prev + list[0]);
              }}
            >
              {list[0]}
            </InputItem>

            <InputItem
              onClick={() => {
                setClickValue((prev) => prev.slice(0, prev.length - 1));
              }}
            >
              <span
                style={{ fontSize: "35px" }}
                class="material-symbols-outlined"
              >
                arrow_back
              </span>
            </InputItem>
          </InputBox>
          {error && <ErrorMsg text={error} />}
          <PaymentPasswordBtnBox>
            <PaymentPasswordBtn
              isActive={clickValue.length === 4 ? true : false}
              onClick={() => {
                paymentPasswordCheck({
                  paypass: clickValue,
                  token: token,
                });
              }}
            >
              완료
            </PaymentPasswordBtn>
          </PaymentPasswordBtnBox>
        </PaymentContainer>
      </>
    </>
  );
};

export default PaymentPasswordCheck;
