import { useNavigate } from "react-router";
import * as S from "./ShowUserId.style";

const ShowUserId = ({ emailData, contentHeight }) => {
  // 넘어오는 번호 데이터로 서버에 번호에 해당하는 아이디 요청
  // console.log("emailData", emailData, "hello");
  const navigate = useNavigate();
  return (
    <S.ShowUserIdBox contentHeight={contentHeight}>
      <S.ShowUserIdContent>
        {emailData === "가입하신 계정이 없습니다." ? (
          `${emailData}`
        ) : (
          <>
            회원님의 아이디는 <span>{emailData}</span>입니다.
          </>
        )}
      </S.ShowUserIdContent>
      <S.ShowUserIdBtnBox>
        <S.ShowUserUdBtn
          onClick={() => navigate("/users/find/password")}
          backColor={"#dcdcdc"}
          color={"#505050"}
        >
          비밀번호 찾기
        </S.ShowUserUdBtn>
        <S.ShowUserUdBtn
          onClick={() => navigate("/users/login")}
          backColor={"#412BD5"}
          color={"white"}
        >
          로그인
        </S.ShowUserUdBtn>
      </S.ShowUserIdBtnBox>
    </S.ShowUserIdBox>
  );
};

export default ShowUserId;
