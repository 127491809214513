import React from "react";
import { NoticeContainer } from "./style";
import { useNoticeResponse } from "../../api/useNoticeResponse";
import Container from "../Common/Wrapper/Container";
import Header from "../Layout/Header/Header";
import NoticeItem from "./components/NoticeItem";

// 홈 화면에서 전체 공지를 클릭했을 때  전체 공지를 보여주는 컴포넌트
const Notice = () => {
  // const [notice, setNotice] = useState({});

  // 공지 데이터를 요청해 받아오는 훅
  const { isData, isError } = useNoticeResponse();
  const noticeArr = Object.values(isData);
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="공지사항/ 이벤트"
        marginBottom="false"
      />
      <NoticeContainer>
        <hr />
        {/* 공지 리스트 / 제목, 날짜 등등 */}
        {noticeArr.reverse().map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <NoticeItem list={item} />
            </React.Fragment>
          );
        })}
      </NoticeContainer>
    </Container>
  );
};

export default Notice;
