import React from "react";
import { Btn } from "../style/styled";

const Button = ({
  text,
  type,
  onClick,
  bgColor,
  color,
  children,
  fontSize,
  isActiveBtn,
}) => {
  return (
    <Btn
      type={type || "button"}
      isActiveBtn={isActiveBtn}
      bgColor={bgColor}
      fontSize={fontSize}
      color={color}
      onClick={onClick}
    >
      {children} {text}
    </Btn>
  );
};

export default Button;
