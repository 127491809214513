import styled from "styled-components";

export const WithdrawReasonBox = styled.div`
  height: ${(props) => `${props.contentHeight}px`};
  padding: 60px 18px 0 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const WithdrawReasonTitle = styled.h1`
  color: black;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
`;

export const ReasonBox = styled.ul`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  li:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const ReasonItem = styled.li`
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.clickItem ? "#412BD5" : "#a0a0a0")};
  font-weight: ${(props) => (props.clickItem ? "600" : "400")};
`;

export const ItemDot = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background-color: #c8c8c8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .realdot {
    width: 13px;
    height: 13px;
    background-color: ${(props) =>
      props.clickItem ? "#412BD5" : "transparent"};
    border-radius: 50%;
  }
`;
