import React from "react";
import { useNavigate } from "react-router-dom";
import { HomeUserInfoBox } from "./HomeUserInfoStyle";

const HomeUserInfo = ({ userInfoData }) => {
  // console.log("hello", userInfoData);
  const navigate = useNavigate();
  return (
    <HomeUserInfoBox onClick={() => navigate("/usermenu")}>
      {/* <span class="material-symbols-outlined">person</span> */}
      <img src="/img/Auth/AuthICON.svg" alt="userimg" />
      {/* <span class="material-symbols-outlined">person</span> */}
      <span className="user-name">{userInfoData.user.name} 회원님</span>
    </HomeUserInfoBox>
  );
};

export default HomeUserInfo;
