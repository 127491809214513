import React from "react";
import { ErrorMsgBox, ErrorMsgText } from "../style/styled";

const ErrorMsg = ({ text }) => {
  return (
    <ErrorMsgBox>
      <ErrorMsgText>{text}</ErrorMsgText>
    </ErrorMsgBox>
  );
};

export default ErrorMsg;
