import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router";

const BtnContainer = styled.div`
  padding-top: 30px;
  text-align: center;
`;

const KaKaoLogin = () => {
  // 문의사항
  // 간편로그인을 하게되면 연동을 할것인지?
  // 카톡로그인과, 회사회원가입과 따로 나눌것인지? 그럼 카톡으로 회원가입하면 가입 한 이메일은 중복처리로 되버림
  // API는 /user/register 로 보낼것인지, /user/login 으로 보낼것인지?
  // API 호출 하는 부분에서는 이미지가 필요하면 로그인 api로 보내는게 좋겟지만, 그게 아니고 연동이나 아니면 회원절차를
  // 밟아야하는 부분이 있으면 회원가입 api로 보내서 저장시켜주는 방식으로 하는게 더 적절하다는 의견,

  // 카카오토큰 access_token은 body로 보냄.

  const [IskakaoInfor, setIskakaoInfor] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    console.log("실행");
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    const grant_type = "authorization_code";
    const client_id = process.env.REACT_APP_REST_API_KEY;
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
    fetch(
      `https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&code=${code}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("response", data);
        const { access_token } = data;

        if (access_token) {
          // console.log(`Bearer ${access_token}`);
          console.log("실행");
          fetch("https://kapi.kakao.com/v2/user/me", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => response.json())
            .then((res) => {
              const kakaoToken = data?.access_token;
              localStorage.setItem("kakaoToken", kakaoToken);
              document.cookie = `token=${kakaoToken}; path=/; secure;`;
              // console.log("데이터 성공:", res);

              fetch(process.env.REACT_APP_API_USER_INFO, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${kakaoToken}`,
                },
                body: JSON.stringify(res),
              })
                .then((response) => response.json())
                .then((responseData) => {
                  // console.log("responseData", responseData);
                  // 추가적인 처리
                })
                .catch((error) => {
                  console.error("API 요청 오류:", error);
                  // 오류 처리
                });

              navigate("/");
              setIskakaoInfor(res);
            })
            .catch((error) => {
              console.error("카카오 API 요청 오류:", error);
              // 오류 처리
            });
        } else {
          console.log("access_token 없음");
        }
      })
      .catch((error) => {
        console.error("카카오 토큰 요청 오류:", error);
        // 오류 처리
      });

    console.log("code", code);
  }, []);

  console.log("iskakaotalk", IskakaoInfor?.kakao_account?.profile?.nickname);
  return (
    <BtnContainer>
      <></>
    </BtnContainer>
  );
};

export default KaKaoLogin;
