import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  BrandBox,
  CarContainer,
  CarNumberBox,
  Input,
  WarningText,
} from "../../components/Cars/style/style";
import Select from "../../components/Cars/Common/Selector/Select";
import {
  batteryAmount,
  carBrandList,
  connectorList,
  hyundaCar,
  kiaCar,
  teslaCar,
} from "../../components/Cars/Common/Selector/CarTypeList";
import FooterBox from "../../components/Common/FooterBox";
import Button from "../../components/Common/Button/Button";
import Loading from "../../components/Common/Loading/Loading";
import useTokenPost from "../../hooks/api/useTokenPost";
import Container from "../../components/Common/Wrapper/Container";
import Header from "../../components/Layout/Header/Header";
import { Form } from "../../components/Cars/style/style";
import { theme } from "../../components/theme/theme";
import { useNavigate } from "react-router";
const CarRegister = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    defaultValues: {
      carbrand: "현대",
      carType: "2023 아이오닉6",
      BatCapacity: "상의 후 결정1",
      connector: "DC콤보1",
    },
    mode: "onBlur",
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    console.log("팩트 실행");

    const confirmed = window.confirm("서비스 준비 중 입니다.");
    if (confirmed) {
      navigate("/usermenu");
    }
    if (!confirmed) {
      navigate("/");
    }
  }, []);

  const { carnumber } = watch();
  // console.log(watch(), carnumber);
  // console.log(errors);
  // console.log("dir", isDirty);

  const { isError, isLoading, tokenPostData } = useTokenPost(); // custom hook

  const onCarRegisterHandler = async (registerData) => {
    // console.log("register!!!", registerData);
    // console.log("엥");
    tokenPostData(
      process.env.REACT_APP_API_CAR_REGISTER,
      registerData,
      "/cars/management"
    );
  };

  // console.log("isError", isError);

  // "상기 차량번호는 이미 등록된 차량번호와 중복됩니다. 다시 입력해주세요.",

  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("hello world!");
  return (
    <Container>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="차량 등록하기"
        marginBottom="false"
        isModal={carnumber?.length === 0 ? false : true}
      />

      <CarContainer contentHeight={contentHeight}>
        <form onSubmit={handleSubmit(onCarRegisterHandler)}>
          <CarNumberBox>
            <label htmlFor="carnumber">차량번호</label>
            <Input
              type="text"
              id="carnumber"
              {...register("carnumber", {
                required: "차량 번호가 입력되지 않았습니다.",
                pattern: {
                  value: /^[0-9]{2,3}[가-힣][0-9]{4}$/,
                  message: "차량 번호 형식이 아닙니다.",
                },
              })}
              placeholder="차량번호를 입력하십시오."
            />
            {errors && errors.carnumber && (
              <p
                style={{
                  marginTop: "5px",
                  color: "#412BD5",
                  lineHeight: "18px",
                }}
              >
                {errors.carnumber.message}
              </p>
            )}
            {isError && (
              <p
                style={{
                  marginTop: "5px",
                  color: "#412BD5",
                  lineHeight: "18px",
                }}
              >
                {isError}
              </p>
            )}
          </CarNumberBox>
          <BrandBox>
            <div className="aa">
              <Select
                title="차량 커넥터 선택"
                register={register}
                inputName="connector"
                setValue={setValue}
                selectList={connectorList}
                defaultValue={connectorList[0].name}
              />
              <div style={{ marginTop: "8px", marginBottom: "10px" }}>
                <WarningText>
                  ※ FEC 충전 시스템은 DC콤보1만 지원합니다. 다른 커넥터는
                  DC콤보1으로 전환하는 어뎁터가 반드시 필요합니다.
                </WarningText>
              </div>
              <Select
                register={register}
                title="차량 브랜드 선택"
                setValue={setValue}
                inputName="carbrand"
                selectList={carBrandList}
                defaultValue={carBrandList[0].name}
              />
              <Select
                register={register}
                title="차종 선택"
                setValue={setValue}
                inputName="carType"
                selectList={
                  watch("carbrand") === "현대"
                    ? hyundaCar
                    : watch("carbrand") === "기아"
                    ? kiaCar
                    : watch("carbrand") === "테슬라"
                    ? teslaCar
                    : null
                }
                defaultValue={
                  watch("carbrand") === "현대"
                    ? hyundaCar[0].name
                    : watch("carbrand") === "기아"
                    ? kiaCar[0].name
                    : watch("carbrand") === "테슬라"
                    ? teslaCar[0].name
                    : null
                }
              />

              <Select
                register={register}
                title="배터리 용량"
                setValue={setValue}
                inputName="BatCapacity"
                selectList={batteryAmount}
                defaultValue={batteryAmount[0].name}
              />
            </div>
          </BrandBox>
          <FooterBox>
            {isLoading ? (
              <Button type="submit" text={<Loading />} />
            ) : (
              <Button type="submit" text="차량등록하기" fontSize="18px" />
            )}
            {/* <Button type="submit" text="다음에 등록하기" bgColor="#D5D4F3"/> */}
          </FooterBox>
        </form>
      </CarContainer>
    </Container>
  );
};

export default CarRegister;
