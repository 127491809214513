import { useForm } from "react-hook-form";
import AuthButton from "../../Common/AuthButton";
import AuthInput from "../../Common/AuthInput/AuthInput";
import { ChangePasswordBox } from "./ChangePassword.style";
import DuplicateError from "../../../Common/Errors/DuplicateError";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

const ChangePassword = ({ email, phonenumber, setIsChangePasswordSuccess }) => {
  const [error, setError] = useState();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  // console.log("isValid", isValid);
  // console.log("errors", errors);
  // console.log("email", email);
  // console.log("number", phonenumber);
  // console.log("와치", watch());
  const { newpassword, newpasswordCheck } = watch();
  // console.log("hello");
  const createNewPassword = async (data) => {
    // console.log("들어오는 데이터", data);
    try {
      const bodyData = {
        email,
        phone: phonenumber,
        new_password: data.newpassword,
      };
      // console.log("da", bodyData);
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_PASSWORD_RESET, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      const responseData = await response.json();
      // console.log("변경 완료", responseData);

      if (response.status === 200) {
        setIsChangePasswordSuccess(true);
      } else if (response.status === 400) {
        setError(responseData.error[0]);
      }
      // console.log(response, "뭐냐");
    } catch (e) {
      console.log(e);
    }
  };
  console.log(error);
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <ChangePasswordBox contentHeight={contentHeight}>
      <form onSubmit={handleSubmit(createNewPassword)}>
        <AuthInput
          id="password"
          label={"새 비밀번호"}
          type="password"
          register={register("newpassword", {
            required: "새 비밀번호를 입력해주새요.",
            pattern: {
              value: /(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,24}/,
              message: "특수문자 포함 8-24자리 입력해주세요.",
            },

            // disabled: true,
          })}
          placeholder={"새 비밀번호를 입력해주세요. (특수문자 포함 8-24자리)"}
        />
        <AuthInput
          id="passwordCheck"
          label={"비밀번호 확인"}
          type="password"
          register={register("newpasswordCheck", {
            required: "새 비밀번호를 다시 입력해주세요.",
            // pattern: {
            //   value: /(?=.*[a-zA-ZS])(?=.*?[#?!@$%^&*-]).{8,24}/,
            //   message: "특수문자 포함 8-24자리 입력해주세요.",
            // },
            validate: {
              check: (item) => {
                console.log("hi", item);
                if (newpassword !== item) {
                  return "비밀번호가 일치하지 않아요";
                } else {
                  return;
                }
              },
            },
            // disabled: true,
          })}
          placeholder={"새 비밀번호를 다시 입력해주세요."}
        />
        {errors?.newpassword && newpassword?.length > 0 && (
          <DuplicateError text={errors?.newpassword?.message} />
        )}
        {!errors?.newpassword && errors?.newpasswordCheck && (
          <DuplicateError text={errors?.newpasswordCheck?.message} />
        )}
        {!error?.newpassword && !errors?.newpasswordCheck && error && (
          <DuplicateError text={error} />
        )}
        <AuthButton
          type="submit"
          title="비밀번호 변경"
          isActiveBtn={
            newpassword?.length > 0 &&
            newpasswordCheck?.length > 0 &&
            newpassword === newpasswordCheck &&
            isValid
              ? true
              : false
          }
        />
      </form>
    </ChangePasswordBox>
  );
};

export default ChangePassword;
