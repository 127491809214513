import styled from "styled-components";

export const SelectBatteryAmountLayout = styled.div`
  width: 100%;
  padding: 80px 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SelectBatteryAmountTitle = styled.div`
  width: 100%;
  color: #969696;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  line-height: 25px;
`;

export const SelectBatteryOptionBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export const BatteryOptionForm = styled.form``;

export const BatteryBtn = styled.button`
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  border: ${(props) =>
    props.selectBtn ? "2px solid #412BD5" : "2px solid #969696"};
  color: ${(props) => (props.selectBtn ? "#412BD5" : "#969696")};
  background-color: white;
  & > div {
    position: relative;
    display: flex;
    .left {
      position: relative;
      left: 6px;
      top: 8px;
      width: 9px;
      height: 3px;
      transform: rotate(43deg) translate(6px, 6px);
      border-radius: 2px;
      background-color: ${(props) => (props.selectBtn ? "#412BD5" : "#b4b4b4")};
    }
    .right {
      position: relative;
      left: -3px;
      top: 7px;
      width: 15px;
      height: 3px;
      transform: rotate(-73deg) translate(-5px, 4px);
      border-radius: 2px;
      background-color: ${(props) => (props.selectBtn ? "#412BD5" : "#b4b4b4")};
    }
  }
`;

export const NextBtn = styled.button`
  margin-top: 50px;
  font-weight: 600;
  width: 100%;
  height: 51px;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b4b4b4;
  font-size: 18px;
  color: white;
  background-color: ${(props) => (props.selectBtn ? "#412BD5" : "#b4b4b4")};
  pointer-events: ${(props) => (props.selectBtn ? "auto" : "none")};
`;
