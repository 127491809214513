import React from "react";
import {
  CardBox,
  CardBoxContaier,
  CardCheckBox,
  CardInfoInputBtn,
  CardWrapper,
  CardinforText,
  CheckBoxContainer,
  Container,
} from "./style";
import Header from "../../../components/Layout/Header/Header";

const CardManagement = () => {
  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전소 위치"
      />
      <CardWrapper>
        <CardBoxContaier>
          <CardBox>
            <CardinforText>
              <span>간편결제 카드 등록</span>
              <span>+</span>
            </CardinforText>
          </CardBox>
          <CardBox>
            <CardinforText>
              <span>간편결제 카드 등록</span>
              <span>+</span>
            </CardinforText>
          </CardBox>
          <CardBox>
            <CardinforText>
              <span>간편결제 카드 등록</span>
              <span>+</span>
            </CardinforText>
          </CardBox>
        </CardBoxContaier>
        <CheckBoxContainer>
          <CardCheckBox />
          <CardCheckBox />
          <CardCheckBox />
        </CheckBoxContainer>
        <CardInfoInputBtn>카드 직접 입력</CardInfoInputBtn>
      </CardWrapper>
    </>
  );
};

export default CardManagement;
