import styled from "styled-components";
import { theme } from "../theme/theme";

export const DescriptionContainer = styled.main`
  background-color: white;
  width: 100%;
  /* max-width: 600px; */
  padding: 110px 18px 20px 18px;
  height: ${(props) => `${props.contentHeight}px`};
  overflow-y: auto;
`;
export const DescriptionItem = styled.section`
  width: 100%;
  font-weight: 500;
  margin-bottom: 36px;
  font-size: 18px;
  span {
    color: #412bd5;
    font-weight: 600;
  }
  .content {
    display: flex;
    line-height: 26px;
    .title {
      margin-right: 8px;
    }
  }
  .subtitle {
    margin-left: 31px;
    line-height: 22px;
    color: ${theme.colors.SubFontColor};
    font-size: 15px;
    .subtitle-list {
      margin-top: 13px;
      li {
        width: 100%;
        display: flex;
        flex-direction: column;
        ul {
          li {
            font-size: 12px;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
`;
