import React from "react";
import styled from "styled-components";


// background는 기본 셋팅 값
const Container = styled.div`
    padding:0 18px 0 18px;
`
const PaddingContainer = ({children})=>{
    return(
        <Container>
            {children}
        </Container>
    )
}

export default PaddingContainer;