import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomerService } from "../../style/styled";

const PhoneNumberBox = styled(CustomerService)`
  margin-bottom: 12px;
`;
const PhoneNumber = () => {
  return (
    <PhoneNumberBox>
      <Link to={`tel:053-719-4967}`}>
        {/* <span class="material-symbols-outlined">call</span> */}
        <p>전화 문의하기</p>
      </Link>
    </PhoneNumberBox>
  );
};

export default PhoneNumber;
