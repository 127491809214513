import styled from "styled-components";
import { theme } from "../../../theme/theme";
// layout 전체 감싸는 wrapper
export const HomeHeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  /* max-width: 600px; */
  position: relative;
  /* background-color: #f5f5f5;s */
  height: 60px;
  /* background-color: transparent; */
  /* height: 270px; */
  /* height: 260px; */
  /* background-color: ${theme.colors.mainColor}; */
  /* border-radius: 0 0 13px 13px; */
  /* background-color: black; */
  /* opacity: 0.5; */
`;
