import { useParams } from "react-router";
import {
  fetchCarInfoFailure,
  fetchCarInfoStart,
  fetchCarInfoSucess,
} from "./carInfo-slice";

export const carInfoData = () => {
  return async (dispatch) => {
    try {
      console.log("요청");
      dispatch(fetchCarInfoStart());
      const token = localStorage.getItem("token");
      const response = await fetch(process.env.REACT_APP_API_CAR_INFO, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        return dispatch(fetchCarInfoSucess(data));
      } else if (response.status === 204) {
      } else {
        console.log("error");
      }
    } catch (error) {
      return dispatch(fetchCarInfoFailure(error.message));
    }
  };
};
