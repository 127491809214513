import styled from "styled-components";

export const ShadowBox = styled.div`
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
`;
export const Text = styled.span`
  width: 117px;
  height: 22px;
  padding: 3px 2px;
  font-weight: 600;
  border-radius: 5px;
  background: rgba(213, 212, 243, 0.4);
  font-size: 16px;
  span {
    font-weight: 600;
    font-size: 14px;
    color: #412bd5;
  }
`;

//Information.js component
