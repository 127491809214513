import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import usePhoneCertificationApi from "../../../../api/usePhoneCertificationApi";
import useCertificationNumberApi from "../../../../api/useCertificationNumberApi";
import AuthForm from "../../../Auth/Common/AuthForm/AuthForm";
import AuthInput from "../../../Auth/Common/AuthInput/AuthInput";
import AuthButton from "../../../Auth/Common/AuthButton";
import ErrorMsg from "../../../Common/Errors/ErrorMsg";
import styled from "styled-components";
import { Watch } from "react-loader-spinner";
import AuthNumberValidTime from "../../../Auth/Common/AuthNumberValidTime/AuthNumberValidTime";
import { theme } from "../../../theme/theme";

const RePhoneCertificationBtn = styled.button`
  font-weight: 600;
  width: 100%;
  height: 51px;
  border: none;
  background: ${theme.colors.pointColor};
  border-radius: 8px;
  margin-top: 20px;
  font-size: 18px;
  color: #ffffff;
`;
// 휴대폰, 인증번호 인증 컴포넌트
const PhoneCertification = ({
  setCertificationCheck,
  userInfo,
  setPhoneNumber,
  setToken,
  mode,
  setEmail,
  setIsEditPaymentPassword,
}) => {
  const [timerKey, setTimerKey] = useState("");
  const [isTimer, setIsTimer] = useState(true);

  // console.log("timerkey", timerKey);

  // 인증번호 입력 form
  const {
    register: verifyRgs,
    handleSubmit: verifySubmit,
    watch: verifywatch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
  });
  console.log("errors", errors);
  // 휴대폰 번호 입력 form
  const {
    register,
    handleSubmit: phoneNumberSubmit,
    formState: { errors: phoneErrors, isValid: phoneValid },
    watch,
  } = useForm({ mode: "onBlur" });

  // console.log(watch());

  // console.log("phoneerrors", phoneErrors);

  // 휴대폰 번호 입력 api
  const {
    phoneNumber,
    email,
    vertify,
    isLoading: phoneCertifiLoading,
    errorMsg: phoneNumberCertifiErrorMsg,
    fetchData: phoneCertificationFetch,
  } = usePhoneCertificationApi();
  // console.log(verifywatch());

  // 휴대폰 번호 입력 후 서버에 보내는 콜백 함수
  const handlePhoneCertificationSubmit = async (data) => {
    // console.log("hello");
    // console.log("폰 인증 데이터는 이거다.", data);
    await phoneCertificationFetch(data);
  };

  const rePhone = async (data) => {
    // console.log("hello");
    setIsTimer(true);
    // timerLetsGitit();
    // console.log("폰 인증 데이터는 이거다.", data);
    await phoneCertificationFetch(data);
  };

  // 인증번호 입력 api
  const {
    errorMsg: cerrificationError,
    fetchData: certificationFetch,
    isLoading: certificationLoading,
  } = useCertificationNumberApi();

  // console.log("error", cerrificationError);
  // 인증번호 입력 후 서버에 보내는 콜백 함수
  const handleCertificationSubmit = async (data) => {
    // console.log("hello");
    // console.log("마 이게 데이터다! ", data);
    // if (timerValue === "인증번호를 다시 받아주세요.") {
    //   console.log("이미 시간이 지나서 인증번호 입력 못해요");
    //   return;
    // }
    const verifyNumber = {
      verify: data.verify,
      phone: data.phone,
    };
    // verifyNumber.email = data.email;
    // console.log(verifyNumber, "hello");
    console.log("요청");
    const response = await certificationFetch(
      data,
      "인증코드가 일치하지 않습니다."
    );
    if (response) {
      response.phone = data.phone;
      clearInterval(timerKey);
      if (mode === "paymentpassword") {
        setIsEditPaymentPassword(false);
        return;
      }
      // console.log("data 어떻게 오는데?", response);
      setPhoneNumber(data.phone);
      if (mode === "findemail") {
        setPhoneNumber(response.email);
      }
      if (mode === "findpassword") {
        setEmail(data.email);
        setPhoneNumber(data.phone);
      }
      setCertificationCheck(true);
      setToken(response.token);
      // 성공 처리 로직 수행
    } else {
      // 에러 처리 로직 수행
      setCertificationCheck(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener(
  //     "keydown",
  //     (e) => {
  //       if (e.key === "Enter") {
  //         console.log("나 엔터 눌렀다!");
  //         e.preventDefault();
  //       }
  //     },
  //     true
  //   );
  // }, []);

  return (
    <>
      {vertify ? (
        // 인증번호 입력
        <>
          <AuthForm onSubmit={verifySubmit(handleCertificationSubmit)}>
            {mode === "findpassword" && (
              <AuthInput
                label={"아이디"}
                value={email}
                register={verifyRgs("email", {
                  required: "아이디(이메일)를 입력해주세요.",
                })}
                placeholder={"아이디를 입력해주세요."}
              />
            )}
            {/* {timerValue === "인증번호 시간 만료" && <input hidden="hidden" />} */}
            <AuthInput
              label="휴대폰 번호"
              type="number"
              id="phone"
              value={phoneNumber}
              // placeholder={phoneNumber}
              register={verifyRgs("phone", {
                minLength: {
                  value: 11,
                  message: "휴대폰번호는 최소 11자리 입니다.",
                },
                maxLength: {
                  value: 11,
                  message: "휴대폰번호는 최대 11자리 입니다.",
                },
                required: true,
              })}
            />
            <AuthInput
              label="인증번호"
              type="number"
              id="verify"
              // timerValue={timerValue}
              register={verifyRgs("verify", {
                required: "인증번호를 입력해주세요.",
                minLength: {
                  value: 6,
                  message: "인증번호는 최소 6자리 입니다.",
                },
                maxLength: {
                  value: 6,
                  message: "인증번호는 최대 6자리 입니다.",
                },
              })}
              placeholder={"인증번호를 입력해주세요."}
              isTimer={isTimer}
              setIsTimer={setIsTimer}
              setTimerKey={setTimerKey}
            />
            {/* <AuthNumberValidTime
              setIsTimer={setIsTimer}
              // timerKey={timerKey}
              // timerValue={timerValue}
              // setTimerValue={setTimerValue}
              // setTimerKey={setTimerKey}
              // rePhone={() => rePhone({ phone: phoneNumber })}
            /> */}

            {errors && errors?.verify && (
              <ErrorMsg text={errors?.verify?.message} />
            )}
            {cerrificationError && <ErrorMsg text={cerrificationError} />}
            {isTimer && (
              <AuthButton
                type="submit"
                title="인증하기"
                isActiveBtn={isValid}
              />
            )}
            {!isTimer && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    color: "tomato",
                    fontWeight: "600",
                  }}
                >
                  인증번호 유효시간 5분이 초과되었습니다.
                </span>
              </div>
            )}
            {!isTimer && (
              <RePhoneCertificationBtn
                type="button"
                onClick={() => rePhone({ phone: phoneNumber })}
              >
                인증번호 재발급
              </RePhoneCertificationBtn>
            )}
          </AuthForm>
          {/* <ResendVerificationCodeButton
            onClick={() => window.location.reload()}
          >
            <span>인증번호를 새로 받으시겠습니까? </span>
          </ResendVerificationCodeButton> */}
        </>
      ) : (
        // 휴대폰 번호 입력
        <AuthForm onSubmit={phoneNumberSubmit(handlePhoneCertificationSubmit)}>
          {mode === "findpassword" && (
            <AuthInput
              label={"아이디"}
              register={register("email", {
                required: "아이디(이메일)를 입력해주세요.",
              })}
              placeholder={"아이디를 입력해주세요."}
            />
          )}
          <AuthInput // 등록 된 휴대폰 번호가 들어가야함
            label="휴대폰 번호"
            type="number"
            id="phone"
            value={userInfo && userInfo?.user?.phone}
            register={register("phone", {
              required: "휴대폰 번호를 입력해주세요.",
              pattern: {
                value: /01([0|1|6|7|8|9])-?([0-9]{4})-?([0-9]{4})$/,
                message: "휴대폰번호 형식이 아닙니다.",
              },
              maxLength: {
                value: 11,
                message: "최대 11개의 숫자만 가능합니다.",
              },
              // minLength: {
              //   value: 11,
              //   message: "최소 11개의 숫자만 가능합니다.",
              // },
            })}
            placeholder={"휴대폰 번호를 입력해주세요."}
          />
          {phoneErrors && phoneErrors?.phone && (
            <ErrorMsg text={phoneErrors?.phone?.message} />
          )}
          {phoneNumberCertifiErrorMsg && (
            <ErrorMsg text={phoneNumberCertifiErrorMsg} />
          )}
          <AuthButton
            type="submit"
            title={
              phoneCertifiLoading ? "인증번호 받는 중..." : "인증번호 받기"
            }
            isActiveBtn={phoneValid}
          />
        </AuthForm>
      )}
    </>
  );
};

const ResendVerificationCodeButton = styled.div`
  height: 53px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto;
`;

export default PhoneCertification;
