import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PaymentDesList from "./PaymentDesList";
import Header from "../Layout/Header/Header";
import Button from "../Common/Button/Button";
import Container from "../Common/Wrapper/Container";
const PaymentRegisterContainer = styled.div`
  margin-top: 40px;
  padding: 40px 24px 0 12px;
`;
const Absolute = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  a {
    //Link
    width: 100%;
  }
`;
const PaymentRegister = () => {
  return (
    <Container>
      <Header
        // isBack={true}
        // isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 등록하기"
      />
      <PaymentRegisterContainer>
        <PaymentDesList paymentDesArr={paymentDesArr} />
      </PaymentRegisterContainer>
      <Absolute>
        <Link to={`${process.env.PUBLIC_URL + "/"}`}>
          <Button
            text={
              <>
                결제수단 등록하기
                <br />
                (추가등록 포함)
              </>
            }
          />
        </Link>
        <Link to={`${process.env.PUBLIC_URL + "/"}`}>
          <Button color="#412BD5" text="다음에 등록하기" bgColor="#D5D4F3" />
        </Link>
      </Absolute>
    </Container>
  );
};

// 결제수단 등록에 대한 설명
const paymentDesArr = [
  {
    content: (
      <>
        <div style={{ marginRight: "8px" }}>
          <img
            src={`${process.env.PUBLIC_URL + "/img/ListNumber/number1.svg"}`}
          />
        </div>
        <div className="text">
          현재는 <span>신용카드, 현금카드만</span> 결제 수단으로 사용합니다.
        </div>
      </>
    ),
  },
  {
    content: (
      <>
        <div style={{ marginRight: "8px" }}>
          <img
            src={`${process.env.PUBLIC_URL + "/img/ListNumber/number2.svg"}`}
          />
        </div>
        <div className="text">
          아래에 <span>'결제수단 등록하기' 클릭</span>하면 제휴된 결제수단 등록
          회사의 해당 페이지로 이동합니다.
        </div>
      </>
    ),
  },
  {
    content: (
      <>
        <div style={{ marginRight: "8px" }}>
          <img
            src={`${process.env.PUBLIC_URL + "/img/ListNumber/number3.svg"}`}
          />
        </div>
        <div className="text">
          제휴된 결제수단 카드회사에서 등록하면{" "}
          <span>결제수단 관리의 결제수단으로 등록</span> 됩니다.
        </div>
      </>
    ),
  },
];
export default PaymentRegister;
