import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CarContainer = styled.main`
  width: 100%;
  overflow: scroll;
  height: ${(props) => `${props.contentHeight}px`};
  padding: 80px 18px 100px;
  form {
    padding-bottom: 100px;
  }
`;
export const NoCarList = styled.div`
  width: 100%;
  color: #412bd5;
  font-weight: bold;
  font-size: 20px;
  /* margin-top: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

// common
export const Label = styled.label`
  color: ${theme.colors.pointColor};
  font-size: 20px;
  font-weight: 600;
  margin-top: 18px;
`;

export const CarInfoBox = styled.article`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
`;
export const CarInfoItem = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  div:nth-child(2) {
    color: #45454f;
  }
`;
export const CarInfoBtnBox = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 10px 0 30px 0;
  display: flex;
  a {
    width: 100%;
  }
  a:nth-of-type(1) {
    margin-right: 10px;
  }
`;
export const CarInfoBtn = styled.button`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 35px;
  border-radius: 8px;
  background-color: ${(props) => props.bgColor || "#412BD5"};
  color: ${(props) => props.color || "white"};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Container = styled.div`
  margin-top: 50px;
`;
export const Form = styled.form`
  width: 100%;
`;
export const CarNumberBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  label {
    color: ${theme.colors.pointColor};
    font-size: 20px;
    font-weight: 600;
  }
  input {
    margin-top: 7px;
  }
`;
export const WarningText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.SubFontColor};
`;
export const Input = styled.input`
  padding: 11px 16px;
  width: 100%;
  max-width: 500px;
  /* border: 1px solid ${theme.colors.pointColor}; */
  height: 50px;
  border: 1px solid ${theme.colors.FontGrayColor};
  border-radius: 8px;
`;
export const BrandBox = styled.div`
  .aa {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
    }
    select {
      width: 100%;
      height: 40px;
      border-radius: 8px;
    }
  }
  margin: 30px 0 10px 0;
`;

export const FooterBox = styled.footer`
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  a {
    width: 100%;
  }
`;

export const SelectBox = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const SelectLabel = styled.label`
  color: ${theme.colors.pointColor};
  font-size: 20px;
  font-weight: 600;
`;

export const SelectInputBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  max-width: 500px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.FontGrayColor};
  &:focus {
    border: 1px solid red;
  }
  .arrow-icon {
    position: absolute;
    top: 6.75px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const SelectInput = styled.input`
  width: 100%;
  border: none;
  padding: 11px 16px;
`;

export const SelectList = styled.ul`
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 75px;
  border: 1px solid ${theme.colors.FontGrayColor};
  background-color: white;
  border-radius: 8px;
  z-index: 10;
  background-color: #f5f5f5;
`;

export const SelectListItem = styled.li`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: ${theme.colors.SubFontColor};
`;

export const SelectConnectorList = styled.div`
  width: 100%;
  max-width: 320px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 8px;
`;

export const SelectConnectorBtn = styled.button`
  border: none;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.currentSelect ? "white" : "black")};
  padding: 11px;
  border-radius: 8px;
  background-color: ${(props) => (props.currentSelect ? "#412BD5" : "#F5F5F5")};
  @media screen and (max-width: 350px) {
    width: 90px;
  }
`;
