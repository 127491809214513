import styled from "styled-components";

export const NoticeContainer = styled.main`
  width: 100%;
  /* max-width: 600px; */
  padding: 80px 18px 0 18px;
  .edit {
    width: 117px;
    height: 22px;
    display: flex;
    float: right;

    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 5px;
    margin-bottom: 13px;
    p {
      font-size: 13px;
    }
  }
  hr {
    width: 100%;

    border: 2px solid #f5f5f5;
  }
`;
