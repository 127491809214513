import { useState } from "react";

import Container from "../../../components/Common/Wrapper/Container";
import Header from "../../../components/Layout/Header/Header";
import ChangePassword from "../../../components/Auth/Find/Password/ChangePassword";

import PhoneCertification from "../../../components/QrCertification/components/PhoneCertification/PhoneCertification";
import ChangePasswordSuccess from "../../../components/Auth/Find/Password/ChangePasswordSuccess";

const UserFindPassword = () => {
  const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState(false);
  // 폰인증 , 인증번호 인증 체크
  const [certificationPhonNumberCheck, setCertificationPhoneNumberCheck] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const [email, setEmail] = useState();

  // 토근 state
  const [token, setToken] = useState();
  // console.log("email!!!", email);
  return (
    <Container bgColor="#f5f5f5">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        marginBottom="false"
        title="비밀번호 찾기"
      />
      {isChangePasswordSuccess && certificationPhonNumberCheck ? (
        <ChangePasswordSuccess />
      ) : !isChangePasswordSuccess && certificationPhonNumberCheck ? (
        <ChangePassword
          email={email}
          phonenumber={phoneNumber}
          setIsChangePasswordSuccess={setIsChangePasswordSuccess}
        />
      ) : (
        <PhoneCertification
          setCertificationCheck={setCertificationPhoneNumberCheck}
          setPhoneNumber={setPhoneNumber}
          setEmail={setEmail}
          setToken={setToken}
          mode={"findpassword"}
        />
      )}
    </Container>
  );
};

export default UserFindPassword;
