import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  CarInfoBox,
  CarInfoBtn,
  CarInfoBtnBox,
  CarInfoItem,
} from "../style/style";
import Modal from "../../Common/Modal/Modal";
import axios from "axios";
import { useDispatch } from "react-redux";
import { carInfoData } from "../../../store/CarInfo/carInfo-api";

const CarList = ({ carListData }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(null);
  const dispatch = useDispatch();

  const onDeleteCarBtn = (id) => {
    setIsDeleteModal(id);
  };

  const deleteCarFunc = async (id, setIsDeleteModalOpen) => {
    const token = localStorage.getItem("token");
    console.log("실행");
    try {
      console.log("요청");
      const res = await axios.delete(process.env.REACT_APP_API_CAR_DELETE, {
        data: { id: id },
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      // console.log("res", res.data);
      if (res.status === 200) {
        console.log("삭제가 성공적으로 되었습니다.");
        dispatch(carInfoData());
        document.body.style.overflow = "";
        setIsDeleteModalOpen(null);
      } else if (res.status === 204) {
        console.log("삭제가 성공적으로 되었습니다.");
        dispatch(carInfoData());
        setIsDeleteModalOpen(null);
      } else if (res.status === 409) {
        console.log(res.error);
      }
    } catch (e) {
      console.log("삭제 요청에 실패했습니다", e);
    }
  };

  return (
    <>
      {/* isDeleteModalOpen 에 삭제 버튼을 클릭한 자동차의 id 번호를 저장 모달에서 취소 버튼을 클릭하면 값을 null 로 변경 */}
      {isDeleteModal && (
        <Modal
          type="delete"
          id={isDeleteModal}
          setIsModal={setIsDeleteModal}
          text="차량을 삭제 하시겠습니까?"
          func={deleteCarFunc}
        />
      )}
      {carListData.map((item, idx) => {
        return (
          <CarInfoBox key={idx}>
            {/* item.id가 같은 경우에만 Modal 컴포넌트가 실행이됨. */}
            {/* map을 사용하고 있어서 item.id와 같은 경우에만 Modal이 뛰어줘야함. 아니면 모든 item.id 가 실행이 됨. */}

            <CarInfoItem>
              <div>차량번호</div>
              <div>{item.carnumber}</div>
            </CarInfoItem>
            <CarInfoItem>
              <div>차량종류</div>
              <div>{item.carType}</div>
            </CarInfoItem>
            <CarInfoItem>
              <div>커넥터종류</div>
              <div>{item.connector}</div>
            </CarInfoItem>
            <CarInfoItem>
              <div>배터리용량</div>
              <div>{item.BatCapacity}</div>
            </CarInfoItem>
            <CarInfoBtnBox>
              <Link to={`/cars/edit/${item.id}`}>
                <CarInfoBtn>차량수정</CarInfoBtn>
              </Link>
              <CarInfoBtn
                type="button"
                onClick={() => {
                  document.body.style.overflow = "hidden";
                  onDeleteCarBtn(item.id);
                }}
                bgColor="#D5D4F3"
                color="black"
              >
                차량삭제
              </CarInfoBtn>
            </CarInfoBtnBox>
          </CarInfoBox>
        );
      })}
    </>
  );
};

export default CarList;
