import React from "react";
import styled from "styled-components";
import Container from "../components/Common/Wrapper/Container";
import Header from "../components/Layout/Header/Header";

const Box = styled.div`
  padding: 133px 61px 0 61px;
  .aa {
    text-align: center;
    .bb {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 8px;
    }
  }
`;
const Sucess = () => {
  return (
    <Container bgColor="white">
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="충전 신청 완료"
      />
      <div>
        <Box>
          <div className="aa">
            <img src="/img/Vector.jpg" />
            <p
              style={{
                marginTop: "16px",
                fontSize: "19px",
                fontWeight: "600",
                marginBottom: "30px",
              }}
            >
              충전 신청이 완료되었습니다.
            </p>
            <div className="bb">
              <p>
                반드시 <span>충전 취소 완료 문자</span>를 받으신 후,
              </p>
              <p>
                <span>커넥터</span>의 연결을 <span>해제</span>하셔야 합니다.
              </p>
            </div>
          </div>
        </Box>
      </div>
      {/* <Fixed>
                <Link to="/"><Button text='홈으로 이동'/></Link>
            </Fixed> */}
    </Container>
  );
};

export default Sucess;
