import styled from "styled-components";

export const ChargeStatusContainer = styled.main`
  background-color: #f5f5f5;
  width: 100%;
  /* max-width: 600px; */
  padding: 80px 18px 0 18px;
  height: ${(props) => `${props.contentHeight}px`};
`;
export const Card = styled.section`
  border: 2px solid #f5f5f5;
  /* border: 0.1px solid #aaaaaa; */
  width: 100%;
  height: 250px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  .my_status {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 18px;
  }
  h1 {
    font-size: 20px;
    font-weight: 800;
  }
`;
export const Introduction = styled.div`
  display: flex;
  width: 100%;
  line-height: 28px;
  justify-content: space-between;
  font-size: 16px;
  p:nth-child(1) {
    font-weight: bold;
  }
`;

export const RefreshBtn = styled.button`
  border: none;
  color: #5b5ac7;
  padding-top: 5px;
  cursor: pointer;
`;

export const UpdateInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const CardTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const NoData = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
`;

export const BatteryValue = styled.div`
  display: flex;
  align-items: center;

  .battery-icon-box {
    position: relative;
    display: flex;
    align-items: center;
    /* margin-right: 5px; */
    p {
      position: absolute;
      top: -2px;
      left: 8px;
      font-size: 15px;
      font-weight: 800;
    }
    .battery-dot {
      /* 배터리 위 쪽에 접지 부분 */
      width: 3px;
      height: 12px;
      background-color: black;
      border-radius: 10px 0 0 10px;
    }
  }
`;
export const BatteryIcon = styled.div`
  padding: 2px;
  border: 2px solid black;
  width: 50px;
  height: 23px;
  background-color: white;
  border-radius: 5px;
  .battery {
    width: ${(props) => `${props.batteryValue}`};
    height: 100%;
    background-color: ${(props) => props.batteryColor};
    border-radius: 3px;
  }
`;
