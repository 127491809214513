import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Address from "../pages/address/Address";
import UserEdit from "../pages/Users/UserEdit";
import UserAccount from "../pages/Users/UserAccount";
import UserLogin from "../pages/Users/UserLogin";
import KaKaoLogin from "../components/Auth/KaKaoLogin/KaKaoLogin";
import CarEdit from "../pages/Cars/CarEdit";
import CarRegister from "../pages/Cars/CarRegister";
import Notice from "../components/Notice/Notice";
import Information from "../pages/fec/information/Information";
import QrCode from "../components/QrScanner/QrCode";
import Sucess from "../pages/Sucess";
import History from "../components/payment/History";
import Application from "../pages/fec/application/Application";
import CarManagement from "../pages/Cars/Management/CarManagement";
import QrCertification from "../components/QrCertification/QrCertification";
import ChargeStatus from "../components/Charge/ChargeStatus/ChargeStatus";
import ApplicationDes from "../components/Fec/ApplicationDes/ApplicationDes";
import NoticeView from "../components/Notice/NoitceView/NoticeView";
import Home from "../pages/Home/Home";
import UserProfile from "../pages/Users/UserProfile";
import Price from "../pages/Price/Price";
import CardManagement from "../pages/card/management/Management";
import Description from "../components/DetailDes/Description";
import { useEffect } from "react";
import Payment from "../pages/card/payment/Payment";
import PaymentCreate from "../pages/card/payment/PaymentCreate";
import PaymentUpdate from "../pages/card/payment/PaymentUpdate";
import PaymentRegister from "../components/payment/PaymentRegister";
import QrBilling from "../components/QrCertification/QrBilling";
import QrBillingSuccess from "../components/QrCertification/QrBillingSuccess";
import Menu from "../pages/Menu/Menu";
import PhoneNumber from "../pages/Menu/components/PhoneNumber/PhoneNumber";
import UserMenu from "../pages/Users/UserMenu/UserMenu";
import TermsOfService from "../pages/termsofservice/TermsOfService";
import PaymentSelect from "../pages/card/payment/PaymentSelect";
import ImmediatePaymentSelect from "../components/QrCertification/components/ImmediatePaymentSelect/ImmediatePaymentSelect";
import UserFindId from "../pages/Users/UserFind/UserFindId";
import UserFindPassword from "../pages/Users/UserFind/UserFindPassword";
import UserMemberWithdraw from "../pages/Users/UserMemberWithdraw";
import OpenSourceLicense from "../pages/openSourceLicense/OpenSourceLicense";
import PaymentPassword from "../components/payment/components/PaymentPassword/PaymentPassword";
import PaymentPasswordCheck from "../components/payment/components/PaymentPassword/PaymentPasswordCheck";
import { useDispatch } from "react-redux";
import { update } from "../store/PrevUrl";
import JoinSuccess from "../pages/JoinSuccess/JoinSuccess";

const ScrollTopReset = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("팩트 실행");
    // console.log("스크롤 업 실행");
    window.scrollTo(0, 0);
    if (pathname.includes("/qrbilling")) {
      dispatch(update(true));
    } else {
      dispatch(update(false));
    }
  }, [pathname]);
};
export const Rou = () => {
  return (
    <BrowserRouter>
      <ScrollTopReset />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/address" element={<Address />} />
        <Route path="/sucess" element={<Sucess />} />
        <Route path="/qrcodescanner" element={<QrCode />} />
        <Route path="/053-719-4967" element={<PhoneNumber />} />
        <Route path="/price" element={<Price />} />
        <Route path="/oauth/kakao/callback" element={<KaKaoLogin />} />
        <Route path="/qrrequest/:charge/:id" element={<QrCertification />} />
        <Route
          path="qrbilling/:charge/:id/:price/:token/:batteryamount"
          element={<QrBilling />}
        />
        <Route
          path="qrbilling/:charge/:connector/:price/:token/:billValue/:billcase/:batteryamount"
          element={<QrBillingSuccess />}
        />
        <Route
          path="/qrbilling/payment/select/:charge/:id/:price/:token/:batteryamount"
          element={<ImmediatePaymentSelect />}
        />
        <Route path="/users/login" element={<UserLogin />} />
        <Route path="/users/account" element={<UserAccount />} />
        <Route path="/users/profile" element={<UserProfile />} />
        <Route path="/users/paymentregister" element={<PaymentRegister />} />
        <Route path="/users/edit" element={<UserEdit />} />
        <Route path="/payment/history" element={<History />} />
        <Route path="/detail/:id/description" element={<Description />} />

        <Route path="/cars/register" element={<CarRegister />} />
        <Route path="/cars/edit/:id" element={<CarEdit />} />
        <Route path="/cars/management" element={<CarManagement />} />
        <Route path="/fec/information" element={<Information />} />
        <Route
          path="/fec/application/description"
          element={<ApplicationDes />}
        />
        <Route path="/fec/application" element={<Application />} />
        <Route path="/card/management" element={<CardManagement />} />
        <Route path="/card/payment/management" element={<Payment />} />
        <Route
          path="/card/payment/create/:customerUid"
          element={<PaymentCreate />}
        />
        <Route
          path="/card/payment/management/:id"
          element={<PaymentUpdate />}
        />
        <Route path="/community/notice" element={<Notice />} />
        <Route path="/community/:id" element={<NoticeView />} />
        <Route path="/charge/status" element={<ChargeStatus />} />
        <Route path="/usermenu" element={<UserMenu />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/service" element={<TermsOfService />} />
        <Route path="/card/payment/select" element={<PaymentSelect />} />
        <Route path="/users/find/id" element={<UserFindId />} />
        <Route path="/users/find/password" element={<UserFindPassword />} />
        <Route path="/users/memberwithdraw" element={<UserMemberWithdraw />} />
        <Route path="/opensourcelicense" element={<OpenSourceLicense />} />
        <Route
          path="/card/payment/password/check/:id/:charge/:token/:price/:batteryamount/:selectpaymentid"
          element={<PaymentPasswordCheck />}
        />
        <Route
          path="/card/payment/password/:mode/:where"
          element={<PaymentPassword />}
        />
        <Route path="/users/join/success" element={<JoinSuccess />} />
      </Routes>
    </BrowserRouter>
  );
};
