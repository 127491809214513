export const termsOfServiceContent = [
  {
    title: "제 1장 총칙",
    main: [
      {
        contentTitle: "제1조(목적)",
        content: [
          `본 약관은 FEC 모바일(이하 “당 사이트”라 한다)이 제공하는 모든 서비스(이 하 "서비스"라 한다)의 이용조건 및 절차, 회원과 당 사이트의 권리, 의무, 책 임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.`,
        ],
      },
      {
        contentTitle: "제2조(약관의 효력과 변경)",
        content: [
          "① 당 사이트는 귀하가 본 약관 내용에 동의하는 경우, 당 사이트의 서비스 제 공 행위 및 귀하의 서비스 사용 행위에 본 약관이 우선적으로 적용됩니다.",
          "② 이 약관은 합리적인 사유가 발생할 경우 관련법령에 위배되지 않는 범위 안에서 개정될 수 있습니다.",
          "③ 당 사이트의 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현 행 약관과 함께 사이트 초기화면에 그 적용일 7일 이전부터 적용일 이후 상당 한 기간동안 공지하고, 기존회원에게는 개정약관을 개별로 통지합니다. 단, 회 원에 불리하게 약관내용을 변경하는 경우에는 최소 30일 이전부터 적용일 이 후 상당한 기간 동안 공지합니다.",
          "④ 변경된 약관은 부칙에 명시된 날부터 그 효력이 발생되며, 공지된 이후 거 부의사를 표시하지 아니하고 서비스를 계속 사용할 경우에는 약관 변경에 동 의한 것으로 간주됩니다. 회원이 변경된 약관에 동의하지 않는 경우 회원은 회 원탈퇴를 할 수 있습니다.",
        ],
      },
      {
        contentTitle: "제3조(약관 외 준칙)",
        content: [
          "① 이 약관은 당 사이트가 제공하는 서비스에 관한 이용안내와 함께 적용합니다.",
          "② 이 약관에 명시되지 아니한 사항은 관계법령 및 서비스별 안내의 취지에 따라 적용할 수 있습니다.",
        ],
      },
      {
        contenttitle: "제4조(용어의 정의)",
        content: [
          "본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.",
          "1. 이용자 : 본 약관에 따라 당 사이트가 제공하는 서비스를 받는 자",
          "2. 가입 : 당 사이트가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위",
          "3. 회원 : 당 사이트에 개인 정보를 제공하여 회원 등록을 한 자로서 당 사이 트가 제공하는 서비스를 이용할 수 있는 자",
          "4. 회원 ID : 회원의 식별 및 서비스 이용을 위하여 자신이 선정한 문자 및 숫 자의 조합4",
          "5. 비밀번호 : 이용자와 회원ID가 일치하는지를 확인하고 통신상의 자신의 비 밀보호를 위하여 이용자 자신이 선정한 문자와 특수문자, 숫자의 조합",
          "6. 회원탈퇴 : 회원이용을 종료시키는 행위이며 기존 권한을 상실",
          "7. 본 약관에서 정의하지 않은 용어는 관계법령, 개별서비스에 대한 별도약관 및 이용규정에서 정의합니다.",
        ],
      },
    ],
  },
  {
    title: "제 2장 이용계약의 체결 및 해지",
    main: [
      {
        contentTitle: "제5조(이용계약의 성립)",
        content: [
          "① 이용계약은 신청자가 온라인으로 당 사이트에서 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 가입을 완료하는 것으로 성립됩니다.",
          "② 본 약관에 대한 동의는 가입신청 당시 ‘동의함’버튼을 누름으로써 성립됩니 다.",
        ],
      },
      {
        contentTitle: "제6조(회원가입)",
        content: [
          "① 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 당 사이트에서 요 청하는 제반정보를 제공하여야 하며, 서비스 개선 등의 목적으로 회원의 동의 하에 추가적인 개인정보를 요구할 수 있습니다.",
          "② 회원가입은 반드시 실명으로만 가입할 수 있습니다.",
          "③ 회원ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 전산센터에 요청하여야 합니다.",
          "④ 타인의 명의를 도용하여 가입신청을 한 회원의 모든 회원ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.",
          "⑤ 회원은 등록된 본인의 회원정보가 변경된 경우, 즉시 변경된 내용으로 회원 정보를 수정하여야 하며, 변경사항을 수정하지 아니하여 발생한 불이익에 대하 여 당 웹사이트는 책임지지 아니합니다.",
        ],
      },
      {
        contentTitle: "제7조(이용신청의 승낙과 제한)",
        content: [
          "① 당 사이트는 제6조의 규정에 의한 가입신청고객에 대하여 서비스 이용을승낙합니다.",
          "② 당 사이트는 다음 각 호의 어느 하나에 해당하는 경우에는 서비스 이용을 승낙하지 아니합니다.",
          "1. 휴대폰 번호 등 중요 사항이 타인의 정보와 중복되는 경우",
          "2. 가입신청서의 내용을 허위로 기재하였거나 신청하였을 때",
          "3. 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때",
          "4. 다른 사람의 당 사이트 서비스 이용을 방해하거나 그 정보를 도용하는 등 의 행위를 하였을 때",
          "5. 당 사이트를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우",
          "6. 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우",
          "7. 기타 당 사이트가 정한 가입신청요건이 미비 되었을 때",
          "③ 당 사이트는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 이용계약 성립을 유보할 수 있습니다.",
          "1. 서비스 관련 제반 용량이 부족한 경우",
          "2. 기술상 장애 사유가 있는 경우",
        ],
      },
      {
        contentTitle: "제8조(이용자격의 정지 및 상실)",
        content: [
          "당 사이트는 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 이용자격을 일시적으로 정지하고 30일 이내에 시정하도록 이용자에게 요구할 수 있으며, 이후 동일한 행위를 2회 이상 반복할 경우에 30일 기간의 소명기회를 부여한 후 이용자격을 상실시킬 수 있습니다.",
        ],
      },
      {
        contentTitle: "제9조(계약해제, 해지 등)",
        content: [
          "① 회원은 언제든지 서비스 정보수정 메뉴 등을 통하여 이용계약을 해지할 수 있으며, 기관은 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.",
          "② 회원이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 회원정보 를 보유하는 경우를 제외하고는 수집된 개인정보를 파기하는 것을 원칙으로 합니다.",
        ],
      },
    ],
  },
  {
    title: "제3장 의무 및 책임",
    main: [
      {
        contentTitle: "제10조(당 사이트의 의무)",
        content: [
          "① 당 사이트는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적, 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.",
          "② 당 사이트는 이용고객이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.",
          "③ 당 사이트는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.",
        ],
      },
      {
        contentTitle: "제11조(개인정보의 보호 및 사용)",
        content: [
          "① 회원의 개인정보는 공공기관의 개인정보보호에 관한 법률에 의해 보호됩니다.",
          "② 당 사이트의 회원 정보는 다음 각 호와 같이 사용, 관리, 보호됩니다.",
          "1. 개인정보의 수집 : 당 사이트는 회원가입 시 회원이 제공하는 정보를 수집 합니다.",
          "2. 개인정보의 사용 : 당 사이트는 서비스 제공과 관련해서 수집된 회원의 신 상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 관련 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적 이 있거나 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우, 귀하가 당 사이트에 제공한 개인정보를 스스로 공개한 경우에는 그러하지 않습니다.",
          "3. 개인정보의 관리 : 귀하는 개인정보의 보호 및 관리를 위하여 서비스의 개 인정보관리에서 수시로 귀하의 개인정보를 수정/삭제할 수 있습니다.",
          "4. 개인정보의 보호 : 귀하의 개인정보는 오직 귀하만이 열람/수정/삭제할 수 있으며, 이는 전적으로 귀하의 ID와 비밀번호에 의해 관리되고 있습니다. 따라 서 타인에게 본인의 ID와 비밀번호를 알려주어서는 안되며, 작업 종료 시에는 반드시 로그아웃 하고, 웹 브라우저의 창을 닫아야 합니다.(이는 타인과 컴퓨 터를 공유하는 인터넷 카페나 도서관 같은 공공장소에서 컴퓨터를 사용하는 경우에 회원의 정보보호를 위하여 필요한 사항입니다.)",
          "3 회원이 본 약관에 따라 이용신청을 하는 것은, 당 사이트가 신청서에 기재 된 회원정보를 수집, 이용하는 것에 동의하는 것으로 간주됩니다.",
        ],
      },
      {
        contentTitle: "제12조(회원의 의무)",
        content: [
          "① 회원 가입 시에 요구되는 정보는 정확하게 기입하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가 정확한 정보가 되도록 유지, 갱신하여야 하며, 회 원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.",
          "② 회원은 당 사이트의 사전 승낙 없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.",
          "③ 회원은 당 사이트 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.",
          "1. 회원가입 신청 또는 회원정보 변경 시 허위내용을 기재하거나 다른 회원의 비밀번호와 ID를 도용하여 부정 사용하는 행위",
          "2. 저속, 음란, 모욕적, 위협적이거나 타인의 Privacy를 침해할 수 있는 내용 을 전송, 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위",
          "3. 기관 운영진, 직원 또는 관계자를 사칭하는 행위",
          "4. 서비스를 통하여 전송된 내용의 출처를 위장하는 행위",
          "5. 법률, 계약에 의해 이용할 수 없는 내용을 게시, 게재, 전자우편 또는 기타 의 방법으로 전송하는 행위",
          "6. 당 사이트로부터 특별한 권리를 부여받지 않고 클라이언트 프로그램을 변 경하거나, 서버 해킹 및 컴퓨터바이러스 유포, 웹 사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위",
          "7. 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위",
          "8. 기관의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자우편 또는 기타의 방법으로 전 송하는 행위",
          "9. 다른 사용자의 개인정보를 수집, 저장, 공개하는 행위",
          "10. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위",
          "11. 선량한 풍속, 기타 사회질서를 해하는 행위",
          "12. 타인의 명예를 훼손하거나 모욕하는 행위",
          "13. 타인의 지적재산권 등의 권리를 침해하는 행위",
          "14. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하 는 행위",
          "15. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위",
          "16. 본 약관을 포함하여 기타 제반 규정 또는 이용 조건을 위반하는 행위",
          "17. 기타 관계법령에 위배되는 행위",
        ],
      },
      {
        contentTitle: "제13조(사용자의 정보 보안)",
        content: [
          "① 가입 신청자가 당 사이트 서비스 가입 절차를 완료하는 순간부터 귀하는 입력한 정보의 비밀을 유지할 책임이 있으며, 회원이 고의나 중대한 실수로 회 원의 ID와 비밀번호를 사용하여 발생하는 피해에 대한 책임은 회원 본인에게 있습니다.",
          "② ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있으며, 회원의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한 경우에는 즉시 당 사이트에 신고하여야 합니다. 신고를 하지 않음으로 인한 모든 책임은 회원 본인에게 있 습니다.",
          "③ 이용자는 당 사이트 서비스의 사용 종료 시마다 정확히 접속을 종료해야 하며, 정확히 종료하지 아니함으로써 제3자가 귀하에 관한 정보를 이용하게 되는 등의 결과로 인해 발생하는 손해 및 손실에 대하여 당 사이트는 책임을 부담하지 아니합니다.",
        ],
      },
    ],
  },
  {
    title: "제4장 서비스 제공 및 이용",
    main: [
      {
        contentTitle: "제14조 (서비스 제공 기간)",
        content: [
          "① 본 전기차 충전소 서비스는 사용자가 서비스 이용 신청을 한 날로부터 서비스를 제공받기 시작하며, 이용 계약의 해지 혹은 서비스 이용 약관에 따른 계약 종료 시까지 지속됩니다.",
          "② 서비스 제공 시간은 연중무휴, 1일 24시간을 원칙으로 합니다. 단, 시스템 정기 점검, 교체, 고장, 통신두절 또는 운영상의 사유로 인하여 서비스가 일시 중단될 수 있으며, 이러한 경우 사전 또는 사후에 사용자에게 공지합니다.",
        ],
      },
      {
        contentTitle: "제15조 (고객센터)",
        content: [
          "① 고객센터는 주 5일(월요일부터 금요일), 오전 9시부터 오후 5시까지 운영합니다.",
          "② 고객센터에는 전화 및 이메일로 문의하실 수 있습니다. 모든 문의는 운영 시간 내에 접수되며, 운영 시간 외에 접수된 문의는 다음 영업일에 처리됩니다.",
          "③ 고객님께서 이메일로 문의하신 사항에 대해서는 영업일 기준 48시간 내에 답변을 드리도록 노력합니다.",
        ],
      },
      {
        contentTitle: "제16조 (환불 규정)",
        content: [
          "① 사용자가 충전 서비스를 이용한 후에는, 잘못 청구된 금액이 아닌 경우를 제외하고는 환불이 불가능합니다. 사용자는 충전 서비스 이용 전 충전 요금 및 조건을 충분히 확인하여야 합니다.",
          "② 잘못 청구된 금액에 대한 환불 요청은 해당 청구일로부터 6개월 이내에 가능합니다. 환불 요청 시, 사용자는 해당 청구가 잘못된 사유를 기재하여 회사 이메일 등 연락수단을 통해 제출해야 합니다.",
          "③ 잘못 청구된 금액에 대하여 회사는 환불 사유를 검토 후, 정당한 환불 요청으로 판단될 경우 최대 30영업일 이내에 환불 처리를 진행합니다.",
          "④ 환불 시, 사용자가 결제한 방법과 동일한 방법으로 환불이 이루어지며, 결제 수단에 따라 소요되는 시간은 달라질 수 있습니다.",
          "⑤ 기타 환불에 관한 사항은 관련 법령 및 회사 정책에 따릅니다.",
        ],
      },
      {
        contentTitle: "제17조(서비스의 변경 및 중지)",
        content: [
          "① 당 사이트는 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 정책 및 운영상의 상당한 이유가 있는 경우 제공하고 있는 전부 또는 일부 서비스 를 중지할 수 있습니다. 이 경우 당 사이트는 이를 이용자에게 미리 통지하여 야 하며, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.",
          "② 당 사이트는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제 공하고 있는 서비스를 변경할 수 있습니다. 서비스의 내용, 이용방법, 이용시 간을 변경할 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등에 대해 변경 전 7일 이상 홈페이지 초기화면 등을 통해 이용자에게 미리 공지하여야 합니다.",
        ],
      },
      {
        contentTitle: "제18조(서비스 이용제한)",
        content: [
          "① 당 사이트는 회원이 서비스 이용내용에 있어서 본 약관 제 12조 내용을 위 반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.",
          "1. 2년 이상 서비스를 이용한 적이 없는 경우",
          "2. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우",
          "3. 기타 정상적인 서비스 운영에 방해가 될 경우3",
          "4. 상용소프트웨0어나 크랙파일을 올린 경우",
        ],
      },
      {
        contentTitle: "제19조(게시물에 대한 저작권)",
        content: [
          "① 당 사이트에서 제공하는 모든 저작물의 저작권 및 기타 지적재산권은 (주) FEC에 귀속됩니다.",
          "② 회원은 서비스를 이용하여 취득한 정보를 당 사이트의 사전 승낙 없이 복제, 송신, 출판, 재배포, 방송, 임의 가공, 판매하는 행위 등을 할 수 없습니다.",
        ],
      },
      {
        contentTitle: "제20조(정보 제공 및 홍보물 게재)",
        content: [
          "① 당 사이트는 서비스를 운영함에 있어서 사이트 운영에 필요한 각종 정보를 게재하거나 전자우편 등을 통해 회원에게 제공할 수 있습니다.",
          "② 당 사이트는 서비스에 적절하다고 판단되거나 공익성이 있는 홍보물을 게 재할 수 있으나, 회원이 홍보를 통한 제3의 서비스를 이용하는 것과 관련하여 발생하는 모든 문제에 대해서 어떠한 책임도 지지 않습니다.",
        ],
      },
      {
        contentTitle: "제21조(전자우편주소 수집 금지)",
        content: [
          "① 회원은 전자우편주소 추출기 등을 이용하여 전자우편주소를 수집 또는 제3 자에게 제공할 수 없습니다.",
        ],
      },
    ],
  },
  {
    title: "제5장 기타",
    main: [
      {
        contentTitle: "제22조(손해배상)",
        content: [
          "① 당 사이트는 서비스 이용과 관련하여 이용자에게 발생한 손해에 대하여 중 대한 과실, 고의 또는 범죄행위로 인해 발생한 손해를 제외하고 이에 대하여 책임을 부담하지 않으며, 이용자가 본 사이트에 게재한 정보, 자료, 사실의 신 뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.",
          "② 당 사이트는 서비스 이용과 관련하여 이용자에게 발생한 손해 중 이용자의 고의, 실수에 의한 손해에 대하여는 책임을 부담하지 아니합니다.",
        ],
      },
      {
        contentTitle: "제23조(양도금지)",
        content: [
          "회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 모든 형태의 공개 자료에 대한 공개책임은 이를 공개한 회원에게 있습니다.",
        ],
      },
      {
        contentTitle: "제24조(면책조항)",
        content: [
          "① 당 사이트는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서 비스 정보가 삭제되거나 서비스를 제공할 수 없는 경우에는 이에 대한 책임이 면제됩니다.",
          "② 당 사이트는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.",
          "③ 당 사이트는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사 유로 발생한 손해에 대한 책임이 면제됩니다.",
          "④ 당 사이트는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원 이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임 을 지지 않습니다.",
          "⑤ 당 사이트는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대 하여 책임을 지지 않습니다.",
          "⑥ 당 사이트는 서비스에 표출된 어떠한 의견이나 정보에 대해 확신이나 대표 할 의무가 없으며 이용자나 제3자에 의해 표출된 의견을 승인/반대/수정하지 않습니다.",
          "⑦ 당 사이트는 이용자 또는 기타 유관기관이 서비스에 게재한 정보에 대해 정확성, 신뢰도에 대하여 보장하지 않습니다. 따라서 당 사이트는 이용자가 위 내용을 이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임 을 부담하지 아니합니다.",
          "⑧ 당 사이트는 이용자가 서비스를 이용하며 타 이용자로 인해 입게 되는 정 신적 피해에 대하여 보상할 책임을 지지 않습니다.",
          "⑨ 당 사이트는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.",
          "⑩ 당 사이트는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개 로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임 도 없습니다.",
          "⑪ 당 사이트에서 링크를 통해 제공하는 다른 사이트의 비밀 보장 관행과 사 이트의 내용에 대해서는 책임지지 않습니다.",
        ],
      },
      {
        contentTitle: "제25조(재판관할)",
        content: [
          "① 당 사이트와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대 한민국 법을 적용하며, 본 분쟁으로 인한 소는 대한민국의 법원에 제기합니다.",
        ],
      },
      {
        contentTitle: "부칙",
        content: ["본 약관은 2024년 3월 1일부터 시행합니다. (변경될 수 있음)"],
      },
    ],
  },
];
