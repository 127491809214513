import { FooterContainer } from "./Footer.style";
import ChargeInfo from "../../Home/ChargeInfo/ChargeInfo";
import HomeNotice from "../../Home/Notice/HomeNotice";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import NoLoginModal from "../../Common/Modal/NoLoginModal";

// 푸터 컴포넌트. 푸터에는 홈, qr 촬영, 현재 충전 실황, 메뉴 페이지로 이동 할 수 있는 버튼들이 있다.
const Footer = () => {
  const [isNoLoginModal, setIsNoLoginModal] = useState(false);
  const navigate = useNavigate();
  const userInfoData = useSelector((state) => state.authInfo.userInfo);
  const onclickMypage = () => {
    if (userInfoData) {
      navigate("/usermenu");
    } else {
      document.body.style.overflow = "hidden";
      setIsNoLoginModal(true);
    }
  };
  return (
    <>
      {isNoLoginModal && <NoLoginModal setIsNoLoginModal={setIsNoLoginModal} />}
      <FooterContainer>
        {/* 홈 화면 하단에 공지 박스 */}
        {/* <HomeNotice /> */}
        {/* 충전소위치, 충전 요금 버튼 */}
        <ChargeInfo onclickMypage={onclickMypage} />
      </FooterContainer>
    </>
  );
};

export default Footer;
