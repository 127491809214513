import React, { useState } from "react";

import { Link } from "react-router-dom";
import { QrBtn, QrBtnBox } from "./QrButtonStyle";

const QrButton = () => {
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const openCameraClick = () => {
    setIsOpenCamera((prev) => !prev);
  };
  return (
    <QrBtnBox>
      <Link to="/qrcodescanner">
        <QrBtn onClick={openCameraClick}>QR코드 촬영</QrBtn>
      </Link>
    </QrBtnBox>
  );
};

export default QrButton;
