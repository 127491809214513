import styled from "styled-components";

// ApplicationDes.js componetn
export const ApplicationContainer = styled.div`
  padding: 60px 18px 0 18px;
  margin-top: 20px;
`;
export const ApplicationDesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 13px;
  & > div > div {
    margin-top: 10px;
    .sub-title {
      /* margin-top: 10px; */
      line-height: 140%;
    }
  }
`;
