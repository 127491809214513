import styled from "styled-components";
import { theme } from "../../../../components/theme/theme";

export const MenuItemBox = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-bottom: 0.5px solid #d2d2d2;
  padding-bottom: 10px;
`;
export const MenuItemTitle = styled.h1`
  font-weight: 700;
  width: 100%;
  margin-top: 5px;
  font-size: 18px;
  line-height: 15px;
`;
export const MenuItemSubList = styled.ul`
  color: black;
  margin-top: 10px;
`;
export const SubItem = styled.li`
  font-size: 16px;
  line-height: 28px;
  .qr {
    display: flex;
    align-items: flex-start;
    color: ${theme.colors.SubFontColor};
    .qr-icon-img {
      width: 16px;
      height: 12px;
      margin-right: 4px;
      line-height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .qr-text {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }
  }
`;
