import { createSlice } from "@reduxjs/toolkit";

const selectCardInfoSlice = createSlice({
  name: "selectCardInfo",
  initialState: {
    cardName: "",
  },
  reducers: {
    updateSelectCardInfo(state, action) {
      state.cardName = action.payload;
    },
  },
});

export const { updateSelectCardInfo } = selectCardInfoSlice.actions;

const selectCardInfoSliceReducer = selectCardInfoSlice.reducer;
export default selectCardInfoSliceReducer;
