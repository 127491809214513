import styled from "styled-components";
import { theme } from "../../../../components/theme/theme";

export const MenuUserInfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  color: black;
  margin-right: 3px;

  .userinfo {
    display: flex;
    align-items: center;
    font-size: 20px;
    .user-name {
      font-weight: bold;
      margin-right: 3px;
    }
    img {
      margin-right: 5px;
    }
  }
  .userinfo-edit {
    font-size: 12px;
    text-decoration: underline;
  }
`;
