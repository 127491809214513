import { useForm } from "react-hook-form";
import usePostApi from "../../../hooks/api/usePostApi";
import AuthForm from "../Common/AuthForm/AuthForm";
import AuthInput from "../Common/AuthInput/AuthInput";
import ErrorMsg from "../../Common/Errors/ErrorMsg";
import AuthButton from "../Common/AuthButton";
import { ButtonLoading } from "../style/styled";
import Loading from "../../Common/Loading/Loading";
import { useState } from "react";

//회원가입 시 인증번호 받을 휴대폰 입력 컴포넌트

const PhoneNumberCertification = ({ setInputPhoneNumberData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
  });
  const { isError, isLoading, postData } = usePostApi();

  const PhoneVerifySubmit = async (phoneData) => {
    try {
      let response = await postData(
        process.env.REACT_APP_API_VERIFY_REGISTER,
        phoneData,
        "",
        false
      );
      console.log("요청이다!!");
      console.log("데이터", response);
      if (response?.error) {
        return;
      }
      setInputPhoneNumberData(phoneData.phone);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthForm onSubmit={handleSubmit(PhoneVerifySubmit)}>
      <AuthInput
        label="인증받을 휴대폰 번호"
        type="number"
        id="phone"
        register={register("phone", {
          required: "휴대폰번호를 입력해주세요.",
          pattern: {
            value: /01([0|1|6|7|8|9])-?([0-9]{4})-?([0-9]{4})$/,
            message: "휴대폰번호 형식이 아닙니다.",
          },
        })}
        placeholder={"휴대폰 번호를 입력해주세요."}
      />
      {errors?.phone && <ErrorMsg text={errors.phone.message} />}
      {!errors?.phone && isError && <ErrorMsg text={isError} />}
      {isLoading ? (
        <AuthButton
          type="submit"
          title={
            <ButtonLoading>
              <Loading />
            </ButtonLoading>
          }
        />
      ) : (
        <AuthButton type="submit" title="인증번호 받기" isActiveBtn={isValid} />
      )}
    </AuthForm>
  );
};

export default PhoneNumberCertification;
