import { useEffect, useState } from "react";
import Header from "../../../components/Layout/Header/Header";
import {
  PaymentContainer,
  PaymentSelectBtn,
  PaymentSelectBtnBox,
} from "./style";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { authInfoData } from "../../../store/Auth/auth-api";
import { Navigate, useNavigate } from "react-router";
import Button from "../../../components/Common/Button/Button";
import Fixed from "../../../components/Common/Fixed/Fixed";
import { theme } from "../../../components/theme/theme";

const PaymentSelect = () => {
  const [isSelectPayment, setIsSelectPayment] = useState("card");
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, []);
  const { IMP } = window;
  useEffect(() => {
    console.log("팩트 실행");
    IMP.init(process.env.REACT_APP_API_PORTONE_CODE);
  }, []);

  // console.log("localhost", window.location.host);
  const handleCreate = () => {
    const customerUid = uuidv4();
    const pgText =
      isSelectPayment === "card"
        ? "html5_inicis.MOIfecch31"
        : "kakaopay.CA70TFYHG9";
    IMP.request_pay(
      {
        pg: pgText,
        pay_method: "card",
        merchant_uid: uuidv4(),
        name: "후불 카드결제 등록",
        amount: 0,
        customer_uid: customerUid,
        buyer_tel: userInfo.phone,
        m_redirect_url: `${window.location.host}/card/payment/create/${customerUid}`,
      },
      function (res) {
        // console.log("res", res);
        if (res.success) {
          navigate(`/card/payment/create/${customerUid}?imp_success=true`);
        } else {
          alert(res.error_msg);
          // alert(res.error_msg);
          // Navigate("/card/payment/select");
        }
      }
    );
  };

  useEffect(() => {
    console.log("팩트 실행");
    window.addEventListener("resize", () => {
      console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);

  return (
    <>
      <Header
        isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title="결제수단 선택"
        marginBottom="false"
      />
      <PaymentContainer contentHeight={contentHeight}>
        <PaymentSelectBtnBox>
          <PaymentSelectBtn
            backColor={"card" === isSelectPayment ? true : false}
            color={"card" === isSelectPayment ? true : false}
            onClick={() => setIsSelectPayment("card")}
          >
            {/* <PaymentSelectDot
              dotColor={"card" === isSelectPayment ? true : false}
            /> */}
            신용 / 체크 카드
          </PaymentSelectBtn>
          <PaymentSelectBtn
            backColor={"kakao" === isSelectPayment ? true : false}
            color={"kakao" === isSelectPayment ? true : false}
            onClick={() => setIsSelectPayment("kakao")}
            // style={{ marginTop: "20px" }}
          >
            {/* <PaymentSelectDot
              dotColor={"kakao" === isSelectPayment ? true : false}
            /> */}
            KakaoPay
          </PaymentSelectBtn>
        </PaymentSelectBtnBox>
      </PaymentContainer>
      <Fixed>
        <Button
          onClick={() => handleCreate()}
          type="submit"
          text={
            isSelectPayment === "card"
              ? "신용/체크 카드 선택 완료"
              : "카카오페이 선택 완료"
          }
          fontSize="18px"
        />
      </Fixed>
    </>
  );
};

export default PaymentSelect;
