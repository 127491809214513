import { createSlice } from "@reduxjs/toolkit";

//초깃값 설정
const initialState = {
  carInfor: null,
  loading: false,
  error: null,
};

const carInfoSlice = createSlice({
  name: "carInfo",
  initialState: initialState,
  reducers: {
    // fetch 요청 할 경우
    fetchCarInfoStart(state) {
      state.loading = true;
      state.error = null;
    },

    // fetch 요청이 true일 경우
    fetchCarInfoSucess(state, action) {
      state.loading = false;
      state.carInfor = action.payload;
    },

    // fetch 요청이 false일 경우.
    fetchCarInfoFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchCarInfoFailure, fetchCarInfoStart, fetchCarInfoSucess } =
  carInfoSlice.actions;

const carInfoSliceReducer = carInfoSlice.reducer;

export default carInfoSliceReducer;
