import styled from "styled-components";
import { ModalBackground } from "../../Common/style/styled";

const HistoryModalBack = styled(ModalBackground)`
  background-color: rgba(0, 0, 0, 0.2);
`;
const HistoryModalBox = styled.div`
  position: relative;
  padding: 15px 10px;
  width: 100%;
  max-width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  & > h1 {
    font-size: 20px;
    font-weight: 800;
  }
  .close-btn {
    & > span {
      color: black;
    }
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
const TitleTextContent = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;

  .title {
    font-size: 16px;
  }
  .content {
    font-size: 15px;
    font-weight: 600;
    margin-left: 5px;
  }
`;

const HistoryModal = (props) => {
  const { modalContent } = props;
  const onClose = () => {
    props.onClose();
  };

  const addComma = (price) => {
    let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnString;
  };

  return (
    <HistoryModalBack>
      <HistoryModalBox>
        {/* <ModalContent
          style={{
            padding: "20px",
          }}
        > */}
        <button className="close-btn" onClick={onClose}>
          <span class="material-symbols-outlined">close</span>
        </button>
        <h1>상세내용</h1>
        <TitleTextContent>
          <p className="title">충전소: </p>
          <p>{modalContent.centerName}</p>
        </TitleTextContent>
        <TitleTextContent>
          <p className="title">충전 시작 시간: </p>
          <p className="content">{modalContent.startTime}</p>
        </TitleTextContent>
        <TitleTextContent>
          <p className="title">충전 종료 시간: </p>
          <p className="content">{modalContent.endTime}</p>
        </TitleTextContent>

        <TitleTextContent>
          <p className="title">충전 요금: </p>
          <p className="content">{addComma(modalContent.chargeFee)} 원</p>
        </TitleTextContent>
        {/* </ModalContent> */}
      </HistoryModalBox>
    </HistoryModalBack>
  );
};

export default HistoryModal;
