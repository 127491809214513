import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CardContainer = styled.div`
  width: 100%;
  height: 180px;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: ${(props) => props.fontColor};
  /* transition: all 0.2s ease-in-out; */
`;

export const CardHeader = styled.div`
  /* padding: 15px; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end; /* background-color: ${theme.colors.pointColor}; */
  .title {
    font-weight: 600;
    font-size: 22px;
    /* color: #fff; */
  }
`;

export const CardMain = styled.div`
  /* padding: 15px; */
  width: 100%;
  display: flex;
  /* color: white; */
  justify-content: space-between;
  flex-direction: column;
  .weight-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;
export const CardNumberText = styled.div`
  font-size: 18px;
  font-weight: 600;
  display: flex;
`;
export const IsMainBillText = styled.p`
  color: #ff9100;
  /* color: red; */
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
`;

// 여기까지 카드 스타일

export const OtherButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: black; */
  color: white;
  .btn {
    width: 4px;
    height: 4px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: ${(props) => props.fontColor};
    /* background-color: white; */
  }
`;

export const CardOptioModal = styled.div`
  width: 120x;
  background-color: white;
  border-radius: 8px;
  div:nth-of-type(2) {
    padding: 0 10px 10px 10px;
  }
`;

export const CardOptionModalItem = styled.div`
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

// 여기까지 카드 옵션 모달 스타일
