import styled from "styled-components";

export const NoAuthBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* 버튼에 마우스를 올려두면 포인터 보여주기 */
  & > a > button {
    cursor: pointer;
  }
`;
