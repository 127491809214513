import { useState } from "react";
import Loading from "../../Common/Loading/Loading";
import AuthButton from "../Common/AuthButton";
import {
  AgreementCheckBox,
  AgreementItem,
  ButtonLoading,
  CheckBox,
} from "../style/styled";
import { InputInfoBox, InputInfoItem } from "./AccountAgreement.style";

const AccountAgreement = ({
  isServiceCheck,
  setIsServieCheck,
  agreementOpenClick,
  isLoading,
  watch,
}) => {
  // console.log("hello", isActiveBtn);
  const { name, email, phone } = watch();
  const inputInfoList = [
    {
      title: "이름",
      content: name,
    },
    { title: "이메일", content: email },
    {
      title: "휴대폰번호",
      content: phone,
    },
  ];
  return (
    <>
      <h1 style={{ fontSize: "20px", fontWeight: "700", marginBottom: "20px" }}>
        입력하신 회원 정보
      </h1>
      <InputInfoBox>
        {inputInfoList.map((item) => {
          return (
            <InputInfoItem>
              <h1>{item.title}</h1>
              <span>{item.content}</span>
            </InputInfoItem>
          );
        })}
      </InputInfoBox>
      <h1 style={{ fontSize: "20px", fontWeight: "700", marginTop: "20px" }}>
        FEC 서비스 이용약관 동의
      </h1>
      <AgreementCheckBox>
        <AgreementItem>
          <CheckBox
            check={isServiceCheck ? true : false}
            onClick={() => {
              setIsServieCheck((prev) => !prev);
            }}
          >
            <>
              <div className="checkbox__left"></div>
              <div className="checkbox__right"></div>
            </>
          </CheckBox>
          <div className="item__text">
            <span>
              <span className="essential">[필수]</span>
              <span className="service">
                서비스이용약관, 개인정보처리방침을
              </span>
              에 동의합니다.
              <span className="detail" onClick={agreementOpenClick}>
                자세히
              </span>
            </span>
          </div>
        </AgreementItem>
      </AgreementCheckBox>

      {isLoading ? (
        <AuthButton
          type="submit"
          title={
            <ButtonLoading>
              <Loading />
            </ButtonLoading>
          }
        />
      ) : (
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "20px",
            left: "0",
            padding: "0 18px",
          }}
        >
          <div
            style={{
              width: "100%",
              fontSize: "14px",
              fontweight: "800",
              display: "flex",
              justifyContent: "center",
              color: "#3296FF",
            }}
          >
            {isServiceCheck
              ? "회원가입 버튼을 클릭해주세요."
              : "서비스 이용약관에 동의해주세요."}
          </div>

          <AuthButton
            type="submit"
            title="회원가입"
            isActiveBtn={isServiceCheck}
          />
          <div>
            {/* <AccountCheckMsg
                    text="이미 계정이 있으신가요?"
                    href="/users/login"
                  /> */}
            {/* <UnAccount>동일한 휴대폰 또는 이메일로 가입한 회원이 있습니다.</UnAccount> */}
          </div>
          {/* 카카오톡 간편 로그인 및 회원가입 */}
          {/* <KaKaoLoginImg /> */}
        </div>
      )}
    </>
  );
};

export default AccountAgreement;
