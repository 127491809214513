import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authInfoData } from "../../store/Auth/auth-api";
import useConnectorCheck from "../../api/useConnectorCheck";
import PhoneCertification from "./components/PhoneCertification/PhoneCertification";
import { useParams } from "react-router";
import ChargeOption from "./components/ChargeOptionCertification/ChargeOption";
import UnChargeable from "./components/UnChargeable/UnChargeable";
import Header from "../Layout/Header/Header";
import SelectBatteryAmount from "./components/SelectBatteryAmount/SelectBatteryAmount";

// qr 로 접속하면 먼저 인증절차를 진행하는 컴포넌트
const QrCertification = () => {
  // 휴대폰 번호 데이터 입력 state
  const [phoneNumber, setPhoneNumber] = useState();
  // 현재 사용자의 자동차 배터리 양
  const [batteryAmount, setBatteryAmount] = useState(null);
  // 토근 state
  const [token, setToken] = useState();

  // 폰인증 , 인증번호 인증 체크
  const [certificationPhonNumberCheck, setCertificationPhoneNumberCheck] =
    useState(false);

  // 회원정보 Get
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, []);

  // =======================================================================
  // 커넥터 충전가능 여부
  // qr check api 부분
  const { isUnchargeable, isErrorMsg, fetchData } = useConnectorCheck();

  // qr 촬영해서 접속한 url에서 center, connector 값을 가져옴
  const { charge, id } = useParams();
  const center = charge;
  const connector = id;

  // console.log("center", center);
  // console.log("connector", id);

  // qr 촬영 url로 qr check api로 center, connector 값을 보냄
  useEffect(() => {
    console.log("팩트 실행");
    fetchData(center, connector);
  }, [center, connector]);

  // =========================================================================

  // console.log("selectbatteryamount", batteryAmount);
  return (
    <>
      <Header
        // isBack={true}
        isHome={true}
        backColor="#5B5AC7"
        homesrc="/img/white_home_icon.svg"
        color="white"
        title={
          certificationPhonNumberCheck && batteryAmount
            ? "충전금액 입력"
            : certificationPhonNumberCheck && !batteryAmount
            ? "배터리 양 선택"
            : "인증번호 입력"
        }
        marginBottom="false"
      />
      <div>
        {isUnchargeable ? (
          certificationPhonNumberCheck && batteryAmount ? (
            // 충전옵션 입력
            <ChargeOption
              userInfo={userInfo}
              token={token}
              phoneNumber={phoneNumber}
              batteryAmount={batteryAmount}
            />
          ) : certificationPhonNumberCheck && !batteryAmount ? (
            <SelectBatteryAmount setBatteryAmount={setBatteryAmount} />
          ) : (
            // 폰인증 ,인증번호 인증
            <PhoneCertification
              userInfo={userInfo}
              setCertificationCheck={setCertificationPhoneNumberCheck}
              setPhoneNumber={setPhoneNumber}
              setToken={setToken}
            />
          )
        ) : (
          // connecotr , qr check error 충전 불가능 메세지 컴포넌트
          <UnChargeable />
        )}
      </div>
    </>
  );
};

export default QrCertification;
