import React, { useEffect, useState } from "react";
import { Form } from "./style";

const AuthForm = ({ children, onSubmit }) => {
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("실행");
    console.log("실행");
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  // console.log("현재 컨텐츠의 높이", contentHeight);

  return (
    <Form onSubmit={onSubmit} contentHeight={contentHeight}>
      <div className="users-container">{children}</div>
    </Form>
  );
};

export default AuthForm;
