import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../../components/Layout/Header/Header";
import { authInfoData } from "../../../store/Auth/auth-api";
import MenuUserInfo from "./MenuUserInfo/MenuUserInfo";
import Container from "../../../components/Common/Wrapper/Container";
import Footer from "../../../components/Layout/Footer/Footer";
import {
  UserMenuContainer,
  UserMenuItem,
  UserMenuList,
} from "./UserMenu.style";
import Modal from "../../../components/Common/Modal/Modal";

const menuList = [
  {
    name: "내 정보 관리",
    url: "/users/profile",
    icon: <span className="material-symbols-outlined">account_circle</span>,
  },
  {
    name: "내 차량 관리",
    url: "/cars/management",
    icon: <span className="material-symbols-outlined">directions_car</span>,
  },
  {
    name: "결제 수단 관리",
    url: "/card/payment/management",
    icon: <span className="material-symbols-outlined">credit_card</span>,
  },
  {
    name: "충전소 이용 내역",
    url: "/payment/history",
    icon: <span className="material-symbols-outlined">list_alt</span>,
  },
];
const UserMenu = () => {
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  const [isCheckModal, setIsCheckModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, []);
  // const onLogOutHandler = () => {
  //   localStorage.removeItem("token");
  //   document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
  //   console.log("logout");
  //   window.location.href = "/";
  // };

  return (
    <>
      {isCheckModal && (
        <Modal
          type="ready"
          text={"서비스 준비 중 입니다."}
          setIsModal={setIsCheckModal}
          // func={(setIsModal) => {
          //   setIsModal((prev) => !prev);
          // }}
        />
      )}
      <Container bgColor="#f5f5f5">
        <Header
          // isBack={true}
          // isHome={true}
          // backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="black"
          title="내 정보"
          marginBottom="false"
        />
        <UserMenuContainer>
          {userInfo && userInfo.user.token && (
            <MenuUserInfo userInfoData={userInfo} />
          )}
          <UserMenuList>
            {menuList.map((item) => {
              if (item.name === "내 차량 관리") {
                return (
                  <UserMenuItem
                    onClick={() => setIsCheckModal((prev) => !prev)}
                  >
                    <span>{item.name}</span>
                  </UserMenuItem>
                );
              } else {
                return (
                  <Link to={item.url}>
                    <UserMenuItem>
                      <span>{item.name}</span>
                    </UserMenuItem>
                  </Link>
                );
              }
            })}
          </UserMenuList>
          <Link to="/users/memberwithdraw" style={{ fontSize: "20px" }}>
            <UserMenuItem>회원 탈퇴</UserMenuItem>
          </Link>
        </UserMenuContainer>
        <Footer />
      </Container>
    </>
  );
};

export default UserMenu;
