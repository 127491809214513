import { useEffect, useState } from "react";
import AuthButton from "../Common/AuthButton";
import AuthInput from "../Common/AuthInput/AuthInput";
import DuplicateError from "../../Common/Errors/DuplicateError";

// 아이디 (이메일) 닉네임 입력 컴포넌트
const InputNicknameAndId = ({
  watch,
  register,
  setIsInputNicknameAndId,
  errors,
  isValid,
}) => {
  const [emailDuplicationCheck, setEmailDuplicationCheck] = useState(false);
  const [errorText, setErrorText] = useState("");
  console.log("errors", errors);

  // 이메일 중복 체크 함수
  const emailCheckFunc = async (email) => {
    try {
      console.log("요청");
      const response = await fetch(process.env.REACT_APP_API_CHECK_EMAIL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // credentials: "include", //Cross-Origin 요청을 할 때 요청에 자동으로 쿠키와 인증 헤더를 포함하도록 설정하는 역할

        body: JSON.stringify({ email: email }),
      });
      if (response.status === 200) {
        setEmailDuplicationCheck(true);
        setErrorText("사용 가능한 이메일 입니다.");
      } else if (response.status === 400) {
        setErrorText("이미 사용중인 이메일 입니다.");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <>
      <AuthInput
        label="이름 또는 닉네임"
        placeholder="이름 또는 닉네임을 입력해주세요. (최대 6글자)"
        type="text"
        id="name"
        register={register("name", {
          required: "이름을 입력해주세요.",
          maxLength: {
            value: 6,
            message: "이름은 최대 6글자 입니다.",
          },
        })}
      />
      <AuthInput
        label="이메일"
        func={() => emailCheckFunc(watch("email"))}
        placeholder={"이메일을 입력해주세요."}
        type="email"
        id="email"
        register={register("email", {
          required: "이메일을 입력해주세요.",
          pattern: {
            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
            message: "이메일 형식이 아닙니다.",
          },
          validate: {
            check: (item) => {
              emailCheckFunc(item);
            },
          },
        })}
      />

      {errors?.name && <DuplicateError text={errors?.name?.message} />}
      {!errors?.name && errors?.email && (
        <DuplicateError text={errors?.email?.message} />
      )}
      {!errors?.name && !errors?.email && errorText !== "" && (
        <DuplicateError text={errorText} />
      )}
      <AuthButton
        type="button"
        title="다음"
        onClick={() => setIsInputNicknameAndId((prev) => !prev)}
        isActiveBtn={emailDuplicationCheck && isValid}
      />
    </>
  );
};

export default InputNicknameAndId;
