import React from "react";
import styled from "styled-components";
const ContainerBox = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* height: 100vh; */
  /* overflow-y: auto; */
  background-color: ${({ theme, bgColor }) =>
    bgColor ?? theme.colors.background};
  padding-bottom: ${(props) => props.paddingBottom};
`;
const Container = (props) => {
  return (
    <ContainerBox
      bgColor={props.bgColor}
      height={props.height}
      paddingBottom={props.paddingBottom}
    >
      {props.children}
    </ContainerBox>
  );
};
export default Container;
