import { useEffect, useRef } from "react";
import { ModalBackground, ModalBox, ModalBtn } from "../style/styled";
import { useNavigate } from "react-router";

const NoLoginModal = ({ setIsNoLoginModal }) => {
  const navigate = useNavigate();
  const ref = useRef();
  useEffect(() => {
    console.log("팩트 실행");
    // console.log("time 실행");
    const key = setTimeout(() => {
      ref.current.style.transform = "translateY(-10px)";
      ref.current.style.transition = "all 0.2s ease-in-out";
    });

    return () => {
      clearTimeout(key);
    };
  }, []);
  return (
    <ModalBackground>
      <ModalBox ref={ref}>
        <div>
          <p className="nologin">로그인 후 이용 부탁드립니다.</p>
          <div className="btn-container">
            <ModalBtn
              type="button"
              color={true}
              onClick={() => {
                document.body.style.overflow = "";
                setIsNoLoginModal(false);
              }}
            >
              취소
            </ModalBtn>
            <ModalBtn
              type="button"
              onClick={() => {
                document.body.style.overflow = "";
                setIsNoLoginModal(false);
                navigate("/users/login");
              }}
            >
              확인
            </ModalBtn>
          </div>
        </div>
      </ModalBox>
    </ModalBackground>
  );
};

export default NoLoginModal;
