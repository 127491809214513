import styled from "styled-components";
import React from "react";

const Footer = styled.footer`
  /* padding: 0 18px; */
  /* margin-top: 100px; */
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
`;
const FooterBox = ({ children }) => {
  return <Footer>{children}</Footer>;
};

export default FooterBox;
