import React from "react";
import {Oval} from "react-loader-spinner";

const Loading = ()=>{
    return(
        <Oval 
        color="red" 
        height={30} 
        width={100}
        />
    )
}

export default Loading;