import React from "react";
import { MenuUserInfoBox } from "./MenuUserInfoStyle";
import AuthButton from "../../../../components/Common/Button/AuthButton";
import { Link } from "react-router-dom";
import { theme } from "../../../../components/theme/theme";

// 내 정보 페이지에서 현재 사용자의 닉네임과 로그아웃 버튼을 포함하는 컴포넌트
const MenuUserInfo = ({ userInfoData }) => {
  // 로그아웃
  const onLogOutHandler = () => {
    localStorage.removeItem("token");
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    console.log("logout");
    window.location.href = "/";
  };

  return (
    <MenuUserInfoBox>
      <div className="userinfo">
        {/* <img src="/img/Auth/AuthICON2.png" alt="회원 이미지" /> */}
        <span className="material-symbols-outlined">person</span>
        <span className="user-name">{userInfoData.user.name}</span>
        <span>회원님</span>
      </div>
      {/* <div className="userinfo-edit">
        <Link to="/users/profile">내 정보 관리</Link>
      </div> */}
      <AuthButton
        onClick={onLogOutHandler}
        text="로그아웃"
        bgColor={theme.colors.mainColor}
        color="#ffff"
        fontSize="15px"
      />
    </MenuUserInfoBox>
  );
};

export default MenuUserInfo;
