import styled from "styled-components";

export const ShowUserIdBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 18px 0 18px;
  height: ${(props) => props.contentHeight && `${props.contentHeight}px`};
`;

export const ShowUserIdContent = styled.div`
  background-color: #f5f5f5;
  /* border: 1px solid #aaaaaa; */
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 23px;
  background-color: white;
  & > span {
    font-weight: 700;
    color: #0a6eff;
  }
`;

export const ShowUserIdBtnBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border: none;
  display: flex;
  align-items: center;
  padding: 0 18px;
  button:nth-last-child(1) {
    margin-left: 15px;
  }
`;

export const ShowUserUdBtn = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backColor};
  color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 600;
`;
