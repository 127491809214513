import { useEffect, useState } from "react";
import * as S from "./ChangePasswordSuccess.style";
import { useNavigate } from "react-router";
import Header from "../../../Layout/Header/Header";
import Container from "../../../Common/Wrapper/Container";

const ChangePasswordSuccess = () => {
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log("실행");
    window.addEventListener("resize", () => {
      // console.log("높이 변경");
      setContentHeight(window.innerHeight);
    });
    return () =>
      window.removeEventListener("resize", () =>
        setContentHeight(window.innerHeight)
      );
  }, []);
  return (
    <Container>
      <S.ChangePasswordSuccessBox contentHeight={contentHeight}>
        <Header
          // isBack={true}
          // isHome={true}
          backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="white"
          title="비밀번호 찾기"
          marginBottom="false"
        />
        <S.ChangePasswordSuccessTextBox>
          <span>비밀번호가 성공적으로 변경되었습니다.</span>
        </S.ChangePasswordSuccessTextBox>
        <S.ChangePasswordSuccessBtnBox>
          <S.ChangePasswordSuccessBtn
            onClick={() => navigate("/")}
            backColor={"#dcdcdc"}
            color={"#505050"}
          >
            홈으로가기
          </S.ChangePasswordSuccessBtn>
          <S.ChangePasswordSuccessBtn
            onClick={() => navigate("/users/login")}
            backColor={"#412BD5"}
            color={"white"}
          >
            로그인 하러가기
          </S.ChangePasswordSuccessBtn>
        </S.ChangePasswordSuccessBtnBox>
      </S.ChangePasswordSuccessBox>
    </Container>
  );
};

export default ChangePasswordSuccess;
