import styled from "styled-components";

// 회사 정보 컴포넌트 박스
export const CompanyInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #969696;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 600;
`;
// 회사 정보 컴포넌트 박스의 각각의 아이템
export const CompanyInfoItem = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  line-height: 14px;
  .title {
    white-space: nowrap;
    margin-right: 10px;
  }
`;
