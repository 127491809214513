import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import usePatchApi from "../../hooks/api/usePatchApi";
import { authInfoData } from "../../store/Auth/auth-api";
import AuthForm from "../../components/Auth/Common/AuthForm/AuthForm";
import AuthInput from "../../components/Auth/Common/AuthInput/AuthInput";
import DuplicateError from "../../components/Common/Errors/DuplicateError";
import Fixed from "../../components/Common/Fixed/Fixed";
import Button from "../../components/Common/Button/Button";
import Loading from "../../components/Common/Loading/Loading";
import Header from "../../components/Layout/Header/Header";
import Container from "../../components/Common/Wrapper/Container";
import { useNavigate } from "react-router";

const UserEdit = () => {
  // const [isEditFindAddressModal, setIsEditFindAddressModal] = useState(false);
  // const [editAddressData, setEditAddressData] = useState({
  //   addr_num: "",
  //   home_addr: "",
  // });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    console.log("팩트 실행");
    if (!token) {
      const confirmed = window.confirm("로그인 후 이용부탁 드립니다.");
      if (confirmed) {
        navigate("/users/login");
      }
      if (!confirmed) {
        navigate("/");
      }
    }
  }, [token]);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
  });
  const { patchData, isLoading, isError } = usePatchApi();
  const userInfo = useSelector((state) => state.authInfo.userInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("팩트 실행");
    dispatch(authInfoData());
  }, [dispatch]);
  const { name, phone, email } = watch();

  console.log("errors", errors);
  // api에 요청 시 빈문자열로 가는것을 방지하기 위해 setValue 이용하여
  // 기존에 저장되어있는 값들을 보내줌
  useEffect(() => {
    console.log("팩트 실행");
    if (userInfo?.user?.name) setValue("name", userInfo?.user?.name);
    // if (userInfo?.user?.home_addr) {
    // setEditAddressData({
    //   addr_num: userInfo.user.addr_num,
    //   home_addr: userInfo.user.home_addr,
    //   addr_detail: userInfo.user.addr_detail,
    // });
    // }
  }, [userInfo]);

  const profileEditHandler = async (editData) => {
    // const eidtPostData = {
    //   ...editData,
    //   postCode: editAddressData.postcode,
    //   address: editAddressData.address,
    // };
    // console.log("edit", editData);
    if (editData.name === userInfo?.user?.name) {
      setError("name", { message: "변경사항이 없습니다." });
      return;
    }

    patchData(process.env.REACT_APP_API_USER_EDIT, editData, "/users/profile");
  };

  // const onCompleteEditAddress = (data) => {
  //   console.log("data", data);
  //   let addr;
  //   if (data.userSelectedType === "R") {
  //     // 사용자가 도로명 주소를 선택했을 경우
  //     addr = data.roadAddress;
  //   } else {
  //     // 사용자가 지번 주소를 선택했을 경우(J)
  //     addr = data.jibunAddress;
  //   }
  //   setEditAddressData({ postcode: data.zonecode, address: addr });
  //   setIsEditFindAddressModal((prev) => !prev);
  // };
  return (
    <>
      {/* {isEditFindAddressModal && (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "60px",
          }}
        >
          <DaumPostcodeEmbed
            onComplete={onCompleteEditAddress}
            autoClose={false}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )} */}
      <Container bgColor="#f5f5f5">
        <Header
          isBack={true}
          isHome={true}
          backColor="#5B5AC7"
          homesrc="/img/white_home_icon.svg"
          color="white"
          title="이름 변경"
          marginBottom="false"
          isModal={name === userInfo?.user?.name ? false : true}
        />
        <AuthForm onSubmit={handleSubmit(profileEditHandler)}>
          <AuthInput
            type="text"
            id="name"
            label="이름"
            register={register("name", {
              required: "이름을 입력해주세요",
              minLength: {
                value: 3,
                message: "이름은 최소 3글자 입니다",
              },
              maxLength: {
                value: 6,
                message: "이름은 최대 6글자 입니다",
              },
            })}
          />
          {errors && errors.name && (
            <DuplicateError text={errors.name.message} />
          )}
          {/* {errors && errors.phone && (
          <DuplicateError text={errors.phone.message} />
        )} */}
          {/* {errors && errors.email && (
          <DuplicateError text={errors.email.message} />
        )} */}
          {/* <AuthInput
          type="text"
          id="home_addr"
          label="주소"
          placeholder={userInfo?.user.home_addr}
          register={register("home_addr")}
        /> */}
          {/* <AddressInput
          register={register}
          setIsFindAddressModal={setIsEditFindAddressModal}
          addressData={editAddressData}
          mode="edit"
        /> */}
          {/* 공백을 입력 할 경우  */}
          {isError && <DuplicateError text={isError} />}
          <Fixed>
            {isError || isLoading ? (
              <Button type="submit" text={<Loading />} />
            ) : (
              <Button
                type="submit"
                text="변경하기"
                fontSize="18px"
                isActiveBtn={isValid}
              />
            )}
          </Fixed>
        </AuthForm>
      </Container>
    </>
  );
};

export default UserEdit;
