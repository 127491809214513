import styled from "styled-components";

export const FooterContainer = styled.footer`
  border-top: 1px solid #b4b4b4;
  width: 100%;
  /* max-width: 600px;s */
  /* border-top: 1px solid #969696; */
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${(props) => props.display};
  /* box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) */
`;
